import React, { useContext, useState } from 'react';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import TextInput from '../../components/inputs/TextInput';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { forgotPassword, resetPassword } from '../../api/user';
import { UIContext } from '../../providers/UIProvider';
import useDocumentTitle from '../../hooks/useDocumentTitle';
// Components & Utils
// UI

/**
 * @function ResetPassword
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const ResetPassword = () => {
  useDocumentTitle('Oasis App - Reiniciar contraseña');
  const { toggleAlertDialog, updateAlertDialogProps } = useContext(UIContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
  } = useForm({ mode: 'onChange' });

  const token = new URLSearchParams(window.location.search).get('token');

  const forgotPasswordMutation = useMutation(forgotPassword);
  const resetPasswordMutation = useMutation(resetPassword);

  const onForgotPasswordSubmit = async ({ email }) => {
    setIsLoading(true);
    try {
      await forgotPasswordMutation.mutateAsync({ email });
      updateAlertDialogProps({
        title: 'Revisa tu correo',
        content: 'Se ha enviado un correo para que restablezcas tu contraseña',
        variant: 'info',
      });
      toggleAlertDialog(true);
    } catch (error) {
      updateAlertDialogProps({
        title: 'Oh no',
        content:
          'Algo ha salido mal, por favor verifica que tu correo sea correcto o inténtalo más tarde.',
        variant: 'error',
      });
      toggleAlertDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onPasswordResetSubmit = async ({ password }) => {
    try {
      setIsLoading(true);
      await resetPasswordMutation.mutateAsync({ password, token });
      updateAlertDialogProps({
        title: 'Felicidades',
        content: 'Se ha restablecido tu contrase con éxito.',
        variant: 'info',
      });
      toggleAlertDialog(true);
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (error) {
      updateAlertDialogProps({
        title: '¡Oh no!',
        content: 'Hubo un problema restableciendo tu contraseña.',
        variant: 'error',
      });
      toggleAlertDialog(true);
      setIsLoading(false);
    }
  };

  return (
    <Stack
      component='form'
      spacing={4}
      onSubmit={handleSubmit(
        token ? onPasswordResetSubmit : onForgotPasswordSubmit
      )}
      sx={{ width: '100%' }}>
      <Typography variant='h1' gutterBottom sx={{ textAlign: 'left', mb: 3 }}>
        Reiniciar contraseña
      </Typography>

      <Typography variant='body2'>
        {token
          ? 'Ingresa tu nueva contraseña'
          : 'Ingresa el correo electrónico con el que hiciste tu cuenta'}
      </Typography>

      <TextInput
        autoFocus
        inputVariant={token ? 'password' : 'email'}
        register={register}
        required={
          token
            ? 'Ingresa tu nueva contraseña'
            : 'Ingresa tu correo electrónico'
        }
        registerName={token ? 'password' : 'email'}
        error={
          token
            ? Boolean(errors.password && touchedFields.password)
            : Boolean(errors.email && touchedFields.email)
        }
      />

      <Button
        variant='contained'
        align='right'
        color='secondary'
        type='submit'
        aria-label='Continuar'
        disabled={!isValid}>
        {isLoading ? (
          <CircularProgress color='neutral' size={30} />
        ) : (
          'Continuar'
        )}
      </Button>
    </Stack>
  );
};

export default ResetPassword;
