import React, { useState } from 'react';
// Components & Utils
import TabsPanel from '../tabs/TabsPanel';
import QuestionsAccordion from './QuestionsAccordion';
import faqQuestions from '../../utils/json/faq.json';
// UI
import {
  Box,
  Tab,
  Tabs,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material';

/**
 * @function QuestionsTabsContainer
 * @author RCH010
 * @version 1.0
 * @description Wrapper for FAQ
 * Shows a Tabs component with the TabsPanel, in a row on desktop and
 * in a column for mobile
 * @returns {JSX}
 */
const QuestionsTabsContainer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        pt: 10,
        flexGrow: 1,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        minHeight: 500,
      }}>
      <Tabs
        variant={isMobile ? 'fullWidth' : 'standard'}
        orientation={isMobile ? 'horizontal' : 'vertical'}
        value={currentTab}
        onChange={handleTabChange}
        aria-label='FAQ sections'
        textColor='secondary'
        indicatorColor='secondary'
        sx={{
          borderRight: isMobile ? 0 : 0.5,
          borderBottom: isMobile ? 0.5 : 0,
          borderColor: (currTheme) => currTheme.palette.secondary.dark,
        }}>
        {faqQuestions.map((section) => (
          <Tab key={section.id} sx={{ color: 'gray' }} label={section.id} />
        ))}
      </Tabs>

      {faqQuestions.map((section, index) => (
        <TabsPanel key={section.id} value={currentTab} index={index}>
          <Typography sx={{ mt: 0, my: 3 }} variant='h3'>
            {section.id}
          </Typography>
          <QuestionsAccordion questions={section.questions} />
        </TabsPanel>
      ))}
    </Box>
  );
};

export default QuestionsTabsContainer;
