import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Utils & Componets
import { UIProvider } from '../providers/UIProvider';
import { AuthProvider } from '../providers/AuthProvider';
import Layout from '../components/ui/Layout';
import ProcessingPayment from '../components/checkout/ProcessingPayment';
import StripeWrapper from '../components/checkout/StripeWrapper';
import { menuOptions } from '../utils/menuOptions';
import ScrollToTop from '../utils/ScrollToTop';
import { RequireAuth } from './RequireAuth';

// Views
import Events from '../views/events/Events';
import Event from '../views/events/Event';
import FAQ from '../views/FAQ';
import Formula1 from '../views/Formula1';
import Mundial2026 from '../views/Mundial2026';
import { Landing } from '../views/Landing';
import Login from '../views/auth/Login';
import SecurityFeatures from '../views/SecurityFeatures';
import TermsAndConditions from '../views/TermsAndConditions';
import Unsubscribe from '../views/Unsubscribe';
import BoxEvent from '../views/boxes/BoxEvent';
import NotFound from '../views/NotFound';
import Booking from '../views/Booking';
import ResetPassword from '../views/auth/ResetPassword';
import { Bookings } from '../views/profile/Bookings';
import { ProfileInfo } from '../views/profile/ProfileInfo';
import { SignUp } from '../views/auth/SignUp';
import { Addresses } from '../views/profile/Addresses';
import ProfileNav from '../views/profile/ProfileNav';
import BoxBooked from '../views/profile/BoxBooked';
import Address from '../views/profile/Address';
import ConfirmAccount from '../views/auth/ConfirmAccount';
import Privacy from '../views/Privacy';

/**
 *
 * /events                                  --> TODOS LOS EVENTOS
 * /events/:eventID                         --> Un evento en particula (BOXEVENT)
 * /events/:eventID/box/:boxID                  --> EL BOX DE UN EVENTO EN PARTICULAR
 * /events/:eventID/box/:boxID/checkout/:type         --> El proceso de renta
 */

// /events/:eventID/title-event
// /events/:eventID/title-event/box/boxID/title-box
// /events/:eventID/title-event/box/:boxID/title-box/checkout/:type

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <UIProvider>
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<Landing />} />
              <Route
                path={menuOptions.SECURITY.path}
                element={<SecurityFeatures />}
              />

              <Route path={menuOptions.FORMULA1.path} element={<Formula1 />} />
              <Route path={menuOptions.MUNDIAL2026.path} element={<Mundial2026 />} />
              <Route
                path='/formula1'
                element={<Navigate to={menuOptions.FORMULA1.path} />}
              />
              <Route path={menuOptions.FAQ.path} element={<FAQ />} />
              <Route path={menuOptions.LOGIN.path} element={<Login />} />
              <Route path={menuOptions.SIGNUP.path} element={<SignUp />} />
              <Route
                path={menuOptions.FORGOTPASSWORD.path}
                element={<ResetPassword />}
              />
              <Route
                path={menuOptions.ACCOUNTVERIFICATION.path}
                element={<ConfirmAccount />}
              />

              <Route path='/events' element={<Events />} />

              <Route path='/events/:eventID/:eventTitle' element={<Event />} />
              <Route path='/events/:eventID' element={<Event />} />

              <Route
                path='/events/:eventID/:eventTitle/box/:boxID'
                element={<BoxEvent />}
              />
              <Route
                path='/events/:eventID/box/:boxID'
                element={<BoxEvent />}
              />
              <Route
                path='/events/:eventID/:eventTitle/box/:boxID/checkout/:type'
                element={
                  <RequireAuth>
                    <Booking />
                  </RequireAuth>
                }
              />
              <Route
                path='/events/:eventID/box/:boxID/checkout/:type'
                element={
                  <RequireAuth>
                    <Booking />
                  </RequireAuth>
                }
              />

              <Route
                path='/processing-payment'
                element={
                  <RequireAuth>
                    <StripeWrapper>
                      <ProcessingPayment />
                    </StripeWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path={menuOptions.TERMSANDCONITIONS.path}
                element={<TermsAndConditions />}
              />
              <Route path={menuOptions.PRIVACY.path} element={<Privacy />} />
              <Route
                path={menuOptions.UNSUBSCRIBE.path}
                element={<Unsubscribe />}
              />
              <Route
                path={menuOptions.PROFILE.path}
                element={
                  <RequireAuth>
                    <ProfileNav />
                  </RequireAuth>
                }>
                <Route path={''} element={<ProfileInfo />} />
                <Route
                  path={menuOptions.ADDRESSES.path}
                  element={<Addresses />}
                />
                <Route
                  path={`${menuOptions.ADDRESSES.path}/:addressID`}
                  element={<Address />}
                />
                <Route
                  path={menuOptions.BOOKINGS.path}
                  element={<Bookings />}
                />
                <Route
                  path={`${menuOptions.BOOKINGS.path}/:bookingID`}
                  element={<BoxBooked />}
                />
                {/* 
                TODO: hacer funcionalidad de favoritos
                <Route
                  path={menuOptions.FAVORITES.path}
                  element={<FavoriteBoxes />}
                /> */}
              </Route>
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        </UIProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
