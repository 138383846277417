import { useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UIContext } from '../providers/UIProvider';
import { transparentNavMenuOptions } from './menuOptions';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const theme = useTheme();
  const { setNavbarBackgroundColor } = useContext(UIContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    if (!transparentNavMenuOptions.includes(pathname) && pathname !== '/') {
      setNavbarBackgroundColor(theme.palette.lightBackground);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}
