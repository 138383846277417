import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import { moneyFormat } from '../../utils/util';
import { currencies } from '../../utils/enums';
// UI
import { Stack, Typography } from '@mui/material';

/**
 * @function Price
 * @author RCH010
 * @version 1.0
 * @description Price element used on ProductCard
 * @param {Number} price short description of prop
 * @param {Number} discount (optional) default: 0 a decimal value that indicates
 * discount percentage
 * @param {String} currency (optional) default: 'MXN' see currencies enum
 * @param {Bool} isLarge (optional) If true, it will be a greater text size
 * @returns {JSX}
 */
const Price = ({ price, discount = 0, currency = currencies.MXN, isLarge }) => {
  let priceToDisplay = discount > 0 ? price : price + discount;
  // Just in case there's a mistake, so no discount is greater than the price
  if (priceToDisplay < 0) {
    priceToDisplay = price;
    discount = 0;
  }
  priceToDisplay = moneyFormat(priceToDisplay, { currency });

  return (
    <Stack spacing='2px'>
      <Typography
        variant='body2'
        sx={{
          fontSize: isLarge ? '1em !important' : '0.8em !important',
          fontWeight: 300,
        }}>
        {priceToDisplay}
      </Typography>
      {discount > 0 && (
        <Typography
          variant='body2'
          sx={{
            textDecoration: 'line-through',
            fontWeight: '300',
            fontSize: isLarge ? '0.9em !important' : '0.75em !important',
            textDecorationThickness: '0.3px',
          }}>
          {moneyFormat(price + discount, { currency })}
        </Typography>
      )}
    </Stack>
  );
};

Price.propTypes = {
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
  currency: PropTypes.string,
};

export default Price;
