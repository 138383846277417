import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components & Utils
import { useBoxEventMenu } from '../../../hooks/requests';
import ProductCard from '../../foodAndBeverages/ProductCard';
import Skeletons from '../stepsStructure/BookingSkeletons';
import ProductModal from '../../foodAndBeverages/ProductModal';
// UI
import { Grid, Stack, Typography } from '@mui/material';

const cardWidth = { xs: '145px', sm: '160px', md: '160px' };

/**
 * @function FoodAndBeveragesForm
 * @author RCH010
 * @version 1.0
 * @description Food and beverages form used on Checkout process
 * @returns {JSX}
 */
const FoodAndBeveragesForm = () => {
  const { eventID, boxID } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const { data: fetchedMenu } = useBoxEventMenu(boxID, eventID, {
    cacheTime: 10000,
    onError: () => console.log('ERROR CARGANDO El menu'),
  });

  const handleModalOpen = (isOpen = true) => {
    setIsModalOpen(isOpen);
  };
  return (
    <Stack sx={{ mt: { xs: 1, md: 3 } }} spacing={1}>
      <Typography variant='h3' gutterBottom>
        ¿Te llevamos algo al palco?
      </Typography>
      <Typography
        as='p'
        variant='subtitle2'
        gutterBottom
        sx={{ lineHeight: 1.3, fontWeight: 400 }}>
        Escoge lo que quieras que dejemos en tu suite el día del evento.
      </Typography>
      {fetchedMenu ? (
        Object.keys(fetchedMenu.items).map((category) => (
          <Stack key={category}>
            <Typography variant='h6' sx={{ py: 1 }}>
              {category}
            </Typography>
            <Grid
              container
              spacing={2.5}
              justifyContent='flex-start'
              alignItems='stretch'>
              {fetchedMenu.items[category].map((product) => (
                <Grid item key={product.id}>
                  <ProductCard
                    cardWidth={cardWidth}
                    handleModalOpen={handleModalOpen}
                    setCurrentProduct={setCurrentProduct}
                    product={{
                      id: product.id,
                      name: product.name,
                      price: product.price,
                      discount: product.discount,
                      pictureURL: product.image,
                      description: product.description,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ))
      ) : (
        <>
          <Skeletons.FoodAndBeverages />
        </>
      )}
      <ProductModal
        isModalOpen={isModalOpen}
        handleModalOpen={handleModalOpen}
        currentProduct={currentProduct}
      />
    </Stack>
  );
};

export default FoodAndBeveragesForm;
