import React from 'react';
import { Outlet } from 'react-router';
import Navbar from './Navbar';
import Footer from './Footer';
import AlertDialog from './AlertDialog';
import SackbarElement from './SackbarElement';

const Layout = () => {
  return (
    <div>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
      <AlertDialog />
      <SackbarElement />
    </div>
  );
};

export default Layout;
