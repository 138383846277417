import { bookingSteps } from '../../utils/enums';
import { zipCodeRegex } from '../../utils/regex';

export const getFormSteps = (boxEventData, bookingType) => {
  const formSteps = [bookingSteps.ADDRESS];

  // Check if box is shared and is booking for a event
  if (boxEventData?.isShared && bookingType === 'event') {
    formSteps.push(bookingSteps.TICKETS);
  }

  // Check if this booking can have food a beverages form
  if (boxEventData?.menuId && bookingType === 'event') {
    formSteps.push(bookingSteps.FOOD_AND_BEVERAGES);
  }

  formSteps.push(bookingSteps.PAYMENT);

  return formSteps;
};

export const isAddressValid = (selectedAddress) => {
  // Check Zip code, street and extNumber
  if (!zipCodeRegex.test(selectedAddress.zip)) return false;
  if (!selectedAddress.street || selectedAddress.street === '') return false;
  if (!selectedAddress.extNumber || selectedAddress.extNumber === '')
    return false;
  return true;
};

export const calculateTotal = (cart) => {
  return Object.values(cart).reduce((prev, curr) => {
    const productTotal = curr.unitPrice * curr.quantity;
    const newTotal = productTotal + prev;
    return newTotal;
  }, 0);
};

// returns an object, where each key is the id and the value is the quantity
// for that id
// {1:10, 3:2} // for item id 1 -> quantity 10; for item id 3, quantity 2
export const createCartCopy = (cart) => {
  return Object.keys(cart).reduce(
    (p, c) => ({ ...p, [c]: cart[c].quantity }),
    {}
  );
};

export const getCartDifference = (copy, cart, withUnitPrice = false) => {
  if (!copy) copy = {};
  const diffCart = [];

  for (const key of Object.keys(cart)) {
    const item = {
      itemId: Number(key),
      comment: cart[key].comment || null,
      quantity: cart[key].quantity - Number(copy[key] || 0),
    };
    if (withUnitPrice) {
      item.unitPrice = cart[key].unitPrice;
    }
    diffCart.push(item);
  }
  return diffCart;
};

export const isNextStepPayment = ({ steps, currentStep }) => {
  let newIndex = steps.findIndex((key) => key === currentStep);
  newIndex += 1;

  return steps[newIndex] === bookingSteps.PAYMENT;
};
