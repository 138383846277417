import React, { useContext, useEffect, useState } from 'react';

// Components & Utils
import { CheckoutContext } from '../../../providers/CheckoutProvider';
import { UIContext } from '../../../providers/UIProvider';
import { useAddresses } from '../../../hooks/requests';
import FormContainer from '../FormContainer';
import SelectAddress from '../address/SelectAddress';
import NewAddressControlled from '../address/NewAddressControlled';

// UI
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';

/**
 * @function AddressForm
 * @author RCH010
 * @version 1.0
 * @description Address form used on Checkout. It renders a select for the
 * address or a controlled form so a new address can be added. This is used on
 * CheckoutProcess
 * @returns {JSX}
 */
const AddressForm = () => {
  const { updateBookingOptions, bookingOptions } = useContext(CheckoutContext);
  const { toggleSnackbar, updateSnackbarProps } = useContext(UIContext);

  const [isNewAddress, setIsNewAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(
    bookingOptions.selectedAddress
  );

  const { data: addresses, isFetchedAfterMount } = useAddresses({
    cacheTime: 5000,
    onError: () => {
      updateSnackbarProps({
        severity: 'warning',
        text: 'Hubo un problema obteniendo sus direcciones.',
      });
      toggleSnackbar(true);
      setIsNewAddress(true);
    },
    retry: false,
  });

  // Onload and addresses are fetched
  // Check if there are addresses for the user. If true, display the select input
  // for those ad
  useEffect(() => {
    if (isFetchedAfterMount) {
      if (!addresses || addresses.length === 0) {
        setIsNewAddress(true);
      } else {
        setIsNewAddress(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchedAfterMount]);

  useEffect(() => {
    if (isNewAddress) {
      updateBookingOptions({ selectedAddress: { ...selectedAddress } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress, isNewAddress]);

  let defaultAddress;
  if (selectedAddress?.id) {
    defaultAddress = selectedAddress?.id;
  } else {
    defaultAddress = addresses && addresses.length > 0 ? addresses[0].id : '';
  }

  return (
    <Box>
      <FormContainer>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h3' gutterBottom>
            ¿A dónde llevamos los pases?
          </Typography>

          {isFetchedAfterMount && addresses && addresses.length > 0 && (
            <Box sx={{ mb: 3, textAlign: 'end' }}>
              <Button
                size='small'
                sx={{ fontSize: '0.82rem' }}
                variant='outlined'
                color='secondary'
                aria-label='Direccion'
                onClick={() => setIsNewAddress((x) => !x)}>
                {isNewAddress
                  ? 'Seleccionar una dirección'
                  : 'Agregar una dirección'}
              </Button>
            </Box>
          )}

          <Box sx={{ display: 'flex', flex: 1 }}>
            {isFetchedAfterMount && isNewAddress !== null ? (
              isNewAddress ? (
                <Stack sx={{ width: '100%' }}>
                  <Typography variant='subtitle2' sx={{ mb: 3 }}>
                    Nueva dirección
                  </Typography>
                  <NewAddressControlled
                    address={selectedAddress}
                    setAddress={setSelectedAddress}
                  />
                </Stack>
              ) : (
                <Stack sx={{ maxWidth: '100%', width: '100%' }}>
                  <Typography variant='subtitle2' sx={{ mb: 3 }}>
                    Tus direcciones
                  </Typography>
                  <SelectAddress
                    addresses={addresses}
                    addressDefaultValue={defaultAddress}
                  />
                </Stack>
              )
            ) : (
              <Stack spacing={4} sx={{ width: '100%', mt: 3 }}>
                <Skeleton variant='rectangular' width='100%' height='15em' />
                <Skeleton variant='rectangular' width='100%' height='4em' />
              </Stack>
            )}
          </Box>
        </Box>
      </FormContainer>
    </Box>
  );
};

export default AddressForm;
