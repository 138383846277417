import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// Components & Utils
import VenuePin from './VenuePin';
import useElementSize from '../../hooks/useElementSize';
// UI
import { Box, useMediaQuery } from '@mui/material';

/**
 * @function VenueMap
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const VenueMap = ({
  venueImage,
  venueHeight,
  venueWidth,
  boxPositions,
  activePins,
  onPinClick,
}) => {
  const [squareRef, { width: containerWidth }] = useElementSize();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const proportionalHeight = (containerWidth * venueHeight) / venueWidth;

  const navigateToBox = (boxId) => {
    navigate(`box/${boxId}`);
  };

  // Top and left positions are calculated with the current dimensions.
  // And the pin size is subtracted (half for x axis)
  return (
    <Box
      ref={squareRef}
      sx={{
        mx: 'auto',
        maxWidth: 'md',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        flex: 1,
        '& img': {
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        },
      }}>
      <img src={venueImage} alt='' />
      {boxPositions.map((boxPosition) => (
        <VenuePin
          onClickPin={
            activePins
              ? onPinClick
                ? onPinClick
                : () => navigateToBox(boxPosition.id)
              : null
          }
          isButton={activePins}
          displayTooltip={activePins}
          {...boxPosition}
          key={boxPosition.id}
          topPosition={
            (boxPosition.y * proportionalHeight) / 100 - (isDesktop ? 40 : 32)
          }
          leftPosition={
            (boxPosition.x * containerWidth) / 100 - (isDesktop ? 14.375 : 11.5)
          }
        />
      ))}
    </Box>
  );
};

VenueMap.defaultProps = {
  activePins: false,
};

VenueMap.propTypes = {
  activePins: PropTypes.bool,
  onPinClick: PropTypes.func,
};

export default VenueMap;
