import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import BottomSheet from './BottomSheet';
import ModalComponent from './ModalComponent';
// Components & Utils
// UI

/**
 * @function ResponsiveModal
 * @author RCH010
 * @version 1.0
 * @description If it's mobile, it will render a bottomSheet,
 *  else a modal at the center of the screen
 * @param {Boolean} isOpen
 * @param {Boolean} showCloseButton
 * @param {Function} onClose
 * @param {Object} ModalProps
 * @param {Object} PaperProps
 * @param {Object} additionalStyles
 * @returns {JSX}
 */
const ResponsiveModal = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return isMobile ? <BottomSheet {...props} /> : <ModalComponent {...props} />;
};

ResponsiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  ModalProps: PropTypes.object,
  PaperProps: PropTypes.object,
  additionalStyles: PropTypes.object,
  children: PropTypes.node,
};

export default ResponsiveModal;
