import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

// Components & Utils
import { AuthContext } from '../../providers/AuthProvider';
import { UIContext } from '../../providers/UIProvider';
import { updateProfile } from '../../api/user';
import TextInput from '../../components/inputs/TextInput';
import DateInput from '../../components/inputs/DateInput';
import CountrySelect from '../../components/inputs/CountrySelect';
import TwoButtons from '../../components/shared/buttons/TwoButtons';
import countries from '../../utils/json/countries.json';
import { parseDate } from '../../utils/util';

// UI
import { CircularProgress, Stack } from '@mui/material';

const getCountry = (code) => {
  return countries.findIndex((country) => country.code === code);
};

// Rage for date of birth   (today-110 years) - (today-18years)
const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 110);
const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 18);

export const ProfileInfo = () => {
  const { auth } = useContext(AuthContext);
  const { toggleSnackbar, updateSnackbarProps } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(false);

  const userInfo = { ...auth.userData };
  const countryIndex = getCountry(userInfo.phoneCountry);
  const defaultUserValues = {
    ...auth.userData,
    phoneCountry: countries[countryIndex],
  };

  const mutation = useMutation(updateProfile);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultUserValues,
    reValidateMode: 'onChange',
  });

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const newValues = {
        ...values,
        phoneCountry: values.phoneCountry?.code,
        birthAt: parseDate(String(values.birthAt), 'yyyy-mm-dd'), // Formate date to yyyy-mm-dd
      };
      await mutation.mutateAsync({ ...newValues });
      updateSnackbarProps({
        severity: 'success',
        text: 'Tu información se ha guardado correctamente.',
      });
      toggleSnackbar(true);
    } catch (error) {
      console.log(error);
      updateSnackbarProps({
        severity: 'error',
        text: 'Algo ha salido mal, por favor inténtalo más tarde',
      });
      toggleSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack component='form' onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
        <TextInput
          fullWidth
          label='Nombre'
          inputVariant='name'
          required='Ingrese su nombre completo'
          registerName='firstName'
          register={register}
          error={Boolean(errors.firstName && touchedFields.firstName)}
        />
        <TextInput
          fullWidth
          label='Apellido paterno'
          inputVariant='firstLastName'
          registerName='lastName'
          required={true}
          register={register}
          error={Boolean(errors.lastName && touchedFields.lastName)}
        />
        <TextInput
          fullWidth
          label='Apellido materno'
          registerName='motherLastName'
          inputVariant='secondLastName'
          register={register}
          error={Boolean(errors.motherLastName && touchedFields.motherLastName)}
        />
      </Stack>
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
        <TextInput
          fullWidth
          label='Correo electrónico'
          registerName='email'
          inputVariant='email'
          required='Por favor ingrese su correo.'
          register={register}
          error={Boolean(errors.email && touchedFields.email)}
        />
        <DateInput
          fullWidth
          rules={{
            required: true,
            validate: (date) => {
              const receivedDate = new Date(date);
              return (
                receivedDate.getTime() > minDate.getTime() &&
                receivedDate.getTime() < maxDate.getTime()
              );
            },
          }}
          control={control}
          registerName='birthAt'
          label='Fecha de nacimiento'
          datePickerProps={{
            maxDate: maxDate,
            minDate: minDate,
            openTo: 'year',
          }}
        />
      </Stack>
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
        {/* TODO: error  */}
        {/* TODO: Mask for phoneinput */}
        <CountrySelect control={control} />
        <TextInput
          fullWidth
          inputVariant='phoneNumber'
          registerName={'phone'}
          register={register}
          required={true}
          error={Boolean(errors.phone && touchedFields.phone)}
        />
      </Stack>
      <Stack alignItems='flex-end' sx={{ py: 2 }}>
        <TwoButtons
          primary={{
            type: 'submit',
            isFullWidth: true,
            isDisabled: !isValid,
            label: isLoading ? (
              <>
                Guardando
                <CircularProgress
                  color='secondary'
                  size='1.9rem'
                  sx={{ ml: 2 }}
                />
              </>
            ) : (
              'Guardar cambios'
            ),
          }}
          containerStyles={{
            width: '100%',
          }}
        />
      </Stack>
    </Stack>
  );
};
