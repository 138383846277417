import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
// UI
import { IconButton, Stack, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const PickerButton = ({ children, ...rest }) => (
  <IconButton
    color='secondary'
    sx={{
      stroke: (theme) => theme.palette.secondary.main,
      color: (theme) => theme.palette.secondary.main,
      strokeWidth: '2px',
      height: '2em',
      width: '2em',
      '& svg': { height: '2em', width: '2em' },
      '&:disabled': {
        stroke: (theme) => theme.palette.grey[400],
        color: (theme) => theme.palette.grey[400],
      },
    }}
    {...rest}>
    {children}
  </IconButton>
);

/**
 * @function NumberPicker
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const NumberPicker = ({ value, onBtnPress, disablePlus, disableMinus }) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='center'>
      <PickerButton onClick={() => onBtnPress('minus')} disabled={disableMinus}>
        <RemoveRoundedIcon />
      </PickerButton>
      <Typography
        sx={{
          fontSize: '4rem !important',
          fontWeight: '700 !important',
          minWidth: '76px',
          textAlign: 'center',
        }}>
        {value}
      </Typography>
      <PickerButton onClick={() => onBtnPress('plus')} disabled={disablePlus}>
        <AddRoundedIcon />
      </PickerButton>
    </Stack>
  );
};

NumberPicker.defaultProps = {
  value: 1,
  onBtnPress: () => {},
  disablePlus: false,
  disableMinus: false,
};

NumberPicker.propTypes = {
  value: PropTypes.number,
  onBtnPress: PropTypes.func,
  disablePlus: PropTypes.bool,
  disableMinus: PropTypes.bool,
};

export default NumberPicker;
