import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ImageLazyLoad from '../shared/ImageLazyLoad';

/**
 * @function VenueItem
 * @author RCH010
 * @version 1.0
 * @description Cards used for the venues carousel, this is a Box that
 * contains the given image
 * @param {String} srcImage Path to the source image
 * @param {String} altText alt text of the image
 * @returns {JSX}
 */
const VenueItem = ({ srcImage, altText, elementHeight, containerSX }) => {
  return (
    <Box
      sx={{
        height: elementHeight,
        width: '100%',
        borderRadius: 8,
        ...containerSX,
      }}>
      <ImageLazyLoad srcHighQuality={srcImage} altText={altText} />
    </Box>
  );
};
VenueItem.defaultProps = {
  elementHeight: '9em',
  containerSX: {},
};

VenueItem.propTypes = {
  elementHeight: PropTypes.string,
  srcImage: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  containerSX: PropTypes.object,
};

export default VenueItem;
