import React, { useState } from 'react';
import PropTypes from 'prop-types';
// UI
import { alpha, Box, Fade, Stack, Typography } from '@mui/material';

/**
 * @function HoverCard
 * @author RCH010
 * @version 1.0
 * @description card that is placed on top of the event onMouseEnters
 * this has a fade animation. This contains a the events title and a link text
 * (styles) the whole card is a button
 * @param {String} eventTitle title that appears on the center of the card
 * @param {Boolean} display if true the Fade effect is activated and the card appears
 * @returns {JSX}
 */
const HoverCard = ({ eventTitle, display }) => {
  const [isCardHover, setIsCardHover] = useState(false);

  return (
    <Fade timeout={600} in={Boolean(display)}>
      <Box
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: (t) => alpha(t.palette.common.black, 0.9),
        }}
        onMouseEnter={() => {
          setIsCardHover(true);
        }}
        onMouseLeave={() => {
          setIsCardHover(false);
        }}>
        <Stack
          sx={{ height: '100%', width: '100%', p: 3 }}
          direction='column'
          justifyContent='space-between'
          alignItems='center'>
          <Stack justifyContent='center' sx={{ height: '100%', width: '100%' }}>
            <Typography textAlign='center' variant='body1'>
              {eventTitle}
            </Typography>
          </Stack>
          <Typography
            color='secondary'
            variant='body2'
            sx={{
              display: 'inline-block',
              '&::after': {
                content: `""`,
                width: isCardHover ? '100%' : '0px',
                height: '1px',
                display: 'block',
                background: (t) => t.palette.secondary.main,
                transition: '450ms',
              },
            }}>
            Ver detalles
          </Typography>
        </Stack>
      </Box>
    </Fade>
  );
};

HoverCard.defaultProps = {
  eventTitle: '',
  display: false,
};

HoverCard.propTypes = {
  eventTitle: PropTypes.string,
  display: PropTypes.bool,
};

export default HoverCard;
