import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import Header from '../Header';
import Skeletons from './BookingSkeletons';

/**
 * @function MobileHeader
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const MobileHeader = ({ boxEventData, eventData, bookingType }) => {
  return !boxEventData ? (
    <Skeletons.MobileHeader />
  ) : (
    <Header
      eventData={eventData}
      boxEventData={boxEventData}
      bookingType={bookingType}
    />
  );
};

MobileHeader.propTypes = {
  bookingType: PropTypes.string,
  eventData: PropTypes.object,
  boxEventData: PropTypes.object,
};

export default MobileHeader;
