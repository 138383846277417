import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box, Typography } from '@mui/material';
import StarRateSharpIcon from '@mui/icons-material/StarRateSharp';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';

const testimonyStars = (numberOfStars) => {
  const stars = [];
  for (let index = 0; index < numberOfStars; index++) {
    stars.push(<StarRateSharpIcon key={`star-${index}`} color='secondary' />);
  }
  return stars;
};

/**
 * @function TestimonyCard
 * @author RCH010
 * @version 1.0
 * @description Card used on Carousel for TestimoniesSection component
 * @param {String} something short description of prop
 * @param {String} text testimony
 * @param {String} author author of the testimony
 * @param {Number} numberOfStars stars that will be rendered
 * @returns {JSX}
 */
const TestimonyCard = ({ text, author, numberOfStars }) => {
  return (
    <Box
      sx={{
        bgcolor: (currTheme) => currTheme.palette.common.white,
        borderRadius: 4,
        p: 4,
        height: '100%',
        maxWidth: '18em',
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Box>
        <FormatQuoteRoundedIcon color='secondary' sx={{ fontSize: '3em' }} />
        <Typography
          variant='body2'
          sx={{
            color: (currTheme) => currTheme.palette.secondary.contrastText,
            mb: 3,
          }}>
          {text}
        </Typography>
      </Box>
      <Box>
        <Typography
          align='right'
          variant='body2'
          sx={{
            fontWeight: 'bold',
            color: (currTheme) => currTheme.palette.secondary.contrastText,
          }}>
          {author}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          {testimonyStars(numberOfStars)}
        </Box>
      </Box>
    </Box>
  );
};

TestimonyCard.defaultProps = {
  text: '',
  author: '',
  numberOfStars: 5,
};

TestimonyCard.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
  numberOfStars: PropTypes.number,
};

export default TestimonyCard;
