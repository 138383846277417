import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

/**
 * @function TabsPanel
 * @author RCH010
 * @version 1.0
 * @description container used as a panel for each tab
 * @param {Number | String} index panel id
 * @param {Number | String} value The current id, this is compared agains the index
 * @param {node} children
 * @returns {JSX}
 */
const TabsPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        width: '100%',
        height: '100%',
      }}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3, width: '100%', height: '100%' }}>{children}</Box>
      )}
    </div>
  );
};

TabsPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabsPanel;
