import React, { createContext, useState } from 'react';

const signUpDefaultData = {
  firstName: '',
  lastName: '',
  motherLastName: '',
  phone: '',
  phoneCountry: null,
  birthAt: null,
  email: '',
  password: '',
  passwordConfirmation: '',
};

export const SignUpContext = createContext();

/**
 * @function SignUpProvider
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const SignUpProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [signUpData, setSignUpData] = useState(signUpDefaultData);

  const updateStep = (increment = 1) => {
    setStep((current) => current + increment);
  };

  return (
    <SignUpContext.Provider
      value={{ step, updateStep, signUpData, setSignUpData }}>
      {children}
    </SignUpContext.Provider>
  );
};

export default SignUpProvider;
