import React, { useContext, useEffect, useRef } from 'react';
// UI
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
// import Formula1Hero from '../assets/heros/Formula1.png';
import BannerF1 from '../assets/formula1/f1-banner.jpg';
import icono1 from '../assets/formula1/benefitsIcons/icono1.png';
import icono2 from '../assets/formula1/benefitsIcons/icono2.png';
import icono3 from '../assets/formula1/benefitsIcons/icono3.png';
import icono4 from '../assets/formula1/benefitsIcons/icono4.png';
import icono5 from '../assets/formula1/benefitsIcons/icono5.png';
import Formula1HeroMobile from '../assets/formula1/banner-mobile.png';
// import LowFormula1Hero from '../assets/heros/LowFormula1.png';
// import LowFormula1HeroMobile from '../assets/heros/LowFormula1Mobile.png';
// // import CIEandOasisImage from '../assets/formula1/CIEandOasis.png';
// import box01 from '../assets/formula1/box/box01.png';
// Components & Utils
import ViewContainer from '../components/shared/ViewContainer';
import ImageLazyLoad from '../components/shared/ImageLazyLoad';
import LargeButton from '../components/shared/buttons/LargeButton';
import DownloadAppSection from '../components/shared/DownloadAppSection';
import FAQSection from '../components/shared/FAQSection';
import Carousel from '../components/carousel/Carousel';
import VenueItem from '../components/carousel/VenueItem';
import ContactForm from '../components/shared/ContactForm';
import HalfCircle from '../components/shared/HalfCircle';
import useElementOnScreen from '../hooks/useElementOnScreen';
import { f1BoxImages } from '../utils/formula1Data';
// import { openExternalLink, scrollIntoRef } from '../utils/util';
import { UIContext } from '../providers/UIProvider';
// import ImageWithTextCard from '../components/shared/ImageWithTextCard';
import { getResponsiveObjectForFormula1 } from '../utils/carouselResponsive';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import { contactData } from '../utils/contactData';

const defaultElementOnScreenOptions = {
  rootMargin: '0px',
  threshold: 0.95,
};

/**
 * @function Formula1
 * @author RCH010
 * @version 1.0
 * @description Formula1 view
 * This view contains promotional content for Formula1 GrandSandClub
 * Has a picture with text and a CTA, a grid with all the benefits, a carousel
 * with pictures of the box, the contact form, FAQ and downloadApp sections
 * @returns {JSX}
 */
const Formula1 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  useDocumentTitle('Oasis App - GP CDMX 2023');
  const contactFormRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setNavbarBackgroundColor } = useContext(UIContext);
  const [bannerRef, isBannerRefVisible] = useElementOnScreen(
    defaultElementOnScreenOptions
  );
  const responsiveCarouselBreakpoints = getResponsiveObjectForFormula1(theme);

  useEffect(() => {
    if (!isBannerRefVisible) {
      setNavbarBackgroundColor(theme.palette.lightBackground);
    } else {
      setNavbarBackgroundColor('transparent');
    }
  }, [
    isBannerRefVisible,
    setNavbarBackgroundColor,
    theme.palette.lightBackground,
  ]);

  return (
    <ViewContainer>
      <Box ref={bannerRef} sx={{ position: 'relative' }}>
        <ImageLazyLoad
          srcHighQuality={isMobile ? Formula1HeroMobile : BannerF1}
          altText=''
        />
      </Box>
      <HalfCircle isLeftCircle left='-2em' top={isMobile ? '70vh' : '70em'} />
      <HalfCircle right='-2em' bottom='80em' opacity='.6' />
      <Box
        sx={{
          zIndex: 1,
          position: 'relative',
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
          mx: 'auto',
          mt: { xs: 10, md: 15 },
          px: 3,
        }}>
        <Typography
          variant='h1'
          textAlign='center'
          sx={{ mb: 2, fontSize: { xs: '2rem', md: '3.5rem' } }}>
          ¡El Gran Premio de México 2024 <br />de manera exclusiva y segura!
        </Typography>

        <Stack>
          <Typography variant='body1' textAlign='center' sx={{ mt: 4, mb: 6 }}>
            <p>¡El evento más esperado del año! Y en Oasis App tenemos el lugar que estas buscando, desde el mejor ambiente en
                el Foro Fol, Skyboxes en Zona Azul, Boxes en Zona Amarilla, Main Grandstand en Zona Verde y más.</p>
            <p>Con nosotros la seguridad es prioridad contamos con los mejores filtros de seguridad antifraudes que existen en el
                mercado.</p>
            <p>En Oasis App, no solo asistes a los mejores eventos, ¡los vivimos juntos! Únete a nosotros y asegura tu presencia en el
                Gran Prix de Fórmula 1 en Ciudad de México, donde la emoción alcanza su máxima velocidad.</p>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <LargeButton
              children='Compra tus boletos'
              onClick={() => navigate('/events/2004/gran-premio-de-mexico-2024-formula-1')}
            />
          </Box>
        </Stack>

        <Typography variant='h2' align='center' sx={{ mt: 15 }}></Typography>
        <Carousel
          carouselItems={f1BoxImages}
          Item={(props) => <VenueItem {...props} elementHeight='15em' />}
          responsive={responsiveCarouselBreakpoints}
        />

        <Typography variant='h2' align='center' sx={{ mt: 15 }}>
          Beneficios de comprar tus accesos con Oasis
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mb: 4,
            '& img': { maxWidth: '8em' },
          }}>
          <Grid
            sx={{ mt: 2, pb: 4 }}
            container
            justifyContent='center'
            alignItems='center'
            spacing={5}>
            <Grid item xs={12} md={6}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{
                  textAlign: 'center',
                  alignSelf: 'stretch',
                  px: { xs: 0, md: 6 },
                }}>
                <img src={icono1} alt='' />
                Asistencia personalizada por Concierge Oasis antes, durante y
                después del evento.
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{
                  textAlign: 'center',
                  alignSelf: 'stretch',
                  px: { xs: 0, md: 6 },
                }}>
                <img src={icono2} alt='' />
                Inventario de boletos cargados por Oasis App cumpliendo con
                seguridad antifraudes.
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{ textAlign: 'center', alignSelf: 'stretch' }}>
                <img src={icono3} alt='' />
                Entrega de accesos a domicilio en la Ciudad de México en la
                semana antes del evento para evitar su comercialización y/o mal
                uso de reventa.
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{ textAlign: 'center', alignSelf: 'stretch' }}>
                <img src={icono4} alt='' />
                Facilidad en métodos de pago con tarjetas de crédito, débito,
                transacciones bancarias, depósitos y efectivo.
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                alignItems='center'
                justifyContent='flex-start'
                sx={{ textAlign: 'center', alignSelf: 'stretch' }}>
                <img src={icono5} alt='' />
                Comisiones 100% facturables y posibilidad de manejo bajo
                contrato.
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Stack
          sx={{ maxWidth: 'sm', gap: 4, mx: 'auto', mt: 2 }}
          alignItems='center'>
          <Button
            onClick={() => navigate('/events/2004/gran-premio-de-mexico-2024-formula-1')}
            size='large'
            color='secondary'
            aria-label='Compra tus boletos'
            fullWidth
            variant='contained'
            sx={{
              px: '2.5em',
              boxShadow: '2px 3px 20px rgba(41, 237, 135, 0.3)',
            }}>
            Compra tus boletos
          </Button>
          <Typography variant='h2' align='center' sx={{ mt: 6 }}>
            ¿Tienes alguna duda?
          </Typography>
          <Button
            size='large'
            color='secondary'
            aria-label='Contacta a un asesor Oasis'
            sx={{ cursor: 'pointer' }}
            variant='outlined'
            onClick={() => {
              window.open(contactData.whatsapp.href, '_blank');
            }}>
            Contacta a un asesor Oasis
          </Button>
        </Stack>
      </Box>
      <Box sx={{ mt: 10, zIndex: 1, position: 'relative' }}>
        <span ref={contactFormRef} />
        <ContactForm noBackground />
      </Box>
      <DownloadAppSection />
      <FAQSection />
    </ViewContainer>
  );
};

export default Formula1;
