import { APITypes, httpRequest } from '../utils/util';
const copomexToken = process.env.REACT_APP_COPOMEX_TOKEN;

// Addresses
export const getAddresses = async () => {
  const res = await httpRequest('app/addresses');
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getAddress = async (id) => {
  const res = await httpRequest(`app/addresses/${id}`);
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const addAddress = async ({
  street,
  extNumber,
  intNumber,
  neighborhood,
  city,
  state,
  country,
  zip,
}) => {
  const res = await httpRequest(`app/addresses`, {
    body: {
      street,
      extNumber,
      intNumber,
      neighborhood,
      city,
      state,
      country,
      zip,
    },
    method: 'POST',
  });
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const editAddress = async ({
  id,
  street,
  extNumber,
  intNumber,
  neighborhood,
  city,
  state,
  country,
  zip,
}) => {
  const res = await httpRequest(`app/addresses/${id}`, {
    body: {
      street,
      extNumber,
      intNumber,
      neighborhood,
      city,
      state,
      country,
      zip,
    },
    method: 'PUT',
  });
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getCopomexData = async (cp) => {
  const res = await httpRequest(
    `${cp}?type=simplified&token=${copomexToken}`,
    {},
    APITypes.COPOMEX,
    false
  );
  const data = res.response;

  /// TODO: define error codes
  if (res.response && !res.error) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getProfile = async () => {
  const res = await httpRequest(`me`, {}, APITypes.PIXAN);
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

// Profile

export const updateProfile = async ({
  firstName,
  lastName,
  motherLastName,
  email,
  phone,
  phoneCountry,
  birthAt,
}) => {
  const res = await httpRequest(
    `me`,
    {
      body: {
        firstName,
        lastName,
        motherLastName,
        email,
        phone,
        phoneCountry,
        birthAt,
      },
      method: 'PUT',
    },
    APITypes.PIXAN
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const updateProfileImage = async (imageURL) => {
  const res = await httpRequest(
    `me/picture`,
    {
      body: {
        imageURL,
      },
      method: 'PUT',
    },
    APITypes.PIXAN
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const createProfile = async ({
  firstName,
  lastName,
  motherLastName,
  email,
  phone,
  phoneCountry,
  birthAt,
  password,
  passwordConfirmation,
}) => {
  const res = await httpRequest(
    `auth/signup`,
    {
      body: {
        firstName,
        lastName,
        motherLastName,
        email,
        phone,
        phoneCountry,
        birthAt: new Date(birthAt).toISOString().split('T')[0],
        password,
        passwordConfirmation,
      },
      method: 'POST',
    },
    APITypes.PIXAN
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  return res;
};

export const forgotPassword = async ({ email }) => {
  const res = await httpRequest(
    `auth/forgot`,
    {
      body: {
        email,
      },
      method: 'POST',
    },
    APITypes.PIXAN
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const resetPassword = async ({ token, password }) => {
  const res = await httpRequest(
    `auth/reset`,
    {
      body: {
        token,
        password,
      },
      method: 'POST',
    },
    APITypes.PIXAN
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const verifyAccount = async ({ token }) => {
  const res = await httpRequest(
    `auth/email-verification`,
    {
      body: {
        token,
      },
      method: 'POST',
    },
    APITypes.PIXAN,
    false
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const resendVerification = async ({ email }) => {
  const res = await httpRequest(
    `auth/resend-verification`,
    {
      body: {
        email,
      },
      method: 'POST',
    },
    APITypes.PIXAN
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.status === 'ok') return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};
