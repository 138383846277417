import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

// Components & Utils
import { CheckoutContext } from '../../providers/CheckoutProvider';
import { UIContext } from '../../providers/UIProvider';
import { useBoxEvent, useCart, useEvent } from '../../hooks/requests';
import { addAddress, editAddress } from '../../api/user';

import ProgressButtons from './ProgressButtons';
import MobileHeader from './stepsStructure/MobileHeader';
import Skeletons from './stepsStructure/BookingSkeletons';
import BookingSteps from './BookingSteps';
import SeeSummaryButton from './SeeSummaryButton';
import FormStepper from './FormStepper';
import Summary from './Summary';

// UI
import { Box, Drawer, Grid, Stack, useMediaQuery } from '@mui/material';
import { logAnalyticsEvent } from '../../firebase';
import { bookingSteps as stepsEnum } from '../../utils/enums';
import {
  createCartCopy,
  getCartDifference,
  getFormSteps,
  isAddressValid,
  isNextStepPayment,
} from './checkoutHelper';
import { addCartProducts } from '../../api/foodAndBeverages';
import BannersCarousel from '../landing/BannersCarousel';
import { sponsorsData } from '../../utils/sponsorsData';

/**
 * @function CheckoutProcess
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const CheckoutProcess = () => {
  const {
    setEventData,
    setBoxData,
    steps,
    setSteps,
    currentStep,
    updateStep,
    bookingOptions,
    updateBookingOptions,
    cart,
    cartCopy,
    setCartCopy,
    updateItemsOnCartDefault,
  } = useContext(CheckoutContext);
  const { toggleSnackbar, updateSnackbarProps } = useContext(UIContext);

  const { eventID, boxID, type: bookingType } = useParams();

  const [primaryLabel, setPrimaryLabel] = useState('Siguiente');
  const [isSummaryDrawerOpen, setIsSummaryDrawerOpen] = useState(false);
  const [processButton, setProcessButton] = useState({
    isLoading: false,
    label: 'Siguiente',
  });

  const [triggerPayment, setTriggerPayment] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const addAddressMutation = useMutation(addAddress);
  const editAddressMutation = useMutation(editAddress);
  const addProductsToCart = useMutation(addCartProducts);

  const { data: eventData } = useEvent(eventID, {
    cacheTime: 5000,
    onError: () => {},
  });

  const { data: boxEventData } = useBoxEvent(boxID, eventID, {
    cacheTime: 5000,
    onError: () => {},
  });

  useCart(
    { boxId: boxID, eventId: eventID },
    {
      enabled: Boolean(boxEventData?.menuId && bookingType === 'event'),
      onSuccess: (data) => {
        updateItemsOnCartDefault(data.items);
      },
    }
  );

  useEffect(() => {
    setEventData(eventData);
  }, [eventData, setEventData]);

  useEffect(() => {
    setBoxData(boxEventData);
  }, [boxEventData, setBoxData]);

  // Set booking steps on Checkout context
  useEffect(() => {
    const bookingSteps = getFormSteps(boxEventData, bookingType);
    setSteps(bookingSteps);
    updateStep({ newStep: bookingSteps[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingType, boxEventData]);

  useEffect(() => {
    logAnalyticsEvent({
      eventName: `checkout_step_${currentStep}`,
      params: {
        box_id: boxID,
        event_id: eventID,
        is_box_shared: boxEventData?.isShared ? 1 : 0,
      },
    });
  }, [boxEventData?.isShared, boxID, eventID, currentStep]);

  const setAddressOnUser = async () => {
    const { selectedAddress } = bookingOptions;
    try {
      if (selectedAddress.id) {
        await editAddressMutation.mutateAsync({ ...selectedAddress });
      } else {
        const address = await addAddressMutation.mutateAsync({
          ...selectedAddress,
        });
        updateBookingOptions({ selectedAddress: { ...address } });
      }
    } catch (error) {
      updateSnackbarProps({
        severity: 'error',
        text: 'Hubo un problema guardado la dirección, por favor inténtalo nuevamente.',
      });
      toggleSnackbar(true);
    }
  };

  const nextStep = async () => {
    setProcessButton((t) => ({ ...t, isLoading: true }));
    let continueToNextStep = true;

    switch (currentStep) {
      case stepsEnum.ADDRESS:
        // Check addres
        if (
          !bookingOptions?.selectedAddress ||
          !isAddressValid(bookingOptions.selectedAddress)
        ) {
          updateSnackbarProps({
            severity: 'warning',
            text: 'La dirección no es válida, por favor resvise que esta sea correcta',
          });
          toggleSnackbar(true);
          continueToNextStep = false;
          break;
        }
        await setAddressOnUser();
        break;
      case stepsEnum.FOOD_AND_BEVERAGES:
        try {
          await addProductsToCart.mutateAsync({
            boxId: boxID,
            eventId: eventID,
            items: getCartDifference(cartCopy, cart),
          });
          setCartCopy(createCartCopy(cart));
        } catch (error) {
          updateSnackbarProps({
            severity: 'error',
            text: 'Oh no, no se ha podido guardar la información de tu carrito. Por favor contácta al equipo Oasis para solucionarlo.',
          });
          toggleSnackbar(true);
          continueToNextStep = false;
        }
        break;
      case stepsEnum.TICKETS:
        // Future feature goes here
        // When adding friends or something else
        break;
      case stepsEnum.PAYMENT:
        setTriggerPayment(true);
        break;

      default:
        break;
    }
    if (isNextStepPayment({ steps, currentStep }) && continueToNextStep) {
      setPrimaryLabel('Pagar');
    }
    if (continueToNextStep) updateStep();
    setProcessButton((t) => ({ ...t, isLoading: false }));
  };

  const backStep = () => {
    setPrimaryLabel('Siguiente');
    updateStep({ increment: false });
  };

  return (
    <>
      <Box
        sx={{
          pt: { xs: 10, md: 13 },
          px: 3,
          pb: 10,
          mx: 'auto',
          minHeight: '100vh',
          maxWidth: 'lg',
        }}>
        {isMobile && (
          <MobileHeader {...{ boxEventData, eventData, bookingType }} />
        )}

        <Stack
          direction='row'
          spacing={{ md: 4, lg: 8 }}
          sx={{
            position: 'relative',
            minHeight: `calc(100vh - ${(isMobile ? 20 : 23) * 8}px)`,
          }}>
          <Stack sx={{ flex: 1, mt: { xs: 2, md: 0 }, maxWidth: { xs: '100%', md: '100%', lg: '60%' } }}>
            {!boxEventData || !currentStep ? (
              <Skeletons.BoxEventData />
            ) : (
              <>
                <FormStepper />
                <SeeSummaryButton
                  setDrawer={setIsSummaryDrawerOpen}
                  display={isMobile}
                />
                <BookingSteps
                  eventID={eventID}
                  boxID={boxID}
                  triggerPayment={triggerPayment}
                  setTriggerPayment={setTriggerPayment}
                />
                <Grid container>
                  <Grid item xs={12}>
                    <BannersCarousel
                      title=''
                      data={sponsorsData}
                      variantTitle='h2'
                      bkDesk={1}
                      bkTablet={1}
                      bkMobile={1}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Stack>
          {!isMobile &&
            (!boxEventData ? (
              <Skeletons.Summary />
            ) : (
              <Summary
                onNext={nextStep}
                onBack={backStep}
                buttonProps={processButton}
                primaryLabel={primaryLabel}
              />
            ))}
        </Stack>
        <Drawer
          anchor='right'
          open={isSummaryDrawerOpen}
          onClose={() => setIsSummaryDrawerOpen(false)}
          PaperProps={{
            sx: {
              bgcolor: (theme) => theme.palette.lighterPrimary,
              width: '20em',
              maxWidth: '85vw',
            },
          }}>
          <Summary isDrawer />
        </Drawer>
      </Box>
      {/* --- BOTTOM BUTTONS ONLY ON MOBILE ---*/}
      {isMobile && (
        <ProgressButtons
          wrapForMobile={true}
          onNext={nextStep}
          onBack={backStep}
          buttonProps={processButton}
          primaryLabel={primaryLabel}
        />
      )}
      
    </>
  );
};

export default CheckoutProcess;
