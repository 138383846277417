import React, { useContext } from 'react';
// Components & Utils
import { CheckoutContext } from '../../providers/CheckoutProvider';
import TwoButtons from '../shared/buttons/TwoButtons';
import { Box } from '@mui/material';

/**
 * @function ProgressButtons
 * @author RCH010
 * @version 1.0
 * @description Back and forward buttons, this should only be used on the
 * checkoutprocess because it must have access to the checkout provider
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const Buttons = ({
  onNext,
  onBack,
  buttonProps,
  primaryLabel = 'Siguiente',
  secondaryLabel = 'Atrás',
  primaryHide = false,
  secondaryHide = false,
}) => {
  const { checkoutButtons } = useContext(CheckoutContext);
  return (
    <TwoButtons
      {...(!primaryHide && {
        primary: {
          isFullWidth: true,
          label: primaryLabel,
          onClick: onNext,
          isLoading: buttonProps.isLoading || checkoutButtons.isLoading,
          isDisabled: buttonProps.isLoading || checkoutButtons.isLoading,
        },
      })}
      {...(!secondaryHide && {
        secondary: {
          label: secondaryLabel,
          onClick: onBack,
        },
      })}
      containerStyles={{
        width: '100%',
      }}
    />
  );
};

const ProgressButtons = ({ wrapForMobile, ...rest }) =>
  wrapForMobile ? (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 20,
        bottom: 0,
        background:
          'linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.9) 106.1%)',
        px: 3,
        py: 4,
      }}>
      <Buttons {...rest} />
    </Box>
  ) : (
    <Buttons {...rest} />
  );

export default ProgressButtons;
