import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { getRandomValue } from '../../../utils/util';

const MobileHeader = () => (
  <Stack sx={{ width: '100%' }}>
    <Skeleton height='4em' width='100%' />
    <Skeleton height='2em' width='100%' />
  </Stack>
);

const BoxEventData = () => (
  <Stack spacing={4} sx={{ width: '100%' }}>
    <Skeleton
      variant='rectangular'
      sx={{ height: { xs: '3em', md: '6em' } }}
      width='100%'
    />
    <Skeleton variant='rectangular' height='3em' width='100%' />
    <Skeleton variant='rectangular' height='20em' width='100%' />
  </Stack>
);

const Summary = () => (
  <Skeleton variant='rectangular' height={`calc(100vh - 184px)`} width='20em' />
);

const FoodAndBeverages = () => (
  <Stack sx={{ mt: { xs: 1, md: 3 } }} spacing={1}>
    {[1, 2, 3, 4].map((_, index) => (
      <Stack key={`row-${index}`} spacing={1}>
        <Skeleton
          variant='text'
          height='44px'
          width={`${getRandomValue(30, 85)}%`}
        />
        <Stack>
          <Grid
            container
            spacing={2.5}
            justifyContent='flex-start'
            alignItems='stretch'>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((product, indx) => (
              <Grid item key={indx}>
                <Box>
                  <Stack spacing={1}>
                    <Skeleton
                      variant='rectangular'
                      height='150px'
                      sx={{
                        width: { xs: '145px', sm: '160px', md: '160px' },
                        borderRadius: '8px',
                      }}
                    />
                    <Skeleton
                      variant='text'
                      height='1em'
                      width={`${getRandomValue(50, 100)}%`}
                      sx={{
                        borderRadius: '8px',
                      }}
                    />
                    <Skeleton
                      variant='text'
                      height='0.7em'
                      width={`${getRandomValue(50, 95)}%`}
                      sx={{
                        borderRadius: '8px',
                      }}
                    />
                    <Skeleton
                      variant='text'
                      height='0.8em'
                      width={`${getRandomValue(50, 95)}%`}
                      sx={{
                        borderRadius: '8px',
                      }}
                    />
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    ))}
  </Stack>
);

const Skeletons = {
  BoxEventData,
  MobileHeader,
  Summary,
  FoodAndBeverages,
};

export default Skeletons;
