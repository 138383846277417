import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box, Typography, useTheme } from '@mui/material';
import { getResponsiveObjectForBannersCarosuel } from '../../utils/carouselResponsive';
import BannerItem from './BannerItem';
import CarouselSponsors from '../carousel/CarouselSponsors';

/**
 * @function BannersCarousel
 * @author AxelML
 * @version 1.0
 * @returns {JSX}
 */

const BannersCarousel = ({title, data, variantTitle, bkDesk, bkTablet, bkMobile}) => {
  const theme = useTheme();
  const responsiveCarouselBreakpoints =
    getResponsiveObjectForBannersCarosuel(theme, bkDesk, bkTablet, bkMobile);

  return(
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
          mx: 'auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        <Typography align='center' variant={variantTitle} sx={{ px: 3, mb: 4 }}>
          {title}
        </Typography>

        <CarouselSponsors
          autoPlaySpeed={5000}
          arrow={false}
          carouselItems={data}
          Item={BannerItem}
          responsive={responsiveCarouselBreakpoints}
        />
      </Box>
    </Box>
  );
};

BannersCarousel.defaultProps = {
  title: '',
  data: [],
  variantTitle: '',
  bkDesk: 1, 
  bkTablet: 1,
  bkMobile: 1,
};

BannersCarousel.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  variantTitle: PropTypes.string,
  bkDesk: PropTypes.number, 
  bkTablet: PropTypes.number,
  bkMobile: PropTypes.number,
}

export default BannersCarousel;