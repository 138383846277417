import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography } from '@mui/material';
// Components & Utils
// UI
import emptyStateImage from '../../assets/emptyStates/emptyDessert.svg';

/**
 * @function EmptyState
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const EmptyState = ({ title, subtitle, buttonLabel, onButtonClick }) => {
  return (
    <Stack
      spacing={5}
      sx={{
        position: 'relative',
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        mx: 'auto',
        py: { xs: 5, md: 10 },
      }}>
      <Stack spacing={1} alignItems='center'>
        <Box
          sx={{
            pt: 2,
            width: 'fit-content',
            '& img': { maxWidth: { xs: '10em', md: '15em' } },
          }}>
          <img src={emptyStateImage} alt='' />
        </Box>
        <Typography variant='h3' textAlign='center'>
          {title}
        </Typography>
        <Typography variant='h6' textAlign='center'>
          {subtitle}
        </Typography>
      </Stack>
      <Stack sx={{ width: '100%' }} alignItems='center'>
        <Box sx={{ width: { xs: '100%', md: 'unset' } }}>
          <Button
            fullWidth
            size='large'
            aria-label='Empty State'
            color='secondary'
            variant='contained'
            onClick={onButtonClick}>
            {buttonLabel}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

EmptyState.defaultProps = {
  title: '',
  subtitle: '',
  buttonLabel: '',
  onButtonClick: () => {},
};

EmptyState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default EmptyState;
