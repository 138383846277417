import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import { emailRegex, passwordRegex } from '../../utils/regex';
// UI
import { InputAdornment, TextField, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';

const iconStyles = {
  color: grey[400],
  transition: 'color 250ms',
};

const variantsProps = {
  email: {
    type: 'email',
    label: 'Correo Electrónico',
    placeholder: 'ejemplo@gmail.com',
    validations: {
      pattern: {
        value: emailRegex,
        message: 'Este no parece ser un correo válido.',
      },
    },
    startAdornment: (
      <InputAdornment position='start'>
        <EmailIcon sx={iconStyles} />
      </InputAdornment>
    ),
  },
  password: {
    label: 'Contraseña',
    type: 'password',
    placeholder: '',
    validations: {
      pattern: {
        value: passwordRegex,
        message:
          'La contraseña debe contener mínimo 8 caracteres, al menos una mayúscula, una minuscula y un número',
      },
    },
    startAdornment: (
      <InputAdornment position='start'>
        <LockIcon sx={iconStyles} />
      </InputAdornment>
    ),
  },
  name: {
    label: 'Nombre(s)',
    type: 'text',
    placeholder: 'Sergio',
    validations: {
      minLength: {
        value: 1,
        message: 'Por favor ingrese su nombre completo',
      },
      maxLength: {
        value: 200,
        message: 'El nombre esta muy largo.',
      },
    },
    startAdornment: (
      <InputAdornment position='start'>
        <PersonIcon sx={iconStyles} />
      </InputAdornment>
    ),
  },
  firstLastName: {
    label: 'Apellido Paterno',
    type: 'text',
    placeholder: 'Ramos',
    validations: {
      minLength: {
        value: 1,
        message: 'Por favor ingrese su apellido paterno',
      },
      maxLength: {
        value: 200,
        message: 'El apellido esta muy largo.',
      },
    },
    startAdornment: null,
  },
  secondLastName: {
    label: 'Apellido Materno',
    type: 'text',
    placeholder: 'Garcia',
    validations: {
      minLength: {
        value: 1,
        message: 'Por favor ingrese su apellido materno',
      },
      maxLength: {
        value: 200,
        message: 'El apellido esta muy largo.',
      },
    },
    startAdornment: null,
  },
  text: {
    type: 'text',
    placeholder: 'Pasa el placeholder por prop',
    label: '',
    validations: {},
    startAdornment: null,
  },
  phoneNumber: {
    label: 'Teléfono',
    type: 'phoneNumber',
    placeholder: '(444) 444 4444',
    validations: {
      minLength: {
        value: 10,
        message: 'Por favor ingrese un número válido',
      },
      maxLength: {
        value: 10,
        message: 'Por favor ingrese un número válido',
      },
    },
    startAdornment: (
      <InputAdornment position='start'>
        <PhoneIcon sx={iconStyles} />
      </InputAdornment>
    ),
  },
};

/**
 * @function TextInput
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const TextInput = ({
  inputVariant,
  register,
  registerName,
  required,
  additionalValidations,
  label,
  labelColor,
  additionalStyles,
  ...otherProps
}) => {
  const [isPasswordInputVisible, setIsPasswordInputVisible] = useState(false);

  const onClickShowPassword = () => {
    setIsPasswordInputVisible((isShown) => !isShown);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let inputType = variantsProps[inputVariant].type;
  if (inputVariant === 'password') {
    inputType = isPasswordInputVisible ? 'text' : 'password';
  }

  return (
    <TextField
      label={label ? label : variantsProps[inputVariant].label}
      variant='outlined'
      color='secondary'
      type={inputType}
      required={Boolean(required)}
      placeholder={variantsProps[inputVariant].placeholder}
      sx={{
        m: 0,
        '& .Mui-focused': {
          '& * svg': {
            color: 'secondary.main',
          },
        },
        '& .Mui-error': {
          '& * svg': {
            color: (currTheme) => currTheme.palette.error.main,
          },
        },
        '& label': {
          fontWeight: 'bolder',
          fontSize: '20px',
          lineHeight: '23px',
          top: '-6px',
          color: labelColor
        },
        ...additionalStyles
      }}
      InputProps={{
        startAdornment: variantsProps[inputVariant].startAdornment,
        endAdornment:
          inputVariant === 'password' ? (
            <InputAdornment position='end' color='blue'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={onClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'>
                {isPasswordInputVisible ? (
                  <VisibilityOff sx={iconStyles} />
                ) : (
                  <Visibility sx={iconStyles} />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      {...otherProps}
      {...register(registerName, {
        required: required,
        ...variantsProps[inputVariant].validations,
        ...additionalValidations,
      })}
    />
  );
};

TextInput.defaultProps = {
  label: null,
  labelColor: 'white',
  registerName: 'untitled',
  inputVariant: 'text',
  required: false,
  additionalValidations: {},
  additionalStyles: {},
};

TextInput.propTypes = {
  register: PropTypes.func.isRequired,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputVariant: PropTypes.oneOf(Object.keys(variantsProps)),
  additionalValidations: PropTypes.object,
  registerName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  additionalStyles: PropTypes.object,
};

export default TextInput;
