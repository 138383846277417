import React from 'react';

// UI
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Utils
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
import countries from '../../utils/json/countries.json';
import { Controller } from 'react-hook-form';

const CountrySelect = ({
  control,
  registerName,
  defaultValue,
  rules,
  ...rest
}) => {
  return (
    <Controller
      defaultValue={defaultValue}
      name={registerName}
      control={control}
      rules={rules}
      onChange={([, data]) => data}
      render={({ field }) => (
        <Autocomplete
          {...rest}
          {...field}
          id='country-select'
          fullWidth
          options={countries}
          onChange={(e, data) => field.onChange(data)}
          autoHighlight
          isOptionEqualToValue={(option, value) => option.code === value.code}
          getOptionLabel={(option) => `${option.label}`}
          renderOption={(props, option) => (
            <Box
              component='li'
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}>
              <img
                loading='lazy'
                width='20'
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=''
              />
              {option.label} +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              sx={{ m: 0 }}
              color='secondary'
              {...params}
              label='Pais'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'country-code', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
    />
  );
};

CountrySelect.defaultProps = {
  rules: { required: true },
  registerName: 'phoneCountry',
};

export default CountrySelect;
