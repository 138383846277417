import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// UI
import { Skeleton, Stack } from '@mui/material';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

/**
 * @function StripeWrapper
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const StripeWrapper = ({ children }) => {
  return (
    <>
      {stripePromise ? (
        <Elements stripe={stripePromise}>{children}</Elements>
      ) : (
        <Stack spacing={4} sx={{ width: '100%', mt: 3 }}>
          <Skeleton variant='rectangular' width='100%' height='15em' />
          <Skeleton variant='rectangular' width='100%' height='4em' />
        </Stack>
      )}
    </>
  );
};

export default StripeWrapper;
