import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
// UI
import stadiumPin from '../../assets/others/stadiumPin.png';
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  styled,
  alpha,
  Zoom,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import ImageLazyLoad from '../shared/ImageLazyLoad';

const BoxTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.grey[900], 0.9),
    maxWidth: '200px',
    padding: '0 0 10px 0',
    borderRadius: '16px',
    overflow: 'hidden',
  },
}));

/**
 * @function VenuePin
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const VenuePin = ({
  topPosition,
  leftPosition,
  isButton,
  pictureUrl,
  boxZone,
  number,
  availableSeats,
  displayTooltip,
  onClickPin,
  // price, // TODO
  // pricePerTicket,
}) => {
  return (
    <>
      <BoxTooltip
        disableHoverListener={!displayTooltip}
        TransitionComponent={Zoom}
        title={
          <>
            <Stack>
              <ImageLazyLoad
                objectFitImage='cover'
                altText=''
                srcHighQuality={pictureUrl}
                containerStyles={{
                  width: '200px',
                  height: '150px',
                }}
              />
              <Box sx={{ px: 1 }}>
                <Typography
                  variant='body2'
                  sx={{
                    fontWeight: 600,
                  }}>
                  {`${boxZone}, ${number}`}
                </Typography>
                {availableSeats && (
                  <Typography
                    variant='body2'
                    sx={{
                      fontSize: '0.8rem',
                    }}>
                    {`${availableSeats} asientos`}
                  </Typography>
                )}
              </Box>
            </Stack>
          </>
        }>
        <Box
          onClick={onClickPin}
          component={isButton ? Button : 'div'}
          sx={{
            position: 'absolute',
            top: topPosition,
            left: leftPosition,
            p: 0,
            height: 'fit-content',
            width: 'fit-content',
            minWidth: 'fit-content',
            '& img': {
              height: { xs: '32px', md: '40px' },
              width: { xs: '23px', md: '28.75px' },
            },
          }}>
          <img src={stadiumPin} alt='' />
        </Box>
      </BoxTooltip>
    </>
  );
};

VenuePin.defaultProps = {
  onClickPin: () => {},
  isButton: false,
  displayTooltip: false,
};

VenuePin.propTypes = {
  something: PropTypes.string,
  onClick: PropTypes.func,
};

export default VenuePin;
