import React from 'react';
import PropTypes from 'prop-types';

// Components & Utils
import CloseModalButton from './CloseModalButton';

// UI
import { Box, Dialog } from '@mui/material';

/**
 * @function ModalComponent
 * @author RCH010
 * @version 1.0
 * @param {Boolean} isOpen
 * @param {Boolean} showCloseButton
 * @param {Function} onClose
 * @param {Object} PaperProps
 * @param {Object} additionalStyles
 * @returns {JSX}
 */
const ModalComponent = ({
  isOpen,
  onClose = () => {},
  PaperProps,
  additionalStyles,
  children,
  showCloseButton,
  ...rest
}) => {
  return (
    <Dialog
      PaperProps={PaperProps}
      onClose={onClose}
      open={isOpen}
      sx={{
        zIndex: 2100,
        ...additionalStyles,
      }}
      {...rest}>
      <Box sx={{ position: 'relative' }}>
        <CloseModalButton onClose={onClose} />
        {children}
      </Box>
    </Dialog>
  );
};

ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  PaperProps: PropTypes.object,
  additionalStyles: PropTypes.object,
  showCloseButton: PropTypes.bool,
};

export default ModalComponent;
