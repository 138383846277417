import React, { useContext, useEffect } from 'react';
// Components & Utils
import DownloadAppSection from '../components/shared/DownloadAppSection';
import ContactForm from '../components/shared/ContactForm';
import QuestionsTabsContainer from '../components/faq/QuestionsTabsContainer';
import ShadowBackgroundTextBanner from '../components/faq/ShadowBackgroundTextBanner';
// UI
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { UIContext } from '../providers/UIProvider';
import useDocumentTitle from '../hooks/useDocumentTitle';

/**
 * @function FAQ
 * @author RCH010
 * @version 1.0
 * @description FAQ view
 * This component doesn't uses ViewContainer because of the desired effect of the FAQBanner
 * View contains a tabs container with all the questions in accordions
 * And a DownloadAppSection and Contact form
 * @returns {JSX}
 */
const FAQ = () => {
  useDocumentTitle('Oasis App - Ayuda');
  const { setNavbarBackgroundColor } = useContext(UIContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setNavbarBackgroundColor(theme.palette.lightBackground);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        bgcolor: (currTheme) => currTheme.palette.lightBackground,
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}>
      <ShadowBackgroundTextBanner
        text={`Preguntas ${isMobile ? '\n' : ''}Frecuentes`}
      />
      <Box
        sx={{
          bgcolor: (currTheme) => currTheme.palette.mainBackground,
        }}>
        <Box
          sx={{
            maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
            mx: 'auto',
            px: 3,
          }}>
          <QuestionsTabsContainer />
        </Box>
        <Box sx={{ my: 5 }}>
          <DownloadAppSection />
        </Box>
        <ContactForm title={`¿Tienes alguna duda? \nContáctanos`} />
      </Box>
    </Box>
  );
};

export default FAQ;
