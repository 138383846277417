import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import StoreButton from './buttons/StoreButton';
// UI
import { Grid } from '@mui/material';
import AppleStoreImage from '../../assets/appsStores/appStore_es.png';
import GoogleStoreImage from '../../assets/appsStores/googlePlay_es.png';

const stores = [
  {
    id: 'appStore',
    image: AppleStoreImage,
    altText: 'Disponible en App Store',
  },
  {
    id: 'playStore',
    image: GoogleStoreImage,
    altText: 'Disponible en Google Play',
  },
  /*
  {
    id: 'appGallery',
    image: HuaweiStoreImage,
    altText: 'Disponible en AppGallery',
  },
   // TODO: que el id sea de Huawei
  */
];

/**
 * @function AppsStores
 * @author RCH010
 * @version 1.0
 * @description Returns a row Grid with social media buttons
 * @param {String} justifyContent for grid container
 * @param {String} spacing between elements
 * @param {String} fullWidth
 * @param {String} direction of buttons stack
 * @param {String} imageWidth
 * @returns {JSX}
 */
const AppsStores = ({
  justifyContent,
  spacing,
  fullWidth,
  direction,
  imageWidth,
}) => {
  return (
    <Grid
      container
      spacing={spacing}
      justifyContent={justifyContent}
      sx={{ minHeight: '4em', height: '100%', flexWrap: 'nowrap' }}
      direction={direction}>
      {stores.map((store) => (
        <Grid
          item
          key={store.id}
          xs={fullWidth ? 4 : 3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <StoreButton
            imageWidth={imageWidth}
            id={store.id}
            altText={store.altText}
            image={store.image}
          />
        </Grid>
      ))}
    </Grid>
  );
};

AppsStores.defaultProps = {
  justifyContent: 'flex-end',
  spacing: 1,
  fullWidth: false,
  direction: 'row',
  imageWidth: '12em',
};
AppsStores.propTypes = {
  justifyContent: PropTypes.string,
  spacing: PropTypes.number,
  imageWidth: PropTypes.string,
};

export default AppsStores;
