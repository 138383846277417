import React from 'react';
// Components & Utils
import AppsStores from './AppsStores';
import ImageLazyLoad from './ImageLazyLoad';
// UI
import { Box, Typography, useTheme, useMediaQuery, Grid } from '@mui/material';
import HighSplashScreen from '../../assets/frame/HighSplashScreen.webp';

/**
 * @function DownloadAppSection
 * @author RCH010
 * @version 1.0
 * @description download app section. With an image and the AppStores component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const DownloadAppSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        px: 3,
        mt: isMobile ? 10 : 25,
      }}>
      <Box textAlign='center' padding='20px 0'>
        <Typography
          variant='p'
          fontSize='50px'
          fontWeight='500'
          display='block'
          align='center'
          sx={{
            mt: 7,
          }}>
          {`¡Descarga la app!`}
        </Typography>
        <Typography
          variant='p'
          fontSize='40px'
          fontWeight='500'
          align='center'
          sx={{
            mb: 7,
          }}>
          {`y vive la experiencia de estar en un Oasis`}
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={5} md={3}>
          <ImageLazyLoad srcHighQuality={HighSplashScreen} altText='' />
        </Grid>
        <Grid item xs={6} md={4}>
          <AppsStores
            direction='column'
            justifyContent='center'
            spacing={isMobile ? 3 : 0}
            imageWidth={isMobile ? '13em' : '15em'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadAppSection;
