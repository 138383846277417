import React, { useContext, useEffect, useRef } from 'react';
// Components & Utils
import IconButtonOasis from './buttons/IconButtonOasis';
// UI
import { Grid } from '@mui/material';
import facebookIcon from '../../assets/providers/facebook-f.svg';
import appleIcon from '../../assets/providers/apple-a.svg';
import googleIcon from '../../assets/providers/google-g.svg';
import { loadScript } from '../../utils/util';
import { AuthContext } from '../../providers/AuthProvider';
import { logLogin } from '../../firebase';

const providers = [
  { id: 'facebook', iconPath: facebookIcon, loginHandler: () => {} },
  { id: 'apple', iconPath: appleIcon, loginHandler: () => {} },
  { id: 'google', iconPath: googleIcon, loginHandler: () => {} },
];

/**
 * @function ProvidersButtons
 * @author RCH010
 * @version 1.1
 * @description Grid of auth providers buttons
 * @returns {JSX}
 */
const ProvidersButtons = () => {
  const { login } = useContext(AuthContext);
  const auth2 = useRef();

  const onGoogleSignIn = async (googleUser) => {
    const authResponse = googleUser.getAuthResponse(true);
    await login({
      provider: 'google',
      email: googleUser.getBasicProfile().getId(),
      password: authResponse.access_token,
    });
    logLogin('google');
  };

  const onFBSignIn = async () => {
    await FB.login(
      (response) => {
        if (response.status === 'connected') {
          const { accessToken, userID } = response.authResponse;
          login({
            provider: 'facebook',
            email: userID,
            password: accessToken,
          });
          logLogin('facebook');
        }
      },
      { scope: 'public_profile, email' }
    );
  };

  const onAppleSignIn = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      // console.log(data);
      await login({
        provider: 'apple',
        email: { email: data.authorization.user.email },
        password: data.authorization.id_token,
      });
      logLogin('apple');
    } catch (error) {
      //handle error.
      // console.log('ERROR', error);
    }
  };

  const onProviderClick = (providerID) => {
    providers.filter((p) => p.id === providerID)[0].loginHandler();
  };

  useEffect(() => {
    // Google Sign In API loader
    loadScript('https://apis.google.com/js/api:client.js', () => {
      gapi.load('auth2', () => {
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        auth2.current = gapi.auth2.init({
          client_id:
            '594075459833-f4pna3eao0jit1054go5064749gonchr.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'
        });
        auth2?.current.attachClickHandler(
          'google-loginBtn',
          {},
          onGoogleSignIn,
          (error) => {
            /// TODO: Catch error and show message
            // console.log(JSON.stringify(error, undefined, 2));
          }
        );
        // providers[2].loginHandler = onGoogleSignIn;
      });
    });

    // FB Sign In API loader
    loadScript('https://connect.facebook.net/en_US/sdk.js', () => {
      window.fbAsyncInit = () => {
        FB.init({
          appId: '156540446052262',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v12.0',
        });
        providers[0].loginHandler = onFBSignIn;
      };
    });

    // Apple Sign In API loader
    loadScript(
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      () => {
        providers[1].loginHandler = onAppleSignIn;
        /// TODO: Send to .env
        window.AppleID.auth.init({
          clientId: 'mx.oasisapp.signin',
          scope: 'name email',
          redirectURI: 'https://oasisapp.mx',
          state: 'origin:web',
          usePopup: true, //or false defaults to false
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      spacing={0}
      justifyContent='center'
      alignItems='center'
      direction='row'
      sx={{ minHeight: '4.5em', maxWidth: '100%' }}>
      {providers.map((provider) => (
        <Grid
          key={provider.id}
          id={`${provider.id}-loginBtn`}
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mx: 2,
          }}>
          <IconButtonOasis
            id={`${provider.id}-signin`}
            shadowColor='rgba(255, 255, 255, 0.2)'
            ariaLabel={provider.id}
            onClick={() => onProviderClick(provider.id)}
            IconPath={provider.iconPath}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProvidersButtons;
