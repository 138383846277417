import React, { useState } from 'react';
import PropTypes from 'prop-types';
import slugify from 'react-slugify';
// Components & Utils
import ImageLazyLoad from '../shared/ImageLazyLoad';
import HoverCard from './HoverCard';
// UI
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import DefaultImage from '../../assets/events/fallBackEventImage.png';
import { Link } from 'react-router-dom';
import { parseDate } from '../../utils/util';
import { logAnalyticsEvent, logAnalyticsSelectContent } from '../../firebase';

const textContentStyles = {
  color: (t) => t.palette.grey[900],
  mt: 0,
  mb: '3px',
  fontSize: '0.85rem',
  whiteSpace: 'nowrap',
  fontWeight: 200,
};

/**
 * @function EventCard
 * @author RCH010
 * @version 1.0
 * @description Card used to display information of event, used on Carousel
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const EventCard = (eventData) => {
  const {
    name: eventTitle,
    pictureUrl: eventImageURL,
    venue,
    startsAt,
    overlayText,
    priceStartsAt,
    cardRootStyles,
  } = eventData;
  const [isCardHover, setIsCardHover] = useState(false);

  const urlEventTitle = slugify(eventTitle);
  const urlPathTitle =
    urlEventTitle === 'box' ? `oas-${urlEventTitle}` : urlEventTitle;
  // TODO: format price
  const onCardClick = () => {
    logAnalyticsSelectContent('event', eventData.id);
    logAnalyticsEvent({
      eventName: 'view_event',
      params: {
        event_id: eventData.id,
        event_name: eventTitle,
        event_venue: venue,
      },
    });
  };

  return (
    <Link
      to={`/events/${eventData.id}/${urlPathTitle}`}
      onClick={onCardClick}
      style={{ color: 'inherit', textDecoration: 'none' }}>
      <Card
        elevation={0}
        onMouseEnter={() => {
          setIsCardHover(true);
        }}
        onMouseLeave={() => {
          setIsCardHover(false);
        }}
        sx={{
          position: 'relative',
          display: 'flex',
          minWidth: { xs: '9.5em', sm: '10.8em', md: '12em', lg: '15em' },
          maxWidth: { xs: '9.5em', sm: '10.8em', md: '12em', lg: '15em' },
          borderRadius: '16px',
          bgcolor: 'transparent',
          mx: 'auto',
          ...cardRootStyles,
        }}>
        <CardActionArea sx={{ borderRadius: '16px' }}>
          <CardMedia sx={{ borderRadius: '16px' }}>
            <Box sx={{ position: 'relative' }}>
              <ImageLazyLoad
                objectFitImage='cover'
                srcHighQuality={eventImageURL || DefaultImage}
                altText={eventTitle}
                containerStyles={{
                  borderRadius: '16px 16px 0 0',
                  maxHeight: { xs: '10em', md: '14em' },
                  height: { xs: '10em', md: '14em' },
                  overflow: 'hidden',
                }}
              />
              {overlayText && (
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background:
                      ' linear-gradient(180deg, rgba(35, 26, 60, 0.7) -37.86%, rgba(90, 60, 180, 0.8) 56.82%, rgba(35, 26, 60, 0.7) 132.57%)',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'MuseoModerno',
                      fontWeight: 800,
                      fontSize: { ms: '1.1rem', md: '1.5rem !important' },
                    }}>
                    {overlayText}
                  </Typography>
                </Stack>
              )}
            </Box>
          </CardMedia>
          <CardContent
            sx={{
              p: {
                xs: 1,
                md: 2,
              },
              backgroundColor: (t) => `${t.palette.grey[50]}`,
              borderRadius: '0 0 16px 16px',
            }}>
            <Stack>
              <Typography
                noWrap
                gutterBottom
                sx={{
                  color: (t) => t.palette.grey[900],
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                }}
                variant='body1'>
                {eventTitle}
              </Typography>
              <Typography
                noWrap
                variant='body2'
                sx={{
                  ...textContentStyles,
                }}>
                {venue}
              </Typography>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                justifyContent='space-between'>
                <Typography
                  noWrap
                  variant='body2'
                  sx={{
                    ...textContentStyles,
                  }}>
                  {parseDate(startsAt, 'dayMonthYearTime')}
                </Typography>

                {priceStartsAt && (
                  <Typography
                    noWrap
                    variant='body2'
                    sx={{
                      ...textContentStyles,
                    }}>
                    {`Desde ${priceStartsAt}`}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
        <HoverCard eventTitle={eventTitle} display={isCardHover} />
      </Card>
    </Link>
  );
};

EventCard.defaultProps = {
  eventData: {},
  cardRootStyles: {},
};

EventCard.propTypes = {
  eventData: PropTypes.shape({
    name: PropTypes.string,
    pictureUrl: PropTypes.string,
    venue: PropTypes.string,
    startsAt: PropTypes.string,
    overlayText: PropTypes.string,
    priceStartsAt: PropTypes.string,
  }),
};

export default EventCard;
