import React from 'react';
import { Link } from 'react-router-dom';
// UI
import { Box, useMediaQuery, useTheme, Button } from '@mui/material';
import FanPersonImage from '../../assets/others/FanPerson.webp';
import LinesSVG from '../../assets/others/lines.svg';
// Components & Utils
import { menuOptions } from '../../utils/menuOptions';

/**
 * @function FAQSection
 * @author RCH010
 * @version 1.0
 * @description FAQ CTA in a div, with picture
 * @returns {JSX}
 */
const FAQSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        bgcolor: (currTheme) => currTheme.palette.oasisPink.main,
        position: 'relative',
        zIndex: 1,
      }}>
      <img
        src={LinesSVG}
        alt=''
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          maxWidth: '100%',
        }}
      />
      <Box
        sx={{
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
          mx: 'auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          '& > img': {
            zIndex: 1,
            maxWidth: '100%',
            width: isMobile ? '16em' : '25em',
            mt: '-5em',
          },
        }}>
        <Box>
          <Button
            as={Link}
            to={menuOptions.FAQ.path}
            variant='contained'
            aria-label='FAQ'
            color='secondary'
            size='large'
            sx={{
              display: 'block',
              textDecoration: 'none',
              zIndex: 2,
              positon: 'relative',
              mb: isMobile ? '2em' : '0',
              fontSize: '1.3rem',
              borderRadius: 8,
              p: '16px 44px',
              transition: 'box-shadow 200ms, background-color 200ms',
              boxShadow: `0.1em 0.2em .2em ${theme.palette.shadowGreen}`,
              '&:hover': {
                boxShadow: `0.2em 0.35em .9em ${theme.palette.shadowGreen}`,
              },
            }}>
            Ayuda / Preguntas frecuentes
          </Button>
        </Box>
        <img src={FanPersonImage} alt='' />
      </Box>
    </Box>
  );
};

export default FAQSection;
