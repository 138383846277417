import forbesImage from '../assets/nosotros/forbes-banner.png'
const width = '100%'

export const nosotrosData = [
  {
    image: forbesImage,
    alt: "Forbes Image",
    link: "https://www.forbes.com.mx/ad-oasis-app-la-empresa-lider-en-el-mercado-en-renta-de-palcos/",
    widthImg: width
  },
  {
    image: forbesImage,
    alt: "Forbes Image",
    link: "https://www.forbes.com.mx/ad-oasis-app-la-empresa-lider-en-el-mercado-en-renta-de-palcos/",
    widthImg: width
  },
  {
    image: forbesImage,
    alt: "Forbes Image",
    link: "https://www.forbes.com.mx/ad-oasis-app-la-empresa-lider-en-el-mercado-en-renta-de-palcos/",
    widthImg: width
  },
  {
    image: forbesImage,
    alt: "Forbes Image",
    link: "https://www.forbes.com.mx/ad-oasis-app-la-empresa-lider-en-el-mercado-en-renta-de-palcos/",
    widthImg: width
  },
  {
    image: forbesImage,
    alt: "Forbes Image",
    link: "https://www.forbes.com.mx/ad-oasis-app-la-empresa-lider-en-el-mercado-en-renta-de-palcos/",
    widthImg: width
  },
  {
    image: forbesImage,
    alt: "Forbes Image",
    link: "https://www.forbes.com.mx/ad-oasis-app-la-empresa-lider-en-el-mercado-en-renta-de-palcos/",
    widthImg: width
  }
]