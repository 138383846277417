import React, { useContext, useEffect } from 'react';

// Components
import { UIContext } from '../../providers/UIProvider';
import { AuthView } from '../../components/auth/AuthView';
import AuthAlternativeButtons from '../../components/auth/AuthAlternativeButtons';
import AuthType from './AuthType';
import { ProviderLoginType } from '../../utils/enums';

/**
 * @function SignUp
 * @returns {JSX}
 */
export const SignUp = () => {
  const { setNavbarBackgroundColor } = useContext(UIContext);

  useEffect(() => {
    setNavbarBackgroundColor('#5A3CB4');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthView>
      <AuthType type={ProviderLoginType.SIGNUP} />
      <AuthAlternativeButtons type={ProviderLoginType.SIGNUP} />
    </AuthView>
  );
};
