import { createTheme, responsiveFontSizes } from '@mui/material';
import '@fontsource/museomoderno/300.css';
import '@fontsource/museomoderno/400.css';
import '@fontsource/museomoderno/500.css';
import '@fontsource/museomoderno/600.css';
import '@fontsource/museomoderno/700.css';
import '@fontsource/museomoderno/800.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { grey } from '@mui/material/colors';

export const oasisTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#753bbd',
        light: '#a769ef',
        dark: '#41088b',
        contrastText: '#fff',
      },
      secondary: {
        main: '#26d07c',
        light: '#6affab',
        dark: '#009f4e',
        contrastText: '#000',
      },
      oasisPink: {
        main: '#bf76ff',
        dark: '#b580d1',
      },
      neutral: {
        main: '#B1B1B1',
        light: '#D1D1D1',
        dark: '#A1A1A1',
        contrastText: '#000',
      },
      shadowGreen: 'rgba(38, 208, 124, 0.4)',
      shadowBlack: 'rgba(12, 8, 24, 0.3);',
      shadowWhite: '3px 4px 15px 1px rgba(255, 255, 255, 0.23);',
      secondaryGray: '#bbbcbc',
      mainBackground: '#2D1E5A',
      lightBackground: '#5A3CB4',
      lighterPrimary: '#7B53DF',
      lightLinearGradient:
        'linear-gradient(288.7deg, #5A3CB4 0.31%, #A264D9 161.46%, #5432A8 161.46%)',
      mediumBackground: '#1B0578',
      darkerBackground: '#090030',
      footerBackground: '#2e293a',
      darklines: '#2E293A',
      background: {
        default: '#2D1E5A',
      },
      text: {
        primary: '#fff',
      },
    },
    typography: {
      fontFamily: 'Roboto',
      h1: {
        fontFamily: 'MuseoModerno',
        color: '#fff',
        fontSize: '3.5rem',
        fontWeight: '700',
      },
      h2: {
        fontFamily: 'MuseoModerno',
        color: '#fff',
        fontSize: '3rem',
        fontWeight: '500',
      },
      h3: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '2.5rem',
        fontWeight: '500',
      },
      h4: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '2.2rem',
        fontWeight: '500',
      },
      h5: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '2rem',
        fontWeight: '500',
      },
      h6: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '1.7rem',
        fontWeight: '500',
      },
      subtitle1: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '1.6rem',
        fontWeight: '500',
      },
      subtitle2: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: '500',
      },
      body1: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '1.2rem',
      },
      body2: {
        fontFamily: 'Roboto',
        color: '#fff',
        fontSize: '1rem',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#2D1E5A',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#2e293a',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '16px',
            // textTransform: 'none',
            fontSize: '1.1rem',
            '&.Mui-disabled': {
              backgroundColor: 'rgba(255, 255, 255, 0.12);',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          //boxes & textfields
          root: {
            borderRadius: '8px',
            '&:hover:not(.Mui-focused)': {
              // On hover
              '& .MuiOutlinedInput-notchedOutline': {},
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: grey[500],
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            margin: '4px 4px',
            '& fieldset': { borderRadius: '8px' }, //textfield radius
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '8px', //textfield background radius
            backgroundColor: '#2D1E5A',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          //boxes & textfields
          root: {
            letterSpacing: '0.5px',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '4px 4px',
            '& fieldset': { borderRadius: '8px' }, //selectorGroup radius
          },
        },
      },
    },
    spacing: 8,
  })
);
