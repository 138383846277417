import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Components & Utils
import Checkout from '../Checkout';
import FormContainer from '../FormContainer';
import { CheckoutContext } from '../../../providers/CheckoutProvider';
import {
  useGetBoxQuote,
  useGetPaymentIntent,
  useGetPaymentMethods,
} from '../../../hooks/requests';

// UI
import { Skeleton, Stack } from '@mui/material';
import { logCheckoutStarts } from '../../../firebase';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

const appearance = {
  theme: 'night',
  labels: 'floating',
  variables: {
    borderRadius: '16px',
    colorPrimary: '#26d07c',
    colorBackground: '#2D1E5A',
    colorText: '#ffffff',
  },
};

/**
 * @function PaymentMethodForm
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const PaymentMethodForm = ({ triggerPayment, setTriggerPayment }) => {
  const { boxData, eventData, bookingOptions } = useContext(CheckoutContext);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [isPaymentMethodLoaded, setIsPaymentMethodLoaded] = useState(false);
  const [quote, setQuote] = useState(null);
  const { type } = useParams();
  const [paymentIntent, setPaymentIntent] = useState({
    customer: null,
    ephemeralKey: null,
    paymentIntent: null,
  });

  useGetPaymentMethods({
    cacheTime: 5000,
    onError: () => {},
    onSuccess: (p) => {
      setPaymentMethods(p);
      setIsPaymentMethodLoaded(true);
    },
    enabled: !paymentMethods,
  });

  useGetPaymentIntent(
    {
      eventId: eventData.id,
      boxId: boxData.id,
      totalSeats: bookingOptions.numberOfSeats || boxData.availableSeats,
      bookingType: type === 'event' ? 'onetime' : 'season',
      address: bookingOptions.selectedAddress,
    },
    {
      cacheTime: 5000,
      onError: () => console.log('ERROR CARGANDO PAYMENT INTENT'),
      onSuccess: (res) => {
        setPaymentIntent({ ...res });
      },
      enabled: !paymentIntent.paymentIntent,
    }
  );

  useGetBoxQuote(
    {
      boxId: boxData.id,
      eventId: eventData.id,
      totalSeats: bookingOptions.numberOfSeats || boxData.availableSeats,
      type: type === 'event' ? 'onetime' : 'season',
    },
    {
      enabled: !quote,
      cacheTime: 5000,
      onError: () => {},
      onSuccess: (fetchedQuote) => {
        setQuote(fetchedQuote);
      },
    }
  );

  useEffect(() => {
    if (quote) {
      logCheckoutStarts(quote.total, boxData.id, eventData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  const options = {
    clientSecret: paymentIntent.paymentIntent,
    appearance: appearance,
  };

  return (
    <FormContainer sx={{ height: '100%' }}>
      {options.clientSecret && isPaymentMethodLoaded ? (
        <Elements options={options} stripe={stripePromise}>
          <Checkout
            paymentIntent={paymentIntent}
            paymentMethods={paymentMethods}
            triggerPayment={triggerPayment}
            setTriggerPayment={setTriggerPayment}
          />
        </Elements>
      ) : (
        <Stack spacing={4} sx={{ width: '100%', mt: 3 }}>
          <Skeleton variant='rectangular' width='100%' height='15em' />
          <Skeleton variant='rectangular' width='100%' height='4em' />
        </Stack>
      )}
    </FormContainer>
  );
};

export default PaymentMethodForm;
