// Bookings
import { httpRequest } from '../utils/util';

export const getBookings = async () => {
  const res = await httpRequest('app/bookings', {});
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getBooking = async ({ bookingId }) => {
  const res = await httpRequest(`app/bookings/${bookingId}`, {});
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};
