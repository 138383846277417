import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
// UI
import { Button, CircularProgress, Stack } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

/**
 * @function TwoButtons
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @returns {JSX}
 */
const TwoButtons = ({
  primary,
  secondary,
  containerStyles,
  primaryWithEndIcon,
}) => {
  return (
    <Stack
      spacing={4}
      direction='row'
      alignItems='flex-end'
      sx={{
        maxWidth: '100%',
        ...containerStyles,
      }}>
      {secondary && (
        <Button
          disabled={secondary.isDisabled}
          onClick={secondary.onClick}
          fullWidth={secondary.isFullWidth}
          size='large'
          color='neutral'
          variant='contained'
          aria-label={secondary.label}
          sx={{
            px: '2.5em',
            boxShadow: 'none',
          }}>
          {secondary.label}
        </Button>
      )}
      {primary && (
        <Button
          disabled={primary.isDisabled || primary.isLoading}
          type={primary.type}
          onClick={primary.onClick}
          size='large'
          color='secondary'
          aria-label={primary.label}
          fullWidth={primary.isFullWidth}
          variant='contained'
          endIcon={primaryWithEndIcon ? <ArrowForwardIosRoundedIcon /> : <></>}
          sx={{
            px: '2.5em',
            boxShadow: '2px 3px 20px rgba(41, 237, 135, 0.3)',
          }}>
          <Stack direction='row' alignItems='center'>
            {primary.label}
            {primary.isLoading && (
              <>
                <CircularProgress
                  color='neutral'
                  size={20}
                  thickness={5}
                  sx={{ ml: '4px' }}
                />
              </>
            )}
          </Stack>
        </Button>
      )}
    </Stack>
  );
};

TwoButtons.defaultProps = {
  primary: null,
  secondary: null,
  containerStyles: {},
};

TwoButtons.propTypes = {
  something: PropTypes.string,
};

export default TwoButtons;
