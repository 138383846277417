import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components & Utils
import { CheckoutContext } from '../../providers/CheckoutProvider';
import { parseDate } from '../../utils/util';
import QuoteSummary from '../shared/QuoteSummary';
import ImageLazyLoad from '../shared/ImageLazyLoad';
import IconText from '../shared/IconText';

// UI
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ProgressButtons from './ProgressButtons';

const Text = ({ children, sx = {}, ...props }) => (
  <Typography
    sx={{ color: 'secondary.contrastText !important', ...sx }}
    {...props}>
    {children}
  </Typography>
);

// Icon-List with venue, date and number of places
const ItemsList = ({ venue, date, places }) => {
  const items = [
    { id: 'venue', label: venue, Icon: <LocationOnIcon color='secondary' /> },
    { id: 'date', label: date, Icon: <CalendarTodayIcon color='secondary' /> },
    { id: 'nSeats', label: places, Icon: <EventSeatIcon color='secondary' /> },
  ];

  return items.map((item) => (
    <IconText
      key={item.id}
      text={item.label}
      Icon={item.Icon}
      typographyProps={{
        variant: 'body2',
        sx: { color: 'secondary.contrastText !important' },
      }}
    />
  ));
};

/**
 * @function Summary
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const Summary = ({ isDrawer, onNext, onBack, buttonProps, primaryLabel }) => {
  const { eventID, boxID, type: bookingType } = useParams();
  const {
    eventData = {},
    bookingOptions = {},
    boxData = {},
  } = useContext(CheckoutContext);

  if (!eventData || !bookingOptions || !boxData) {
    return <Skeleton />;
  }

  const {
    pictureUrl: eventImage,
    name: eventName,
    startsAt: eventDate,
    venue,
  } = eventData;

  const { season } = boxData;

  const dateToDisplay =
    bookingType === 'event'
      ? parseDate(eventDate, 'dayLongMonthYearTime')
      : parseDate(season?.startsAt, 'dayLongMonthYear');

  return (
    <Stack
      sx={{
        ...(!isDrawer && {
          top: '104px',
          position: 'sticky',
          width: '20em',
          borderRadius: 8,
          display: 'flex',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          boxShadow: '3px 4px 24px rgba(255, 255, 255, 0.12)',
          backdropFilter: 'blur(25px)',
          overflow: 'hidden',
          height: 'fit-content',
          minHeight: `calc(100vh - 184px)`,
        }),
      }}>
      <Stack
        sx={{ pb: 3, height: isDrawer ? 'fit-content' : '100%', flexGrow: 1 }}>
        {/* --- EVENT IMAGE --- */}
        <ImageLazyLoad
          srcHighQuality={eventImage}
          imageStyles={{ height: '170px' }}
          containerStyles={{ height: 'unset' }}
          objectFitImage='cover'
        />
        <Stack justifyContent='space-between' sx={{ flex: 1, px: 2 }}>
          {/* --- EVENT VENUE, DATE, NUM SEATS --- */}
          <Stack spacing={0.5}>
            <Text sx={{ py: 1, lineHeight: 1.2 }} variant='subtitle1'>
              {eventName || ''}
            </Text>
            <ItemsList
              venue={`${venue?.venue || ''}`}
              date={
                bookingType === 'event'
                  ? `${dateToDisplay}`
                  : `Inicio de temporada: ${dateToDisplay}`
              }
              places={`${
                bookingOptions.numberOfSeats || boxData.availableSeats
              } lugares`}
            />
            {/* --- PAYMENT SUMMARY --- */}
            <Box>
              <Text variant='body1' sx={{ fontWeight: 'bold', my: 1 }}>
                Resumen de pago
              </Text>
              <QuoteSummary
                styleVariant={'white'}
                boxID={boxID}
                eventID={eventID}
                totalSeats={
                  bookingOptions.numberOfSeats || boxData.availableSeats
                }
                type={bookingType === 'event' ? 'onetime' : 'season'}
              />
            </Box>
          </Stack>
          {!isDrawer && (
            <Stack alignItems='flex-end'>
              <ProgressButtons
                onNext={onNext}
                onBack={onBack}
                buttonProps={buttonProps}
                primaryLabel={primaryLabel}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

Summary.defaultProps = {
  something: '',
};

Summary.propTypes = {
  something: PropTypes.string,
};

export default Summary;
