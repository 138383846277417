import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import { parseDate } from '../../utils/util';
// UI
import { Box, Typography } from '@mui/material';

/**
 * @function Header
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const Header = ({ eventData, boxEventData, bookingType }) => {
  const dateToDisplay =
    bookingType === 'event'
      ? parseDate(eventData?.startsAt, 'dayLongMonthYearTime')
      : parseDate(boxEventData?.season?.startsAt, 'dayLongMonthYear');
  return (
    <Box sx={{ position: 'relative', xIndex: 2 }}>
      <Typography gutterBottom variant='h2'>
        {boxEventData?.event?.name}
      </Typography>
      <Typography variant='body1'>
        {`${boxEventData?.venue?.name}`}
        {', '}
        {bookingType === 'event'
          ? `${dateToDisplay}`
          : `Inicio de temporada: ${dateToDisplay}`}
      </Typography>
    </Box>
  );
};

Header.propTypes = {
  bookingType: PropTypes.string,
  eventData: PropTypes.object,
  boxEventData: PropTypes.object,
};

export default Header;
