import React, { createContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createCartCopy } from '../components/checkout/checkoutHelper';

const defaultValues = {
  bookingOptions: {
    numberOfSeats: null,
    selectedAddress: null,
    paymentMethod: null,
    extras: null,
  },
  eventData: null,
  boxData: null,
  currentStep: null,
  steps: [],
};

export const CheckoutContext = createContext(defaultValues);

export const CheckoutProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(null);
  const [steps, setSteps] = useState([]);

  const [bookingOptions, setBookingOptions] = useState(
    defaultValues.bookingOptions
  );
  const [eventData, setEventData] = useState(null);
  const [boxData, setBoxData] = useState(null);

  const [cart, setCart] = useState({});
  const [cartCopy, setCartCopy] = useState(null);

  const [checkoutButtons, setCheckoutButtons] = useState({ isLoading: false });

  const { eventID, boxID, eventTitle } = useParams();
  const navigate = useNavigate();

  const updateBookingOptions = (data) => {
    setBookingOptions((currentOptions) => ({
      ...currentOptions,
      ...data,
    }));
  };

  const updateStep = ({ increment = true, newStep = null } = {}) => {
    setCurrentStep((step) => {
      // If an ID is given update directly to it.
      if (newStep) return newStep;

      let index = steps.findIndex((key) => key === step);
      index += increment ? 1 : -1;

      // If the new step is beyond the existing ones. Return the current
      if (index >= steps.length) return step;

      // If the new step is smaller than the existing ones. Navigate to box view
      if (index < 0) {
        if (eventTitle) {
          navigate(`/events/${eventID}/${eventTitle}/box/${boxID}`);
        } else {
          navigate(`/events/${eventID}/box/${boxID}`);
        }
        return step;
      }

      // Return to updated step
      return steps[index];
    });
  };

  const updateCart = (id, { quantity = 0, unitPrice, comment = null }) => {
    setCart((currentCart) => {
      let newQuantity = currentCart[id]?.quantity || 0;
      newQuantity += quantity;

      const newComment = comment ? comment : currentCart[id]?.comment || null;

      const newUnitPrice = unitPrice
        ? unitPrice
        : currentCart[id]?.unitPrice || null;

      return {
        ...currentCart,
        [id]: {
          quantity: newQuantity,
          comment: newComment,
          unitPrice: newUnitPrice,
        },
      };
    });
  };

  const updateItemsOnCartDefault = (items = []) => {
    const serverCart = {};
    items.forEach((item) => {
      serverCart[item.id] = {
        quantity: item.quantity,
        comment: item.comment,
        unitPrice: item.price,
      };
    });
    setCartCopy(createCartCopy(serverCart));
    setCart((currentCart) => ({
      ...currentCart,
      ...serverCart,
    }));
  };

  return (
    <CheckoutContext.Provider
      value={{
        bookingOptions,
        updateBookingOptions,
        setBookingOptions,

        eventData,
        setEventData,
        boxData,
        setBoxData,

        currentStep,
        updateStep,
        steps,
        setSteps,

        cart,
        updateCart,
        updateItemsOnCartDefault,

        cartCopy,
        setCartCopy,

        checkoutButtons,
        setCheckoutButtons,
      }}>
      {children}
    </CheckoutContext.Provider>
  );
};

CheckoutProvider.propTypes = {
  children: PropTypes.node,
};
