import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';

// Components & Utils
import { UIContext } from '../../providers/UIProvider';
import { resendVerification, verifyAccount } from '../../api/user';

// Assets
import FansImage from '../../assets/others/fans.svg';
import TextInput from '../../components/inputs/TextInput';
import { useForm } from 'react-hook-form';

/**
 * @function ConfirmAccount
 * @author JPVARGAS59
 * @version 2.0
 * @description When user is validating it's account it's send to this view
 * @returns {JSX}
 */
const ConfirmAccount = () => {
  const { setNavbarBackgroundColor } = useContext(UIContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(undefined);
  const [resendLink, setResendLink] = useState({
    isLoading: false,
    isSent: false,
    isError: false,
  });
  // const [resendLink, setResendLink] = useState(false);
  // const [loadingResend, setLoadingResend] = useState(false);

  const verifyAccountMutation = useMutation(verifyAccount);
  const resendVerificationMutation = useMutation(resendVerification);

  const token = new URLSearchParams(window.location.search).get('token');

  const {
    register,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm({ mode: 'onChange' });

  // Validates the given token, updating the success state
  const verifyAccountToken = async () => {
    try {
      await verifyAccountMutation.mutateAsync({ token });
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const resendVerificationLink = async ({ email }) => {
    try {
      setResendLink((v) => ({ ...v, isLoading: true }));
      await resendVerificationMutation.mutateAsync({ email });
      setResendLink((v) => ({
        ...v,
        isLoading: false,
        isSent: true,
        isError: false,
      }));
    } catch (error) {
      setResendLink((v) => ({ ...v, isLoading: false, isError: true }));
    }
  };

  // On load, set navbar color to purple and verify account token
  useEffect(() => {
    setNavbarBackgroundColor('#5A3CB4');
    verifyAccountToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        background: (t) => t.palette.lightLinearGradient,
        minHeight: '100vh',
        width: '100%',
        py: 20,
        display: 'flex',
      }}>
      <Stack
        spacing={3}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          maxWidth: 'md',
          mx: 'auto',
          px: 3,
          height: '100%',
          alignSelf: 'center',
        }}>
        {isLoading ? (
          <>
            <Typography variant='h1'>Verificando cuenta</Typography>
            <CircularProgress color='neutral' size={100} />
          </>
        ) : (
          <>
            {success && (
              <Box sx={{ '& img': { maxWidth: '100%' } }}>
                <img src={FansImage} alt='' />
              </Box>
            )}
            <Typography align='left' variant='h1' sx={{ width: '100%' }}>
              {success ? 'Felicidades' : 'Oh no!'}
            </Typography>
            <Typography align='left' variant='body1' sx={{ width: '100%' }}>
              {success
                ? 'Cuenta verificada con éxito'
                : 'Este enlace no es válido o ha expirado. Por favor intenta de nuevo.'}
            </Typography>
            {!success && (
              <Box
                component='form'
                onSubmit={handleSubmit(resendVerificationLink)}>
                <TextInput
                  disabled={resendLink.isSent}
                  inputVariant='email'
                  register={register}
                  registerName='email'
                  required={true}
                  additionalStyles={{ width: '100%' }}
                  error={Boolean(errors.email && touchedFields.email)}
                />
                <Button
                  disabled={resendLink.isLoading || resendLink.isSent}
                  type='submit'
                  color='secondary'
                  aria-label='Reenviar'
                  variant='contained'
                  fullWidth
                  sx={{ mt: 3 }}>
                  Reenviar link
                </Button>
                {(resendLink.isSent || resendLink.isError) && (
                  <Typography variant='caption'>
                    {resendLink.isSent &&
                      'Se ha enviado el correo para validar su cuenta. Por favor revise su bandeja.'}
                    {resendLink.isError &&
                      'Ha habido un problema al mandar el correo, por favor verifique el correo'}
                  </Typography>
                )}
              </Box>
            )}
            <Button
              fullWidth
              onClick={() => {
                navigate('/login');
              }}
              aria-label='Iniciar Sesion'
              color='secondary'
              variant={success ? 'contained' : 'outlined'}>
              Ir a iniciar sesión
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ConfirmAccount;
