import React from 'react';
import PropTypes from 'prop-types';
// UI
import { ReactComponent as LeftCircle } from '../../assets/others/leftCircle.svg';
import { ReactComponent as RightCircle } from '../../assets/others/rightCircle.svg';

/**
 * @function HalfCircle
 * @author RCH010
 * @version 1.0
 * @description half a circle svg used for background purposes.
 * @param {Boolean} isLeftCircle
 * @param {Number} opacity
 * @param {String | Number} top
 * @param {String | Number} left
 * @param {String | Number} right
 * @param {String | Number} bottom
 * @returns {JSX}
 */
const HalfCircle = ({
  isLeftCircle,
  top,
  left,
  right,
  bottom,
  opacity,
  maxWidth,
  fill,
  ...rest
}) => {
  if (isLeftCircle) {
    return (
      <LeftCircle
        fill={fill}
        style={{
          overflow: 'hidden',
          opacity: opacity,
          zIndex: '0',
          position: 'absolute',
          maxWidth: maxWidth,
          objectFit: 'contain',
          top,
          left,
          right,
          bottom,
          ...rest,
        }}
      />
    );
  }

  return (
    <RightCircle
      fill={fill}
      style={{
        overflow: 'hidden',
        zIndex: '0',
        position: 'absolute',
        objectFit: 'contain',
        opacity,
        maxWidth,
        top,
        left,
        right,
        bottom,
        ...rest,
      }}
    />
  );
};

HalfCircle.defaultProps = {
  isLeftCircle: false,
  opacity: '0.4',
  top: 'unset',
  left: 'unset',
  right: 'unset',
  bottom: 'unset',
  maxWidth: '90%',
  fill: '#39276f',
};

HalfCircle.propTypes = {
  isLeftCircle: PropTypes.bool,
  opacity: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default HalfCircle;
