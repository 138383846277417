import shield from '../assets/securityFeatures/mecanisms/shield.png';
import calendar from '../assets/securityFeatures/mecanisms/calendar.png';
import moneyback from '../assets/securityFeatures/mecanisms/moneyback.png';
import verified from '../assets/securityFeatures/mecanisms/verified.png';

import clubAtlante from '../assets/securityFeatures/partnerships/clubAtlante.png';
// import clubQueretaro from '../assets/securityFeatures/partnerships/clubQueretaro.png';
import clubSantos from '../assets/securityFeatures/partnerships/clubSantos.png';
import fronticket from '../assets/securityFeatures/partnerships/fronticket.png';
import fronton from '../assets/securityFeatures/partnerships/fronton.png';
import mazatlan from '../assets/securityFeatures/partnerships/mazatlan.png';
import seatsAvenue from '../assets/securityFeatures/partnerships/seatsAvenue2.webp';
import estadioCiudad from '../assets/securityFeatures/partnerships/estadio-ciudad-deportes.png';
import plazaMexico from '../assets/securityFeatures/partnerships/plaza-mexico.png';

export const mechanismsData = [
  {
    id: '3dSecure',
    image: shield,
    title: '3D Secure en tus pagos',
    supportingText:
      'A través de Oasis App, todas tus compras están seguras. Nuestra plataforma utiliza tecnologías de vanguardia que los bancos más seguros del mundo utilizan. Mediante 3D Secure, solo tú puedes hacer compras con tu tarjeta, nuestra plataforma nunca guarda tu información de pago.',
  },
  {
    id: 'verifiedBoxes',
    image: verified,
    title: 'Palcos y accesos verificados',
    supportingText:
      'Todos los activos listados en la plataforma son verificados por nuestro equipo técnico / legal para comprobar su veracidad y legitimidad en todos los aspectos. Todos y cada uno de los palcos y accesos son 100% reales, al igual que sus rentas. El evento que reserves por Oasis será un evento al que asistirás sin inconvenientes de fraudes, de lo contrario, buscaremos la mejor opción para ti o te regresamos íntegramente tu dinero.',
  },
  {
    id: 'customersupport',
    image: calendar,
    title: 'Concierge Oasis: Asistencia personalizada pre-durante-post compra',
    supportingText:
      'Desde el momento en el que existe interés de reserva y hasta la salida del estadio, se te asignará un Concierge Oasis que estará encargado de cumplir con los estándares de calidad más altos para ti, tu familia, amigos, empleados, etc. Nuestro Concierge Oasis estará 24/7 atendiendo todas las dudas, inconvenientes y extras que puedan derivar de cada renta efectuada por la plataforma.',
  },
  {
    id: 'cancellation',
    image: moneyback,
    title: 'Referencias e historial transaccional',
    supportingText:
      'Desde el 2021, Oasis App ha concretado más de 1,500 rentas de palcos y activos de entretenimiento de manera satisfactoria con más de 50,000 usuarios verificados. De igual manera, aparte de nuestros rigurosos procesos de afiliación de activos, contamos con alianzas comerciales con equipos de fútbol de la Liga MX, equipos de béisbol de la Liga Mexicana de Beisbol y de la Liga Mexicana del Pacífico, los mejores promotores de eventos del país, los mejores y más concurridos estadios del país, etc.',
  },
];

export const partnersData = [
  {
    id: 'clubSantos',
    image: clubSantos,
  },
  {
    id: 'clubAtlante',
    image: clubAtlante,
  },
  {
    id: 'fronton',
    image: fronton,
  },
  {
    id: 'fronticket',
    image: fronticket,
  },
  /*{
    id: 'algodoneros',
    image: algodoneros,
  },*/
  {
    id: 'mazatlan',
    image: mazatlan,
  },
  /*{
    id: 'canal6',
    image: canal6,
  },
  {
    id: 'funticket',
    image: funticket,
  },
  {
    id: 'musicvibe',
    image: musicvibe,
  },
  {
    id: 'ch',
    image: ch,
  },
  {
    id: 'auditorioPachuca',
    image: auditorioPachuca,
  },
  {
    id: 'auditorioPuebla',
    image: auditorioPuebla,
  },
  {
    id: 'islaMerida',
    image: islaMerida,
  },*/
  {
    id: 'seatsAvenue',
    image: seatsAvenue,
  },
  {
    id: 'estadioCiudad',
    image: estadioCiudad,
  },
  {
    id: 'plazaMexico',
    image: plazaMexico,
  },
];
