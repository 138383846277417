import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
// UI
import { Grid, Stack, Typography } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { cardsSVG } from '../../utils/cardsSVG';

/**
 * @function CardElement
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const CardElement = ({
  paymentMethod,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  const cardBrand = paymentMethod.card.brand;
  const cardImage = cardsSVG[cardBrand];

  return (
    <Grid item key={paymentMethod.id} xs={12} sm='auto'>
      <Stack
        onClick={() => setSelectedPaymentMethod(paymentMethod)}
        spacing={2}
        direction='row'
        alignItems='center'
        justifyContent={{ xs: 'space-evenly', sm: 'flex-start' }}
        sx={{
          cursor: 'pointer',
          p: 1,
          pl: 2,
          borderRadius: '16px',
          backgroundColor:
            selectedPaymentMethod.id === paymentMethod.id
              ? (t) => t.palette.lighterPrimary
              : (t) => t.palette.mainBackground,
        }}>
        {cardImage ? (
          <img
            src={cardImage}
            alt=''
            style={{ width: '60px', height: '28px', objectFit: 'contain' }}
          />
        ) : (
          <Typography
            variant='body2'
            noWrap
            sx={{
              width: { xs: 'auto', sm: '60px' },
              maxWidth: '60px',
              whiteSpace: 'nowrap !important',
            }}>
            {paymentMethod.card.brand}
          </Typography>
        )}
        <Stack>
          <Typography variant='body2'>
            {`···· ···· ···· ${paymentMethod.card.last4}`}
          </Typography>
          <Typography variant='caption' sx={{ fontSize: '0.7rem' }}>
            {`Exp ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
          </Typography>
        </Stack>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ width: '1.5em' }}>
          {selectedPaymentMethod.id === paymentMethod.id && (
            <CheckRoundedIcon sx={{ color: 'secondary.main' }} />
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};

CardElement.defaultProps = {
  something: '',
};

CardElement.propTypes = {
  something: PropTypes.string,
};

export default CardElement;
