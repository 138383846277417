import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
// Components & Utils
import ViewContainer from '../../components/shared/ViewContainer';
import EventBanner from '../../components/events/EventBanner';
import IconText from '../../components/shared/IconText';
import Carousel from '../../components/carousel/Carousel';
import VenueItem from '../../components/carousel/VenueItem';
import VenueMap from '../../components/events/VenueMap';
import CardsRowSkeleton from '../../components/shared/skeletons/CardsRowSkeleton';
import { useBoxEvent, useEvent } from '../../hooks/requests';
import {
  moneyFormat,
  openExternalLink,
  parseDate,
  scrollToTop,
} from '../../utils/util';
import { getResponsiveObjectForFormula1 } from '../../utils/carouselResponsive';
// UI
import {
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from '@mui/icons-material/People';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HalfCircle from '../../components/shared/HalfCircle';
import SeatsImage from '../../assets/others/seats.svg';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import EmptyState from '../../components/shared/EmptyState';
import { logAnalyticsEvent, logAnalyticsSelectItem } from '../../firebase';
import slugify from 'react-slugify';

const legalDocuments = [
  { id: 'termsAndConditions', text: 'Términos y condiciones' },
  { id: 'privacyNotice', text: 'Aviso de privacidad' },
  { id: 'cancelationPolicies', text: 'Políticas de cancelación' },
];

const getBannerIconTextItems = (boxEventData, eventData) => {
  const bannerIconTextItems = [
    {
      id: 1,
      text: eventData?.venue?.venue,
      Icon: <LocationOnIcon color='secondary' />,
    },
    {
      id: 2,
      text: `${parseDate(eventData?.startsAt, 'dayMonthYearTime')}`,
      Icon: <CalendarTodayIcon color='secondary' />,
    },
    {
      id: 3,
      text: `${boxEventData?.availableSeats} lugares`,
      Icon: <EventSeatIcon color='secondary' />,
    },
  ];
  if (boxEventData?.isShared) {
    bannerIconTextItems.push({
      id: 4,
      text: 'Palco compartido',
      Icon: <PeopleIcon color='secondary' />,
    });
  }

  bannerIconTextItems.push({
    id: 5,
    text: `${moneyFormat(boxEventData?.price, {
      moneySign: false,
    })} por evento`,
    Icon: <AttachMoneyRoundedIcon color='secondary' />,
  });
  if (boxEventData?.pricePerTicket && boxEventData.pricePerTicket > 0) {
    bannerIconTextItems.push({
      id: 6,
      text: `${moneyFormat(boxEventData.pricePerTicket, {
        moneySign: false,
      })} por asiento`,
      Icon: <AttachMoneyRoundedIcon color='secondary' />,
    });
  }
  if (
    boxEventData?.season?.isAvailable &&
    boxEventData?.season?.price &&
    boxEventData.season.price > 0
  ) {
    bannerIconTextItems.push({
      id: 7,
      text: `${moneyFormat(boxEventData.season?.price, {
        moneySign: false,
      })} por temporada`,
      Icon: <AttachMoneyRoundedIcon color='secondary' />,
    });
  }

  return bannerIconTextItems;
};

const getBoxPicturesArray = (boxEventData) =>
  boxEventData?.pictureUrl.map((srcImage, index) => ({
    id: index,
    altText: '',
    srcImage,
  }));

const SkeletonSection = () => (
  <Box>
    <Skeleton variant='text' width={'100%'} sx={{ height: '3.5rem', mb: 3 }} />
    <Skeleton variant='rectangular' width={'100%'} sx={{ height: '10rem' }} />
  </Box>
);

const boxEventSkeleton = () => {
  const sections = [];
  for (let index = 0; index < 3; index++) {
    sections.push(<SkeletonSection key={index} />);
  }
  return sections;
};

const subtitleProps = {
  variant: 'h2',
  sx: { mb: 3 },
};

/**
 * @function BoxEvent
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const BoxEvent = () => {
  const { eventID, boxID } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isErrorFetching, setIsErrorFetching] = useState(false);

  const getUrlTitle = (eventTitle) => {
    const urlEventTitle = slugify(eventTitle);
    const urlPathTitle =
      urlEventTitle === 'box' ? `oas-${urlEventTitle}` : urlEventTitle;
    return urlPathTitle;
  };

  const { data: eventData } = useEvent(eventID, {
    cacheTime: 5000,
    retry: 1,
    onError: () => {
      setIsErrorFetching(true);
    },
  });

  const { data: boxEventData } = useBoxEvent(boxID, eventID, {
    cacheTime: 5000,
    retry: 1,
    onError: () => setIsErrorFetching(true),
  });
  useDocumentTitle(`Oasis App ${eventData?.name ? '- ' + eventData.name : ''}`);

  const iconTextItems = useMemo(
    () => getBannerIconTextItems(boxEventData, eventData),
    [boxEventData, eventData]
  );

  const boxPictures = useMemo(
    () => getBoxPicturesArray(boxEventData),
    [boxEventData]
  );

  const isEventAvailableForSeason =
    boxEventData?.type === 'both' &&
    boxEventData?.season?.price &&
    boxEventData?.season?.isAvailable;

  const responsiveCarouselBreakpoints = getResponsiveObjectForFormula1(theme);

  const goToCheckoutProcess = (type) => {
    // To be consistent with the app
    const bookingType = type === 'event' ? 'onetime' : 'season';
    logAnalyticsEvent({
      eventName: `press_on_booking_${bookingType}`,
      params: {
        box_id: boxID,
        event_id: eventID,
        box_number: boxEventData?.number,
        box_zone: boxEventData?.boxZone,
        venue_name: eventData?.venue?.venue,
        is_box_shared: boxEventData?.isShared ? 1 : 0,
        box_availableSeats: boxEventData?.availableSeats,
        box_price: boxEventData?.price,
        box_price_per_ticket: boxEventData.pricePerTicket,
        event_name: boxEventData?.event?.name,
      },
    });
    logAnalyticsSelectItem({
      item_list_id: eventID,
      item_list_name: boxEventData?.event?.name,
      boxID: boxID,
      boxName: `${boxEventData?.boxZone}, ${boxEventData?.number}`,
      boxPrice: boxEventData?.price,
    });
    navigate(`checkout/${type}`);
  };

  useEffect(() => {
    if (isErrorFetching) {
      document.title = 'Oasis App - Evento no disponible';
      const meta = document.createElement('meta');
      meta.setAttribute('name', 'googlebot');
      meta.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }, [isErrorFetching]);

  return (
    <ViewContainer>
      <HalfCircle isLeftCircle top='250px' left='-2em' maxWidth='unset' />
      {!isErrorFetching && (
        <HalfCircle right='-2em' bottom='200px' opacity='.8' maxWidth='unset' />
      )}
      {isErrorFetching ? (
        <Box sx={{ mb: 15, py: 10, px: 3 }}>
          <EmptyState
            title='¡Oh no!'
            subtitle='Parece que este palco no esta disponible. Te invitamos a ver más palcos para este evento'
            buttonLabel='Ver palcos'
            onButtonClick={() =>
              navigate(
                `/events/${eventID}/${getUrlTitle(boxEventData?.event?.name)}`
              )
            }
          />
        </Box>
      ) : (
        <>
          <EventBanner
            isLoading={!(boxEventData && eventData)}
            pictureUrl={eventData?.pictureUrl}
            title={boxEventData?.event?.name}
            subtitle={`${boxEventData?.boxZone}, Zona ${boxEventData?.number}`}
            iconTextItems={iconTextItems}
            goBack={() =>
              navigate(
                `/events/${eventID}/${getUrlTitle(boxEventData?.event?.name)}`
              )
            }
            CTAComponent={
              <Stack sx={{ pt: 5 }} spacing={3}>
                <Button
                  variant='contained'
                  aria-label='Rentar'
                  color='secondary'
                  onClick={() => goToCheckoutProcess('event')}>
                  Rentar
                </Button>
                {isEventAvailableForSeason && (
                  <Button
                    variant='outlined'
                    aria-label='Temporada'
                    color='secondary'
                    onClick={() => goToCheckoutProcess('season')}>
                    Rentar por temporada
                  </Button>
                )}
              </Stack>
            }
          />
          <Box
            sx={{
              px: 3,
              mb: 15,
              maxWidth: 'lg',
              mx: 'auto',
              zIndex: 1,
              position: 'relative',
            }}>
            <Stack sx={{ width: '100%' }} spacing={10}>
              {!(boxEventData && eventData) ? (
                <>
                  {boxEventSkeleton()}
                  <CardsRowSkeleton
                    displayTitleSkeleton={true}
                    responsive={responsiveCarouselBreakpoints}
                  />
                </>
              ) : (
                <>
                  {/* ========= SECCIÓN DE AMENIDADES ========= */}
                  {boxEventData?.services.length > 0 && (
                    <Box>
                      <Typography {...subtitleProps}>Amenidades</Typography>
                      <Grid
                        container
                        justifyContent='flex-start'
                        alignItems='center'
                        spacing={2}>
                        {boxEventData?.services.map((service) => (
                          <Grid key={service.id} item xs={12} sm={6} md={4}>
                            <IconText
                              text={service.name}
                              Icon={
                                <img
                                  src={service.iconUrl}
                                  alt=''
                                  style={{ width: '1em', height: '1em' }}
                                />
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  {/* ========= SECCIÓN DE DESCRIPCIÓN ========= */}
                  {boxEventData?.detail && (
                    <Box>
                      <Typography {...subtitleProps}>Descripción</Typography>
                      <Typography variant='body1'>
                        {boxEventData.detail}
                      </Typography>
                    </Box>
                  )}
                  {/* ========= SECCIÓN DE INDICACIONES ACCESO ========= */}
                  {boxEventData?.accessIndications && (
                    <Box>
                      <Typography {...subtitleProps}>
                        Indicaciones de acceso
                      </Typography>
                      <Typography variant='body1'>
                        {boxEventData.accessIndications}
                      </Typography>
                    </Box>
                  )}
                  {/* ========= SECCIÓN DE FOTOS ========= */}
                  {/* FOTOS */}
                  <Carousel
                    carouselItems={boxPictures}
                    Item={(props) => (
                      <VenueItem
                        {...props}
                        elementHeight='18em'
                        containerSX={{
                          px: 3,
                          '& img': { objectFit: 'cover !important' },
                        }}
                      />
                    )}
                    responsive={responsiveCarouselBreakpoints}
                  />

                  {/* ========= SECCIÓN DE EVENTOS TEMPORADA  ========= */}
                  {/* TODO, EL GET DE LOS EVENTOS DE TEMPORADA */}
                  {/* <Typography {...subtitleProps}>
                Eventos incluidos por temporada
              </Typography> */}
                  {/* ========= SECCIÓN DE UBICACIÓN  ========= */}
                  {boxEventData?.venuePictureUrl ? (
                    <Box>
                      <Typography {...subtitleProps}>Ubicación</Typography>
                      <VenueMap
                        venueImage={boxEventData?.venuePictureUrl}
                        venueHeight={boxEventData?.pictureHeight}
                        venueWidth={boxEventData?.pictureWidth}
                        boxPositions={[{ id: 1, ...boxEventData?.position }]}
                      />
                    </Box>
                  ) : (
                    <SkeletonSection />
                  )}
                  {boxEventData && (
                    <>
                      <Stack alignItems='flex-start'>
                        {legalDocuments.map((legalDocument) => (
                          <Button
                            key={legalDocument.id}
                            variant='text'
                            aria-label='Legal BTN'
                            color='secondary'
                            sx={{ textTransform: 'none' }}
                            startIcon={<InsertDriveFileIcon />}
                            onClick={() => openExternalLink(legalDocument.id)}>
                            {legalDocument.text}
                          </Button>
                        ))}
                      </Stack>

                      <Box>
                        <Typography {...subtitleProps} textAlign='center'>
                          No te quedes sin lugar
                        </Typography>
                        <Stack
                          direction={{ xs: 'column-reverse', md: 'row' }}
                          justifyContent='center'
                          alignItems='center'
                          spacing={6}
                          sx={{
                            maxWidth: 'md',
                            mx: 'auto',
                            pt: 6,
                            mb: '-60px',
                          }}>
                          <Stack
                            sx={{
                              mb: {
                                xs: '-60px',
                                md: isEventAvailableForSeason
                                  ? '-138px'
                                  : '-60px',
                                maxWidth: '100%',
                              },
                            }}>
                            <img src={SeatsImage} alt='' />
                          </Stack>
                          <Stack spacing={4} sx={{ flexGrow: 1 }}>
                            <Button
                              variant='contained'
                              color='secondary'
                              aria-label='Rentar'
                              onClick={() => goToCheckoutProcess('event')}>
                              Rentar
                            </Button>
                            {isEventAvailableForSeason && (
                              <Button
                                variant='outlined'
                                color='secondary'
                                aria-label='Temporada'
                                onClick={() => goToCheckoutProcess('season')}>
                                Rentar por temporada
                              </Button>
                            )}
                            <Button
                              variant='outlined'
                              color='secondary'
                              aria-label='Otros palcos'
                              onClick={() => {
                                navigate(
                                  `/events/${
                                    boxEventData.eventId
                                  }/${getUrlTitle(boxEventData?.event?.name)}`
                                );
                                scrollToTop();
                              }}>
                              Ver otros palcos
                            </Button>
                          </Stack>
                        </Stack>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Box>
        </>
      )}
    </ViewContainer>
  );
};

BoxEvent.defaultProps = {
  something: '',
};

BoxEvent.propTypes = {
  something: PropTypes.string,
};

export default BoxEvent;
