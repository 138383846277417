import React from 'react';
import PropTypes from 'prop-types';
// UI
import FacebookIcon from '../../assets/socialMedia/Facebook.svg';
import InstagramIcon from '../../assets/socialMedia/Instagram.svg';
import TwitterIcon from '../../assets/socialMedia/Twitter.svg';
import TiktokIcon from '../../assets/socialMedia/Tiktok.svg';
import { Box, Grid } from '@mui/material';
// Components & Utils
import IconButtonOasis from './buttons/IconButtonOasis';
import externalLinks from '../../utils/json/externalLinks.json';

const socialNetworks = [
  { id: 'facebook', iconPath: FacebookIcon },
  { id: 'twitter', iconPath: TwitterIcon },
  { id: 'instagram', iconPath: InstagramIcon },
  { id: 'tiktok', iconPath: TiktokIcon },
];

/**
 * @function SocialMedia
 * @author RCH010
 * @version 1.0
 * @description Returns a row Grid with social media buttons
 * @param {String} justifyContent for Grid
 * @param {Number} spacing between elements
 * @returns {JSX}
 */
const SocialMedia = ({ justifyContent, spacing }) => {
  return (
    <Grid
      container
      spacing={spacing}
      justifyContent={justifyContent}
      sx={{ px: 3, minHeight: '4.5em' }}>
      {socialNetworks.map((socialNetwork) => (
        <Grid
          key={socialNetwork.id}
          item
          xs={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Box>
            <a
              target='_blank'
              href={externalLinks[socialNetwork.id]}
              rel='noreferrer noopener'>
              <IconButtonOasis
                ariaLabel={socialNetwork.id}
                IconPath={socialNetwork.iconPath}
              />
            </a>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

SocialMedia.defaultProps = {
  justifyContent: 'flex-end',
  spacing: 1,
};
SocialMedia.propTypes = {
  justifyContent: PropTypes.string,
  spacing: PropTypes.number,
};

export default SocialMedia;
