import React from 'react';
import PropTypes from 'prop-types';

// Components & Utils
import CloseModalButton from './CloseModalButton';

// UI
import { Drawer } from '@mui/material';

/**
 * @function BottomSheet
 * @author RCH010
 * @version 1.0
 * @param {Boolean} isOpen
 * @param {Boolean} showCloseButton
 * @param {Function} onClose
 * @param {Object} ModalProps
 * @param {Object} PaperProps
 * @param {Object} additionalStyles
 * @returns {JSX}
 */
const BottomSheet = ({
  isOpen,
  onClose = () => {},
  ModalProps,
  PaperProps,
  additionalStyles,
  children,
  showCloseButton,
  ...rest
}) => {
  return (
    <Drawer
      open={isOpen}
      anchor='bottom'
      ModalProps={ModalProps}
      PaperProps={PaperProps}
      onClose={onClose}
      sx={{
        zIndex: 2100,
        position: 'relative',
        ...additionalStyles,
      }}
      {...rest}>
      <CloseModalButton onClose={onClose} />
      {children}
    </Drawer>
  );
};

BottomSheet.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  ModalProps: PropTypes.object,
  PaperProps: PropTypes.object,
  additionalStyles: PropTypes.object,
};

export default BottomSheet;
