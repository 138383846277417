import React from 'react';
// UI
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Grid,
  Stack
} from '@mui/material';
import securityImage from '../assets/securityFeatures/secureOasis.svg';
// Components & Utils
import ViewContainer from '../components/shared/ViewContainer';
import ImageWithTextCard from '../components/shared/ImageWithTextCard';
import DownloadAppSection from '../components/shared/DownloadAppSection';
import FAQSection from '../components/shared/FAQSection';
import ImageCard from '../components/shared/ImageCard';
import HalfCircle from '../components/shared/HalfCircle';
import { mechanismsData, partnersData } from '../utils/securityData';
import useDocumentTitle from '../hooks/useDocumentTitle';
import TestimoniesSections from '../components/landing/TestimoniesSections';
import { nosotrosData } from '../utils/nosotrosData';
import BannersCarousel from '../components/landing/BannersCarousel';

/**
 * @function SecurityFeatures
 * @author RCH010
 * @version 1.0
 * @description View for security features
 * @returns {JSX}
 */
const SecurityFeatures = () => {
  useDocumentTitle('Oasis App - Seguridad al rentar');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ViewContainer>
      <HalfCircle isLeftCircle left='-2em' bottom='30em' />
      <HalfCircle right='-2em' top='20em' opacity='.2' />
      <Box
        sx={{
          zIndex: 1,
          position: 'relative',
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
          mx: 'auto',
          mt: { xs: 12, md: 18 },
          px: 3,
        }}>
        <Typography sx={{ mb: '-1em' }} variant='h1'>
          Un Oasis {isMobile && '\n'}seguro
        </Typography>
        <Stack
          direction={{ xs: 'column', md: 'row-reverse' }}
          justifyContent='center'
          alignItems='center'>
          <img
            src={securityImage}
            alt=''
            style={{
              marginTop: isMobile ? '-1em' : '0',
              marginRight: isMobile ? '-5em' : '0',
              height: isMobile ? '15em' : '30em',
            }}
          />
          <Typography variant='body1'>
            <span style={{ fontWeight: 600 }}>
              Oasis es la plataforma líder en el mercado mexicano especializada
              en la renta de palcos y activos de entretenimiento.
            </span>{' '}
            Utilizamos rigurosos y sofisticados criterios enfocados en seguridad
            anti-fraudes para que todo tu proceso de renta esté protegido.
          </Typography>
        </Stack>
        {/* AQUI IRA EL CARRUSEL DE REDES */}
        <BannersCarousel
          title='Las redes hablan de nosotros'
          data={nosotrosData}
          variantTitle='h2'
          widthImg='100%'
          bkDesk={1} 
          bkTablet={1}
          bkMobile={1}
        />    
        <Typography variant='h2' align='center' sx={{ mt: 15 }}>
          Conoce nuestos mecanismos de seguridad
        </Typography>
        <Box
          sx={{
            mt: 8,
            mb: 8,
          }}>
          <Grid
            container
            spacing={isMobile ? 3 : 5}
            justifyContent='center'
            alignItems='flex-start'>
            {mechanismsData.map((item) => (
              <Grid key={item.id} item xs={12} md={6} sx={{ height: '100%' }}>
                <ImageWithTextCard
                  {...item}
                  backgroundColor='transparent'
                  textColor={theme.palette.common.white}
                  justifyContent='flex-start'
                  imageMaxHeigh='7em'
                  imageMaxWidth='auto'
                  textVariant='body2'
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <TestimoniesSections/>
      {/* AQUI IRA EL VIDEO DE SEGUIRDAD CUANDO ESTE LISTO */}
      <Box
        sx={{
          maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
          mx: 'auto',
          zIndex: 1,
          position: 'relative',
        }}>
        <Typography variant='h2' align='center' sx={{ mt: 15 }}>
          Nuestras alianzas comerciales nos respaldan
        </Typography>
        <Grid
          container
          spacing={isMobile ? 0 : 2}
          justifyContent='center'
          alignItems='center'>
          {partnersData.map((item) => (
            <Grid key={item.id} item xs={4} sm={3}>
              <ImageCard
                {...item}
                backgroundColor='transparent'
                imageMaxWidth='5em'
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ my: 8, zIndex: 1, position: 'relative' }}>
        <DownloadAppSection />
      </Box>
      <FAQSection />
    </ViewContainer>
  );
};

export default SecurityFeatures;
