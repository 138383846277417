import React from 'react';
import PropTypes from 'prop-types';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// UI
import { Box, Skeleton } from '@mui/material';

// This returns an array with six skeleton cards
const cardsForCarousel = () => {
  const cards = [];
  for (let index = 0; index < 6; index++) {
    cards.push(
      <Skeleton
        key={index}
        variant='rectangular'
        sx={{
          borderRadius: '16px',
          height: { xs: '13.25em', sm: '13.4em', md: '17.3em', lg: '17.4em' },
          width: { xs: '9.5em', sm: '10.8em', md: '12em', lg: '15em' },
          mx: 'auto',
        }}
      />
    );
  }
  return cards;
};

/**
 * @function CardsRowSkeleton
 * @author RCH010
 * @version 1.0
 * @description Renders carousel with 6 skeleton cards, this is used to simulate a loading state of a row
 * @param {Object} responsive object used for MUICarousel
 * @param {Boolean} displayTitleSkeleton if true, a skeleton for the title will appear
 * @returns {JSX}
 */
const CardsRowSkeleton = ({ responsive, displayTitleSkeleton }) => {
  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: 'lg',
          mx: 'auto',
        }}>
        {displayTitleSkeleton && (
          <Skeleton
            width='40%'
            height='4em'
            variant='text'
            sx={{ mb: 3, mx: 3 }}
          />
        )}
        <MultiCarousel
          arrows={false}
          partialVisible={true}
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          keyBoardControl={true}>
          {cardsForCarousel()}
        </MultiCarousel>
      </Box>
    </Box>
  );
};

CardsRowSkeleton.defaultProps = {
  responsive: {},
  displayTitleSkeleton: true,
};

CardsRowSkeleton.propTypes = {
  responsive: PropTypes.object.isRequired,
  displayTitleSkeleton: PropTypes.bool,
};

export default CardsRowSkeleton;
