import React from 'react';

// UI
import { Box, Stack, Typography } from '@mui/material';
import Logo from '../../assets/logo/Logo.svg';

export const AuthView = ({ children }) => {
  return (
    <Box
      sx={{
        background: (t) => t.palette.lightLinearGradient,
        minHeight: '100vh',
        width: '100%',
        py: 20,
      }}>
      <Stack
        spacing={10}
        sx={{ maxWidth: 'lg', mx: 'auto', py: { xs: 0, md: 10 }, px: 3 }}
        direction={{ xs: 'column', md: 'row' }}
        alignItems='center'
        justifyContent={{ sx: 'center', md: 'space-between' }}>
        <Stack
          justifyContent='center'
          alignItems={{ xs: 'center', md: 'flex-start' }}
          sx={{
            display: { xs: 'none', md: 'flex' },
            mb: 10,
            '& > img': {
              height: { xs: '50px', md: '70px' },
            },
          }}>
          <img src={Logo} alt='Oasis' />
          <Typography
            my={2}
            variant='body1'
            sx={{
              textAlign: { xs: 'center', md: 'left' },
            }}>
            Desde Oasis, todo el mundo se ve mejor
          </Typography>
        </Stack>
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{ maxWidth: '100%', width: '26em' }}
          spacing={3}>
          {children}
        </Stack>
      </Stack>
    </Box>
  );
};
