import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Components & Utils
import TwoButtons from '../shared/buttons/TwoButtons';
import { SignUpContext } from '../../providers/SignUpProvider';

// UI
import { Button, Stack, Typography } from '@mui/material';

/**
 * @function SignUpThirdStep
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const SignUpThirdStep = () => {
  const { updateStep, signUpData } = useContext(SignUpContext);
  const navigate = useNavigate();

  const sendVerificationEmail = () => {
    // TODO: re-enviar correo de validar contraseña
  };

  return (
    <Stack spacing={3}>
      <Typography variant='h4'>Verifica tu correo</Typography>
      <Typography variant='body1'>
        Hemos enviado un correo a <strong>{signUpData.email}</strong> para
        verificar tu cuenta de correo.
      </Typography>
      <Stack spacing={1}>
        <Typography variant='body2'>¿No has recibido el correo?</Typography>
        <Button
          variant='text'
          aria-label='Step 3'
          onClick={sendVerificationEmail}
          color='secondary'>
          Volver a mandar correo
        </Button>
      </Stack>
      <Stack alignItems='flex-end' sx={{ py: 2 }}>
        <TwoButtons
          primary={{
            isFullWidth: true,
            label: 'Ir a iniciar sesión',
            onClick: () => navigate('/login'),
          }}
          secondary={{
            label: 'Atrás',
            onClick: () => updateStep(-1),
          }}
          containerStyles={{
            width: '100%',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SignUpThirdStep;
