import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Components & Utils
import { CheckoutContext } from '../../providers/CheckoutProvider';
import DiscountBadge from './DiscountBadge';
import Price from './Price';
import ProductButtonModal from './ProductButtonModal';
import ResponsiveModal from '../shared/modals/ResponsiveModal';

// UI
import { Box, Stack, TextField, Typography } from '@mui/material';

/**
 * @function ProductModal
 * @author RCH010
 * @version 1.0
 * @description Modal that displays product information
 * @param {Boolean} isModalOpen
 * @param {Function} handleModalOpen
 * @param {Object} currentProduct
 * @returns {JSX}
 */
const ProductModal = ({ isModalOpen, handleModalOpen, currentProduct }) => {
  const { updateCart, cart } = useContext(CheckoutContext);

  return (
    <ResponsiveModal
      showCloseButton
      isOpen={isModalOpen}
      onClose={() => handleModalOpen(false)}>
      {currentProduct && (
        <Stack
          spacing={1}
          sx={{
            mx: 4,
            mt: 2,
            mb: 4,
            maxWidth: 'md',
          }}>
          <Box
            sx={{
              width: '100%',
              '& img': {
                display: 'block',
                mx: 'auto',
                width: '20em',
                maxWidth: '100%',
                maxHeight: { xs: '15em', md: '20em' },
                objectFit: 'contain',
              },
            }}>
            <img alt={currentProduct.name} src={currentProduct.pictureURL} />
            {currentProduct.discount > 0 && (
              <Box sx={{ mt: '-0.9em', zIndex: 5, position: 'relative' }}>
                <DiscountBadge
                  isLarge
                  price={currentProduct.price}
                  discount={currentProduct.discount}
                />
              </Box>
            )}
          </Box>
          <Typography variant='h4'>{currentProduct.name}</Typography>
          <Price
            isLarge
            price={currentProduct.price}
            discount={currentProduct.discount}
          />
          <Typography variant='body1'>{currentProduct.description}</Typography>
          <TextField
            label='Agrega un comentario'
            placeholder=''
            multiline
            color='neutral'
            variant='filled'
            size='small'
            value={cart[currentProduct?.id]?.comment || ''}
            onChange={(e) =>
              updateCart(currentProduct?.id, { comment: e.target?.value })
            }
          />
          <Box>
            <ProductButtonModal
              id={currentProduct.id}
              price={currentProduct.price}
            />
          </Box>
        </Stack>
      )}
    </ResponsiveModal>
  );
};

ProductModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleModalOpen: PropTypes.func,
  currentProduct: PropTypes.object,
};

export default ProductModal;
