import { useState, useEffect } from 'react';

const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = useState(null);
  
  useEffect(() => {
    const lowImg = new Image();
    const highImg = new Image();

    highImg.src = highQualitySrc;
    lowImg.src = lowQualitySrc;
    
    lowImg.onload = () => {
      if(!highImg.complete) {
        setSrc(lowQualitySrc);
      }
    };
    highImg.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [highQualitySrc, lowQualitySrc]);

  return [src, { blur: src === lowQualitySrc }];
};
export default useProgressiveImg;

/**
 * USAGE:
 * 
 * For this, we need two images, 
 * imageSRC --> the image you want to short
 * imageLowSRC --> the image, but in super low quality
 * 
 * const [src, { blur }] = useProgressiveImg(imageLowSRC, imageSRC);
 * 
 * <img src={src} alt='lazy_sort_off' 
 *    style={{ 
 *      filter: blur ? 'blur(20px)' : 'none', 
 *      transition: blur ? 'none' : 'filter 0.75s ease-out', 
 *    }}
    />
 * 
 */


