import React, { useContext } from 'react';
// Components & Utils
import { CheckoutContext } from '../../providers/CheckoutProvider';
import { bookingSteps } from '../../utils/enums';
// UI
import {
  Box,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material';

const formStepperLabels = {
  [bookingSteps.ADDRESS]: 'Entrega de pases',
  [bookingSteps.TICKETS]: 'Asientos',
  [bookingSteps.FOOD_AND_BEVERAGES]: 'Alimentos y bebidas',
  [bookingSteps.PAYMENT]: 'Pago',
};

/**
 * @function FormStepper
 * @author RCH010
 * @version 1.2
 * @description Stepper
 * @returns {JSX}
 */
const FormStepper = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { currentStep, steps } = useContext(CheckoutContext);

  const stepIndex = steps.findIndex((element) => element === currentStep);
  const progressPercentage = ((stepIndex + 1) * 100) / steps.length;

  if (isMobile) {
    return (
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 2 }}>
          <LinearProgress
            variant='determinate'
            value={progressPercentage}
            color='secondary'
            sx={{
              '& > span': { borderRadius: '3px' },
              borderRadius: '3px',
              height: '6px',
            }}
          />
        </Box>
        <Box sx={{ minWidth: 45 }}>
          <Typography variant='caption'>{`${Math.round(
            progressPercentage
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={stepIndex}>
        {steps.map((stepID) => (
          <Step
            key={stepID}
            sx={{
              '& .MuiStepLabel-label': { fontSize: '0.75rem' },
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'secondary.dark', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'secondary.main', // circle color (ACTIVE)
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: 'common.white', // Just text label (ACTIVE)
                },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: 'black', // circle's number (ACTIVE)
              },
            }}>
            <StepLabel>{formStepperLabels[stepID]}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default FormStepper;
