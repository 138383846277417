import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

/**
 * @function ImageWithTextCard
 * @author RCH010
 * @version 1.0
 * @description component ad ImageCard, but this component renders also a title
 * and on mobile changes direction
 * @param {String} image
 * @param {String} title
 * @param {String} supportingText
 * @param {String} backgroundColor
 * @param {String} textColor
 * @param {String} shadow
 * @param {String} justifyContent
 * @param {String} imageMaxWidth
 * @param {String} imageMaxHeigh
 * @param {String} imageContainerHeight
 * @param {String} textVariant
 * @param {String} titleVariant
 * @returns {JSX}
 */
const ImageWithTextCard = ({
  image,
  title,
  supportingText,
  backgroundColor,
  textColor,
  shadow,
  justifyContent,
  imageMaxWidth,
  imageMaxHeigh,
  imageContainerHeight,
  textVariant,
  titleVariant,
}) => {
  return (
    <Box
      sx={{
        boxShadow: shadow,
        display: 'flex',
        // flexDirection: { xs: 'row', md: 'column' },
        flexDirection: 'column',
        justifyContent: justifyContent,
        alignItems: 'center',
        p: { xs: 1, md: 3 },
        width: '100%',
        height: '100%',
        borderRadius: 8,
        backgroundColor: backgroundColor,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '4em', md: '100%' },
          minWidth: { xs: '4em', md: 'unset' },
          mr: { xs: 2, md: 0 },
          height: imageContainerHeight,
          '& > img': {
            width: '100%',
            height: 'auto',
            maxHeight: imageMaxHeigh,
            maxWidth: imageMaxWidth,
            objectFit: 'contain',
          },
        }}>
        <img src={image} alt={supportingText} />
      </Box>
      <Box sx={{ width: '100%' }}>
        {title && (
          <Typography
            sx={{
              color: (theme) => textColor || theme.palette.primary.dark,
              width: '100%',
              textAlign: { xs: 'left', md: 'center' },
              my: 2,
              lineHeight: '1.2',
            }}
            gutterBottom
            variant={titleVariant}
            variantMapping={{ h6: 'p' }}>
            {title}
          </Typography>
        )}
        <Typography
          sx={{
            color: (theme) => textColor || theme.palette.primary.dark,
            pt: { xs: 0, md: 2 },
            width: '100%',
            textAlign: { xs: 'left', md: 'center' },
          }}
          variant={textVariant}
          align='center'
          variantMapping={{ h6: 'p' }}>
          {supportingText}
        </Typography>
      </Box>
    </Box>
  );
};

ImageWithTextCard.defaultProps = {
  backgroundColor: grey.A100,
  textColor: null,
  shadow: 'unset',
  justifyContent: 'space-between',
  imageMaxWidth: 'unset',
  imageMaxHeigh: 'unset',
  imageContainerHeight: '100%',
  textVariant: 'h6',
  titleVariant: 'h6',
  title: null,
};

ImageWithTextCard.propTypes = {
  image: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  shadow: PropTypes.string,
  justifyContent: PropTypes.string,
  imageMaxWidth: PropTypes.string,
  imageMaxHeigh: PropTypes.string,
  imageContainerHeight: PropTypes.string,
  textVariant: PropTypes.string,
  titleVariant: PropTypes.string,
};

export default ImageWithTextCard;
