import React from 'react';
import PropTypes from 'prop-types';

// UI
import { Box, IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

/**
 * @function CloseModalButton
 * @author RCH010
 * @version 1.0
 * @description Button used on ModalComponent and BottomSheet
 * @param {Function} onClose
 * @returns {JSX}
 */
const CloseModalButton = ({ onClose }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 10,
        left: 16,
        transition: 'transform 200ms',
        ':hover': {
          transform: 'rotate(90deg)',
        },
      }}>
      <IconButton onClick={onClose}>
        <CloseRoundedIcon
          sx={{
            color: (t) => t.palette.secondary.light,
          }}
        />
      </IconButton>
    </Box>
  );
};

CloseModalButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CloseModalButton;
