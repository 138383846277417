import React, { useContext, useEffect, useState } from 'react';

// Components & Utils
import { CheckoutContext } from '../../../providers/CheckoutProvider';
import NumberPicker from '../../shared/NumberPicker';
import FormContainer from '../FormContainer';

// UI
import { Box, Typography } from '@mui/material';

/**
 * @function TicketsForm
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const TicketsForm = () => {
  const { boxData, bookingOptions, updateBookingOptions } =
    useContext(CheckoutContext);

  const [numberOfTickets, setNumberOfTickets] = useState(
    bookingOptions?.numberOfSeats || 1
  );

  const onNumberPickerPress = (type) => {
    const delta = boxData?.deltaNumberOfSeats || 1;
    const newNumber =
      type === 'plus' ? numberOfTickets + delta : numberOfTickets - delta;
    setNumberOfTickets(newNumber);
    updateBookingOptions({ numberOfSeats: newNumber });
  };

  useEffect(() => {
    updateBookingOptions({ numberOfSeats: bookingOptions?.numberOfSeats || 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContainer>
      <Box>
        <Typography variant='h3' sx={{ mb: { xs: 6, md: 12 } }}>
          ¿Cuántos asientos quieres?
        </Typography>
        <NumberPicker
          value={numberOfTickets}
          onBtnPress={onNumberPickerPress}
          disablePlus={
            numberOfTickets + boxData?.deltaNumberSeats >
            boxData?.maxNumberSeats
          }
          disableMinus={
            numberOfTickets - boxData?.deltaNumberSeats <
            boxData?.minNumberSeats
          }
        />
      </Box>
    </FormContainer>
  );
};

export default TicketsForm;
