import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logAnalyticsEvent = ({eventName, params}) => {
  return logEvent(analytics, eventName, params)
};


// For when an event or box is clicked
export const logAnalyticsSelectContent = (content_type, item_id) => {
  return logEvent(analytics, 'select_content', {
    content_type,
    item_id,
  })
}

// For when the user selects the box to book
export const logAnalyticsSelectItem = ({
  item_list_id,
  item_list_name,
  boxID,
  boxName,
  boxPrice
}) => {
  return logEvent(analytics, 'select_item', {
    item_list_id,
    item_list_name,
    items: [{
      item_id: String(boxID),
      item_name: boxName,
      currency: 'MXN',
      price: String(boxPrice),
    }]
  })
}

export const logSearch = (searchValue) => {
  return logEvent(analytics, 'search', {
    search_term: searchValue
  })
};

export const logCheckoutStarts = (price, boxID, eventID) => {
  return logEvent(analytics, 'begin_checkout', {
    currency: 'MXN',
    value: price,
    items: [{
      item_id: String(boxID),
      item_list_id: String(eventID),
    }]
  })
}

export const logPurchase = (value, transactionID, boxID, eventID) => {
  return logEvent(analytics, 'purchase', {
    value: value,
    currency: 'MXN',
    transaction_id: transactionID,
    items: [{
      item_id: String(boxID),
      item_list_id: String(eventID),
    }],
  })
}

export const logLogin = (method) => {
  return logEvent(analytics, 'login', {method})
}

export const logSignUp = (method) => {
  return logEvent(analytics, 'sign_up', {method})
}
