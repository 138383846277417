import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

// Components & Utils
import { useZIPCode } from '../../../hooks/requests';
import { zipCodeRegex } from '../../../utils/regex';
import TextInput from '../../inputs/TextInput';
import TwoButtons from '../../shared/buttons/TwoButtons';

// UI
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';

const addressDefaultValues = {
  street: '',
  extNumber: '',
  intNumber: '',
  neighborhood: '',
  city: '',
  state: '',
  country: '',
  zip: '',
};

/**
 * @function NewAddressForm
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const NewAddressForm = ({
  onSubmit,
  onBackButtonPress,
  defaultValues,
  buttonLabel,
  buttonsContainerStyles,
  setAddressCallback,
}) => {
  const [searchForZIPCode, setSearchForZIPCode] = useState(false);
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([]);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [neighborhoodAutocomplete, setNeighborhoodAutocomplete] = useState('');
  const [neighborhoodTextField, setNeighborhoodTextField] = useState('');

  const {
    register,
    watch,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues || addressDefaultValues,
  });
  const zipCode = watch('zip');

  const { data: postalCodeData } = useZIPCode(zipCode, {
    enabled: searchForZIPCode,
    onError: () => {
      setSearchForZIPCode(false);
    },
    onSuccess: (data) => {
      setNeighborhoodsOptions(data.asentamiento);
      setValue('city', data.municipio || data.ciudad, { shouldValidate: true });
      setValue('state', data.estado, { shouldValidate: true });
      setNeighborhoodAutocomplete(data.asentamiento[0]);
      setNeighborhoodTextField(data.asentamiento[0]);
      setSearchForZIPCode(false);
    },
    retry: false,
  });

  // On valid zipcode, trigger get copomex data
  useEffect(() => {
    if (zipCode?.length === 5 && !errors.zip) {
      setSearchForZIPCode(true);
    } else {
      setSearchForZIPCode(false);
    }
  }, [zipCode, errors.zip, errors]);

  const onFormSubmit = async (values) => {
    setIsSubmitButtonLoading(true);
    const newAddress = {
      ...values,
      neighborhood: neighborhoodTextField || neighborhoodAutocomplete,
      country: postalCodeData?.pais || 'México',
    };

    try {
      onSubmit(newAddress);
    } catch (error) {
      // TODO ALERT DE ERROR GUARDANDO DIRECCION
      setIsSubmitButtonLoading(false);
    }
  };

  return (
    <Stack component='form' onSubmit={handleSubmit(onFormSubmit)} spacing={2}>
      <TextInput
        sx={{ m: 0 }}
        register={register}
        inputVariant='text'
        registerName='street'
        required={true}
        label='Calle'
        placeholder=''
      />
      <Stack direction='row' spacing={2}>
        <TextInput
          sx={{ m: 0 }}
          fullWidth
          register={register}
          inputVariant='text'
          registerName='extNumber'
          required={true}
          label='Número exterior'
          placeholder=''
        />
        <TextInput
          sx={{ m: 0 }}
          fullWidth
          register={register}
          inputVariant='text'
          registerName='intNumber'
          required={false}
          label='Número interior'
          placeholder=''
        />
      </Stack>
      <TextInput
        register={register}
        inputVariant='text'
        registerName='zip'
        placeholder=''
        required={true}
        label='Código Postal'
        error={Boolean(touchedFields.zip) && Boolean(errors.zip)}
        helperText={
          Boolean(touchedFields.zip) &&
          Boolean(errors.zip) &&
          errors.zip.message
        }
        additionalValidations={{
          pattern: {
            value: zipCodeRegex,
            message: 'Este no parece ser un código postal válido.',
          },
        }}
      />
      <Stack spacing={2} sx={{ mb: 3 }}>
        <Autocomplete
          placeholder=''
          color='secondary'
          freeSolo
          options={neighborhoodsOptions}
          ListboxProps={{
            sx: { backgroundColor: '#2D1E5A' },
          }}
          value={neighborhoodAutocomplete}
          onChange={(event, newValue) => {
            setNeighborhoodAutocomplete(newValue);
          }}
          inputValue={neighborhoodTextField}
          onInputChange={(event, newInputValue) => {
            setNeighborhoodTextField(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              color='secondary'
              required
              sx={{ display: 'block', width: 'unset', m: 0 }}
              {...params}
              label='Colonia'
            />
          )}
          isOptionEqualToValue={(option, value) => option === value}
        />
        <TextInput
          placeholder=''
          InputLabelProps={{ shrink: true }}
          register={register}
          inputVariant='text'
          registerName='city'
          required={true}
          label='Ciudad'
        />
        <TextInput
          placeholder=''
          InputLabelProps={{ shrink: true }}
          register={register}
          inputVariant='text'
          registerName='state'
          required={true}
          label='Estado'
        />
      </Stack>
      <Stack alignItems='flex-end'>
        <TwoButtons
          primary={{
            type: 'submit',
            label: isSubmitButtonLoading ? (
              <>
                Guardando
                <CircularProgress
                  color='secondary'
                  size='1.9rem'
                  sx={{ ml: 2 }}
                />
              </>
            ) : (
              buttonLabel
            ),
            isFullWidth: true,
            isDisabled:
              !isValid ||
              !(neighborhoodAutocomplete || neighborhoodTextField) ||
              isSubmitButtonLoading,
          }}
          {...(onBackButtonPress && {
            secondary: {
              label: 'Atrás',
              onClick: onBackButtonPress,
            },
          })}
          containerStyles={{
            width: '25em',
            ...buttonsContainerStyles,
          }}
        />
      </Stack>
    </Stack>
  );
};

NewAddressForm.defaultProps = {
  onSubmit: () => {},
  onBackButtonPress: null,
  defaultValues: null,
  buttonLabel: 'Siguiente',
  buttonsContainerStyles: {},
  setAddressCallback: null,
};

NewAddressForm.propTypes = {
  something: PropTypes.string,
};

export default NewAddressForm;
