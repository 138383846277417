import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import { useGetBoxQuote } from '../../hooks/requests';
import { moneyFormat } from '../../utils/util';
// UI
import { Divider, Stack, Typography, CircularProgress } from '@mui/material';
import { CheckoutContext } from '../../providers/CheckoutProvider';
import { calculateTotal } from '../checkout/checkoutHelper';
import { bookingSteps } from '../../utils/enums';

const QuoteDivider = ({ isWhite }) => (
  <Divider
    flexItem
    light
    orientation='horizontal'
    sx={{
      alignSelf: 'center',
      flex: 1,
      mx: 2,
      borderColor: isWhite ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
    }}
  />
);

const quoteKeys1 = {
  box: 'Subtotal palco',
  boxTaxComission: 'Impuestos y comisiones palco',
  deposit: 'Depósito de garantía',
};

const quoteKeys2 = {
  subtotalSum: 'Subtotal',
  comissions: 'Impuestos y comisiones',
};

const textStylesVariants = {
  regular: {
    text: {
      color: 'secondary.contrastText',
      fontSize: '0.9rem',
    },
    total: {
      color: 'secondary.main',
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
  },
  white: {
    text: {
      color: 'inherit',
      fontSize: '0.9rem',
    },
    total: {
      color: 'secondary.main',
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
    divider: {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
  },
};

/**
 * @function QuoteSummary
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const QuoteSummary = ({ boxID, eventID, totalSeats, type, styleVariant }) => {
  const { cart, boxData, currentStep } = useContext(CheckoutContext);
  const boxQuoteParams = {
    boxId: boxID,
    eventId: eventID,
    type: type,
  };
  if (totalSeats) boxQuoteParams[totalSeats] = totalSeats;
  const { data, isFetchedAfterMount } = useGetBoxQuote(
    {
      boxId: boxID,
      eventId: eventID,
      totalSeats: totalSeats,
      type: type,
    },
    {
      cacheTime: 5000,
      onError: () => {},
    }
  );

  const quote = {
    ...data,
    boxTaxComission: Number(data?.tax) + Number(data?.fee),
    box: Number(data?.subtotal_box),
    subtotalSum: Number(data?.subtotal_box) + Number(data?.subtotal_food),
    comissions: Number(data?.fee) + Number(data?.tax) + Number(data?.tax_food),
  };

  // When the checkout process has a menuId, add the key for FoodAndBeverages
  // If the total fro foodAndBeverages is coming from the sever, keep it, else
  // Calculate it and add it to quote
  if (boxData?.menuId && type === 'onetime') {
    quoteKeys1['foodAndBeverages'] = 'Alimentos y bebidas';
    // If current step isn't Payment then... we should calculate the food and
    // beverages cost, based on state cart. Because the cart on the DB is updated
    // when the user continues to the next step, so the cost wouldn't be correct
    // until th request is done.
    if (currentStep !== bookingSteps.PAYMENT) {
      const totalCart = calculateTotal(cart);
      quote['foodAndBeverages'] = totalCart ? totalCart : 0;
      quote.total =
        Number(quote?.box) +
        Number(quote?.boxTaxComission) +
        Number(quote?.deposit) +
        Number(quote?.foodAndBeverages);
    } else {
      // Use the total given by the server
      quote.total = Number(data?.total);
      const cartTotal = Number(data?.subtotal_food) + Number(data?.tax_food);
      if (cartTotal) {
        quote['foodAndBeverages'] = cartTotal;
      } else {
        delete quote['foodAndBeverages'];
      }
    }
  }

  return (
    <Stack direction='column'>
      {Object.keys(quoteKeys1).map((key) => (
        <Stack key={key} direction='row' justifyContent='space-between'>
          <Typography
            variant='body2'
            sx={textStylesVariants[styleVariant].text}>
            {quoteKeys1[key]}
          </Typography>
          <QuoteDivider isWhite={styleVariant === 'white'} />
          <Typography
            variant='body2'
            sx={textStylesVariants[styleVariant].text}>
            {isFetchedAfterMount ? (
              moneyFormat(Number(quote[key]))
            ) : (
              <CircularProgress color='neutral' size='.8rem' />
            )}
          </Typography>
        </Stack>
      ))}
      <Stack direction='row' justifyContent='space-between' sx={{ mt: 1 }}>
        <Typography variant='body2' sx={textStylesVariants[styleVariant].total}>
          Total
        </Typography>
        <QuoteDivider isWhite={styleVariant === 'white'} />
        <Typography variant='body2' sx={textStylesVariants[styleVariant].total}>
          {isFetchedAfterMount ? (
            moneyFormat(Number(quote?.total))
          ) : (
            <CircularProgress color='secondary' size='.8rem' />
          )}
          {}
        </Typography>
      </Stack>
      {currentStep === bookingSteps.PAYMENT && (
        <Stack
          direction='column'
          justifyContent='space-between'
          sx={{ mt: 1, mb: 2 }}>
          {Object.keys(quoteKeys2).map((key) => (
            <Stack key={key} direction='row' justifyContent='space-between'>
              <Typography
                variant='body2'
                sx={textStylesVariants[styleVariant].text}>
                {quoteKeys2[key]}
              </Typography>
              <QuoteDivider isWhite={styleVariant === 'white'} />
              <Typography
                variant='body2'
                sx={textStylesVariants[styleVariant].text}>
                {isFetchedAfterMount ? (
                  moneyFormat(Number(quote[key]))
                ) : (
                  <CircularProgress color='neutral' size='.8rem' />
                )}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

QuoteSummary.propTypes = {
  isWhite: PropTypes.bool,
};

export default QuoteSummary;
