import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import Price from './Price';
import ProductButton from './ProductButton';
import DiscountBadge from './DiscountBadge';
// Components & Utils
import fallbackImage from '../../assets/others/fallbackProduct.svg';
// UI

/**
 * @function ProductCard
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const ProductCard = ({
  product,
  handleModalOpen,
  setCurrentProduct,
  cardWidth,
}) => {
  const { name, price, discount, description, pictureURL } = product;

  const onCardClick = () => {
    setCurrentProduct(product);
    handleModalOpen(true);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: { ...cardWidth },
        height: '100%',
        px: '12px',
        py: '12px',
        borderRadius: '16px',
        cursor: 'pointer',
        transition: 'background-color 150ms',
        ':hover': {
          backgroundColor: 'rgba(244, 244, 244, 0.2)',
        },
      }}>
      <Box sx={{ position: 'absolute', top: 0, right: 0, width: '100%' }}>
        <ProductButton cardWidth={cardWidth} {...product} />
      </Box>
      <Box onClick={onCardClick}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& img': {
              maxHeight: '150px',
              width: '100%',
              objectFit: 'contain',
            },
          }}>
          <img alt={name} src={pictureURL ? pictureURL : fallbackImage} />
        </Box>
        {discount > 0 && (
          <Box sx={{ mt: '-0.5em', zIndex: 5, position: 'relative' }}>
            <DiscountBadge {...{ price, discount }} />
          </Box>
        )}
        <Stack spacing='6px' sx={{ mt: '4px' }}>
          <Typography
            variant='body1'
            sx={{
              fontSize: '0.9em !important',
              fontWeight: 600,
            }}>
            {name}
          </Typography>
          <Price {...{ price, discount }} />
          <Typography
            sx={{
              fontSize: '0.85em !important',
              fontWeight: 300,
              whiteSpace: 'nowrap !important',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {description}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

ProductCard.defaultProps = {
  something: '',
};

ProductCard.propTypes = {
  something: PropTypes.string,
};

export default ProductCard;
