import React, { useContext, useEffect } from 'react';
// Components & Utils
import ShadowBackgroundTextBanner from '../components/faq/ShadowBackgroundTextBanner';
// UI
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { UIContext } from '../providers/UIProvider';
import useDocumentTitle from '../hooks/useDocumentTitle';

/**
 * @function Privacy
 * @author JPVARGAS59
 * @version 1.0
 * @description TermsAndContiions view
 * This component doesn't uses ViewContainer because of the desired effect of the FAQBanner
 * View contains a tabs container with all the questions in accordions
 * And a DownloadAppSection and Contact form
 * @returns {JSX}
 */
const Privacy = () => {
  useDocumentTitle('Oasis App - Aviso de privacidad');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setNavbarBackgroundColor } = useContext(UIContext);

  useEffect(() => {
    setNavbarBackgroundColor(theme.palette.lightBackground);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      sx={{
        bgcolor: (currTheme) => currTheme.palette.lightBackground,
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}>
      <ShadowBackgroundTextBanner
        text={`Aviso ${isMobile ? '\n' : ''}de privacidad`}
      />
      <Box
        sx={{
          bgcolor: (currTheme) => currTheme.palette.mainBackground,
        }}>
        <Box
          sx={{
            px: 3,
            maxWidth: 'lg',
            mx: 'auto',
            py: 8,
          }}>
          <Typography>
            <p>
              A los Usuarios (como se definen posteriormente), les informamos
              que el siguiente Aviso de Privacidad, les es aplicable por el
              simple uso o acceso a cualquiera de las páginas, aplicaciones web
              y móviles, softwares y, aplicaciones en general, que integran el
              Portal de OASISAPP.MX (en adelante y, conjunta e indistintamente,
              el "Portal"), por lo que entenderemos que lo acepta y acuerda en
              obligarse en su cumplimiento.{' '}
              <b>
                En caso de que no esté de acuerdo con el Aviso de Privacidad y/o
                con los Términos y Condiciones a su disposición, deberá
                abstenerse de acceder o utilizar el Portal.
              </b>
            </p>
            <p>
              El Usuario, entendido como aquella persona que realiza el uso o
              accede, mediante equipo de cómputo y/o cualquier equipo de
              comunicación o dispositivo, al Portal (en adelante el "Usuario"),
              acepta plena y sin reserva todas y cada una de las disposiciones
              incluidas en el presente Aviso de Privacidad.
            </p>
            <p>
              <b>RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES.</b> Para
              OASIS BY JOA S.A. DE C.V. (en adelante la "Empresa") la seguridad
              de los Usuarios es nuestra prioridad, por lo que protegemos sus
              datos personales mediante el uso, aplicación y mantenimiento de
              altas medidas de seguridad técnicas, físicas y administrativas.
            </p>
            <p>
              Como Usuario, usted tiene la oportunidad de escoger entre una
              amplia gama de productos y servicios a través de nuestro Portal,
              sabiendo que sus datos personales estarán protegidos y serán
              tratados de manera confidencial. Les informamos que el RESPONSABLE
              de recabar y dar tratamiento y/o utilizar los datos personales que
              el Usuario proporcione a través del Portal, es la Empresa, así
              como sus subsidiarias, asociadas, sociedades controladoras y
              afiliadas.
            </p>
            <p>
              <b>DOMICILIO DEL RESPONSABLE. </b> Para efectos del presente aviso
              de privacidad, la Empresa señala, individualmente, como su
              domicilio, el ubicado en Av. Santa Fe 505, piso 21, Cuajimalpa de
              Morelos, CDMX
            </p>
            <p>
              <b>DATOS PERSONALES QUE PUEDEN SER RECOLECTADOS.</b> Los datos
              personales que la Empresa puede recopilar del Usuario al utilizar
              el Portal y/o contratar nuestros servicios y productos, son los
              siguientes:
            </p>
            <ol>
              <li>Nombre</li>
              <li>Teléfono</li>
              <li>Correo electrónico</li>
              <li>Cuentas bancarias</li>
              <li>
                Documentos legales para comprobar la legítima propiedad de
                bienes inmuebles
              </li>
              <li>Cualquier otra información que comparta con Oasis</li>
            </ol>
            <p>
              Serán considerados como datos personales sensibles aquellos así
              dispuestos por la Ley Federal de Protección de Datos en Posesión
              de los Particulares, que de forma enunciativa más no limitativa,
              son los datos que forman parte de la intimidad del personal y cuya
              divulgación pone en riesgo o da origen a discriminación por
              aspectos de origen racial o étnico, estado de salud, información
              genética, creencias religiosas, filosóficas o morales, afiliación
              sindical, opiniones políticas y preferencias sexuales.
            </p>
            <p>
              <b>FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES.</b> Los
              datos personales que la Empresa recabe serán utilizados para
              atender las siguientes finalidades:
            </p>
            <ol>
              <li>
                Garantizar la calidad de nuestro servicio, Agilizar
                reservaciones, Comunicar y concretar eventos
              </li>
              <li>
                Para integrar expedientes, bases de datos y sistemas necesarios
                para llevar a cabo las operaciones y servicios correspondientes;
                (ii) Para reclamar la entrega de premios y/o promociones; (iii)
                Para llevar a cabo análisis internos;
              </li>
              <li>
                De manera adicional, se podrán utilizar sus datos personales
                para las siguientes finalidades secundarias: (i) Mercadotecnia,
                publicidad y prospección comercial; (ii) Ofrecerle, en su caso,
                otros productos y servicios propios de la Empresa o de
                cualquiera de sus afiliadas, subsidiarias, sociedades
                controladoras, asociadas, comisionistas o sociedades; (iii)
                Remitirle promociones de otros bienes, servicios y/o productos;
                (iv) Para realizar análisis estadísticos, de generación de
                modelos de información y/o perfiles de comportamiento actual y
                predictivo y, (v) Para participar en encuestas, sorteos y
                promociones.
              </li>
            </ol>
            <p>
              <b>
                OPCIONES Y MEDIOS PA RA LIMITAR EL USO O DIVULGACIÓN DE LOS
                DATOS.
              </b>{' '}
              La Empresa tiene implementadas medidas de seguridad
              administrativas, técnicas y físicas para proteger sus datos
              personales, mismas que igualmente exigimos sean cumplidas por los
              proveedores de servicios que contratamos. Usted podrá limitar el
              uso o divulgación de sus datos personales enviando un correo
              electrónico a soporte@oasisapp.mx indicándonos en el cuerpo del
              correo su nombre completo y que dato desea que no sea divulgado,
              de proceder su solicitud, se le registrará en el listado de
              exclusión.
            </p>
            <p>
              <b>
                MEDIOS PA RA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
                CANCELACIÓN U OPOSICIÓN (DERECHOS ARCO).
              </b>{' '}
              Puede enviar un correo electrónico a soporte@oasisapp.mx, en
              cualquier momento, para ejercer sus Derechos de Acceso,
              Rectificación, Cancelación u Oposición ("Derechos ARCO"). Para
              ejercer los Derechos ARCO, usted (o su representante legal),
              deberá presentar la solicitud, identificándose con la siguiente
              documentación:
            </p>
            <ol>
              <li>Nombre del usuario o titular</li>
              <li>
                Domicilio del usuario o titular u otro medio para comunicarle la
                respuesta a su solicitud.
              </li>
              <li>
                Documentos que acrediten su identidad (IFE/INE o pasaporte) y,
                en su caso, los documentos que acrediten la representación legal
                del solicitante.
              </li>
              <li>
                Una descripción de la información / los datos sobre los que está
                tratando de ejercer sus derechos ARCO y los derechos que le
                gustaría ejercer.
              </li>
            </ol>
            <p>
              Si uno o más de los documentos mencionados anteriormente no están
              incluidos, y/o los documentos tienen errores u omisiones, la
              Empresa le notificará dentro de los 3 días hábiles posteriores a
              la recepción de la solicitud y le pedirá los documentos faltantes
              y/o las correcciones pertinentes; tendrá 5 días hábiles a partir
              de esa notificación para proporcionar la información actualizada,
              de lo contrario, la solicitud se entenderá como no presentada.
            </p>
            <p>
              <b>TRANSFERENCIA DE DATOS PERSONALES. </b>La Empresa podrá
              divulgar sus datos personales a aquellos terceros que, en virtud
              de los servicios y productos ofrecidos, necesiten conocerlos para
              cumplir cabalmente con los mismos.
            </p>
            <p>
              Asimismo, la Empresa puede divulgar su información a las
              autoridades competentes en términos de la legislación aplicable;
              cualquier transferencia de sus datos personales sin consentimiento
              se realizará de acuerdo con el Artículo 37 de la LFPDPPP.
            </p>
            <p>
              <b>WEB BEACONS. </b>La Empresa, podrá o no, utilizar tecnologías
              de seguimiento tales como web beacons, similares a las cookies,
              para recabar datos sobre sus visitas en el Portal; éstas son
              pequeñas imágenes electrónicas incrustadas en el contenido web o
              mensajes de correo electrónico, las cuales no se encuentran
              normalmente visibles para los Usuarios y que nos permiten generar
              contenidos casi personalizados para ofrecerle una mejor
              experiencia cuando utilice nuestro Portal.
            </p>
            <p>
              En caso de no estar de acuerdo con cualquiera de las condiciones
              aquí establecidas, el Usuario siempre podrá cambiar la
              configuración de su navegador.
            </p>
            <p>
              <b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b> El presente aviso de
              privacidad puede sufrir modificaciones, cambios o actualizaciones
              derivadas, entre otras cosas, por nuevos requerimientos legales;
              necesidades propias de la Empresa, por los productos o servicios
              que ofrecemos; por nuestras prácticas de privacidad; por cambios
              en nuestro modelo de negocio, o por otras causas.
            </p>
            <p>
              Cualquier modificación al presente aviso de privacidad le será
              notificada a través de cualquiera de los siguientes medios: un
              comunicado por escrito enviado a su domicilio; por el correo
              electrónico que señale; un mensaje a su teléfono móvil; un mensaje
              dado a conocer a través del Portal o de cualquier medio
              electrónico que utilice para celebrar operaciones; o bien, en
              periódicos de amplia circulación el domicilio social de la
              Empresa.
            </p>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Privacy;
