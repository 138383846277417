import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useStripe } from '@stripe/react-stripe-js';

// Components
import ViewContainer from '../shared/ViewContainer';
import HalfCircle from '../shared/HalfCircle';

// UI
import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { logPurchase } from '../../firebase';
import { appStorage } from '../../utils/util';

const ProcessingPayment = () => {
  const stripe = useStripe();
  useDocumentTitle('Oasis App - Realizando pago');
  const [message, setMessage] = useState(null);
  const [redirectTimer, setRedirectTimer] = useState(null);
  const [title, setTitle] = useState('');
  const [displayBackButton, setDisplayBackButton] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();
  const boxID = appStorage.retrieve('boxID');
  const eventID = appStorage.retrieve('eventID');
  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );
    if (!clientSecret) {
      return;
    }
    let timer;
    let timeout;

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setisLoading(false);
      switch (paymentIntent.status) {
        case 'succeeded':
          setTitle('¡Felicidades!');
          setMessage('Tu pago se ha realizado correctamente. ');

          logPurchase(paymentIntent.amount, paymentIntent.id, boxID, eventID);
          setDisplayBackButton(false);
          setRedirectTimer(5);

          timeout = setTimeout(() => {
            navigate('/profile/bookings', { replace: true });
          }, 5000);
          timer = setInterval(() => {
            setRedirectTimer((c) => c - 1);
          }, 1000);
          break;

        case 'processing':
          setTitle('Seguimos procesando el pago');
          setMessage('Te avisaremos cuando el pago sea recibido.');
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setTitle('¡Oh no!');
          setMessage(
            'El pago ha fallado, por favor intente con otra tarjeta o asegúrate de estar usando tu tarjeta digital.'
          );
          setDisplayBackButton(true);
          break;

        default:
          setTitle('¡Oh no!');
          setMessage(
            'El pago no se pudo completar, por favor intente de nuevo. Asegúrate de estar usando tu tarjeta digital.'
          );
          setDisplayBackButton(true);
          break;
      }
    });
    return () => {
      if (timer) clearInterval(timeout);
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  return (
    <ViewContainer>
      <HalfCircle isLeftCircle left='-2em' top='200px' />
      <Stack
        justifyContent='center'
        alignItems='center'
        sx={{
          flexGrow: 1,
          maxWidth: 'lg',
          mx: 'auto',
          position: 'relative',
          zIndex: '2',
          minHeight: '80vh',
          mt: { xs: 10, md: 15 },
          px: 3,
          pb: 10,
        }}>
        <Stack>
          {isLoading ? (
            <Stack spacing={2} sx={{ my: 4 }}>
              <Typography variant='h1'>Realizando Pago</Typography>
              <LinearProgress variant='indeterminate' color='secondary' />
            </Stack>
          ) : (
            <Stack spacing={2} sx={{ mb: 8 }}>
              <Typography variant='h1'>{title}</Typography>
              <Typography variant='subtitle1'>{message}</Typography>
              {redirectTimer && (
                <Typography variant='body1'>{`Redirigiendo a tus reservas en... ${redirectTimer} segundos.`}</Typography>
              )}
            </Stack>
          )}
        </Stack>
        {!isLoading && (
          <Stack spacing={3}>
            {displayBackButton ? (
              <Button
                color='secondary'
                aria-label='Regresar'
                variant='contained'
                onClick={() => navigate(-1)}>
                Regresar a la reserva
              </Button>
            ) : (
              <>
                <Button
                  color='secondary'
                  variant='contained'
                  aria-label='Mis Reservas'
                  onClick={() =>
                    navigate('/profile/bookings', { replace: true })
                  }>
                  Ir a mis reservas
                </Button>
                <Button
                  color='secondary'
                  variant='outlined'
                  aria-label='Todos los eventos'
                  onClick={() => navigate('/events', { replace: true })}>
                  Regresar a todos los eventos
                </Button>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </ViewContainer>
  );
};

export default ProcessingPayment;
