import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Snackbar } from '@mui/material';
import { UIContext } from '../../providers/UIProvider';
// Components & Utils
// UI

/**
 * @function SackbarElement
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const SackbarElement = () => {
  const { isSnakBarOpened, toggleSnackbar, snackBarProps } =
    useContext(UIContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    toggleSnackbar(false);
  };
  return (
    <Snackbar
      sx={{zIndex: 2100, maxWidth: 500}}
      open={isSnakBarOpened}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={snackBarProps.severity}
        sx={{ width: '100%' }}>
        {snackBarProps.text}
      </Alert>
    </Snackbar>
  );
};

SackbarElement.defaultProps = {
  snackBarProps: {
    severity: 'info',
    text: '',
  },
};

SackbarElement.propTypes = {
  snackBarProps: PropTypes.shape({
    severity: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default SackbarElement;
