export const ProviderLoginType = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  ACCOUNT_VERIFICATION: 'ACCOUNT_VERIFICATION',
};

export const bookingStatusType = {
  CANCELED: 'canceled',
  ACCEPTED: 'accepted',
  PENDING: 'pending',
};

export const bookingSteps = {
  ADDRESS: 'address',
  TICKETS: 'tickets',
  FOOD_AND_BEVERAGES: 'food_and_beverages',
  PAYMENT: 'payment',
};

export const currencies = {
  MXN: 'MXN',
  USD: 'USD',
};
