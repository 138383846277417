import React, { useEffect, useState } from 'react';
// Components & Utils
import { useZIPCode } from '../../../hooks/requests';
// UI
import { Autocomplete, Stack, styled, TextField } from '@mui/material';

const InputText = styled((props) => (
  <TextField variant='outlined' color='secondary' required={true} {...props} />
))(
  ({ theme }) => `
  margin: 0;
  & .Mui-focused {
    & * svg {
      color: ${theme.palette.secondary.main};
    }
  }
  & .Mui-error {
  & * svg {
    color: ${theme.palette.error.main};
  }
`
);

/**
 * @function NewAddressControlled
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const NewAddressControlled = ({ address, setAddress }) => {
  const [neighborhoodAutocomplete, setNeighborhoodAutocomplete] = useState('');
  const [neighborhoodTextField, setNeighborhoodTextField] = useState('');
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([]);
  const [searchForZIPCode, setSearchForZIPCode] = useState(false);

  const updateValue = (key, value) => {
    setAddress((currentAddress) => ({
      ...currentAddress,
      [key]: value,
    }));
  };

  useZIPCode(address?.zip, {
    enabled: searchForZIPCode,
    onError: () => {
      setSearchForZIPCode(false);
    },
    onSuccess: (data) => {
      setNeighborhoodsOptions(data.asentamiento);
      setAddress((currentAddress) => ({
        ...currentAddress,
        city: data.municipio || data.ciudad,
        state: data.estado,
        country: data.pais || 'México',
        neighborhood: data.asentamiento[0],
      }));
      setNeighborhoodAutocomplete(data.asentamiento[0]);
      setNeighborhoodTextField(data.asentamiento[0]);
      setSearchForZIPCode(false);
    },
    retry: false,
  });

  // On valid zipcode, trigger get copomex data
  useEffect(() => {
    if (address?.zip?.length === 5) {
      setSearchForZIPCode(true);
    } else {
      setSearchForZIPCode(false);
    }
  }, [address?.zip]);

  useEffect(() => {
    updateValue(
      'neighborhood',
      neighborhoodTextField || neighborhoodAutocomplete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [neighborhoodAutocomplete, neighborhoodTextField]);

  return (
    <>
      <Stack spacing={2}>
        <InputText
          label='Calle'
          value={address?.street || ''}
          onChange={(e) => updateValue('street', e.target.value)}
        />
        <Stack direction='row' spacing={2}>
          <InputText
            fullWidth
            label='Número exterior'
            value={address?.extNumber || ''}
            onChange={(e) => updateValue('extNumber', e.target.value)}
          />
          <InputText
            fullWidth
            required={false}
            label='Número interior'
            value={address?.intNumber || ''}
            onChange={(e) => updateValue('intNumber', e.target.value)}
          />
        </Stack>
        <InputText
          label='Código Postal'
          value={address?.zip || ''}
          onChange={(e) => updateValue('zip', e.target.value)}
        />
        <Stack spacing={2} sx={{ mb: 3 }}>
          <Autocomplete
            color='secondary'
            freeSolo
            options={neighborhoodsOptions}
            ListboxProps={{
              sx: { backgroundColor: '#2D1E5A' },
            }}
            value={neighborhoodAutocomplete}
            onChange={(_, newValue) => {
              setNeighborhoodAutocomplete(newValue);
            }}
            inputValue={neighborhoodTextField}
            onInputChange={(_, newInputValue) => {
              setNeighborhoodTextField(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                color='secondary'
                required
                sx={{ display: 'block', width: 'unset', m: 0 }}
                {...params}
                label='Colonia'
              />
            )}
            isOptionEqualToValue={(option, value) => option === value}
          />
          <InputText
            InputLabelProps={{ shrink: true }}
            label='Ciudad'
            value={address?.city || ''}
            onChange={(e) => updateValue('city', e.target.value)}
          />
          <InputText
            InputLabelProps={{ shrink: true }}
            value={address?.state || ''}
            onChange={(e) => updateValue('state', e.target.value)}
            label='Estado'
          />
        </Stack>
      </Stack>
    </>
  );
};

export default NewAddressControlled;
