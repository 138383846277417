import React from 'react';
// UI
import { Box, Grid, useTheme, Typography, useMediaQuery } from '@mui/material';
import BackgroundImage from '../../assets/heros/back-home.jpg';
//import SeatImage from '../../assets/landingAssets/Seat.webp';
// Components & Utils
import SocialMedia from '../shared/SocialMedia';
// import AppsStores from '../shared/AppsStores';
import { CTA } from '../landing/CTA';

const LandingTitle = ({ isMobile = false }) => (
  <>
    <Typography
      variant='h1'
      gutterBottom
      sx={{
        fontSize: { xs: '30px', md: '80px' },
        filter: 'drop-shadow(0px 0px 16px black)',
        textAlign: 'center'
      }}>
      Encuentra un palco para los<br></br> mejores eventos 
    </Typography>
    {/*<Typography
      variant='h2'
      gutterBottom
      sx={{
        filter: 'drop-shadow(0px 0px 16px black)',
        ...(isMobile && { fontSize: '1.4rem', mb: 4 }),
      }}>
      Nosotros te ayudamos
    </Typography>

    <Typography
      variant={isMobile ? 'body2' : 'body1'}
      sx={{
        mb: 4,
      }}>
      {`Renta o registra un palco mediante nuestra app. \n¡Hagamos juntos la plataforma más grande del mundo de renta de palcos! `}
    </Typography>*/}
    <CTA isFullWidth={isMobile} />
  </>
);

/**
 * @function HeroBanner
 * @author RCH010
 * @version 1.0
 * @description Banner used on landing.
 * @returns {JSX}
 */
const HeroBanner = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  //const isMedium = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Box
        {...props}
        sx={{
          minHeight: isMobile ? '22em' : '40em',
          height: isMobile ? '50vh' : '85vh',
          width: '100%',
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'scroll',
          backgroundPosition: 'center bottom',
          backgroundSize: 'cover',
          position: 'relative',
          zIndex: 1,
          marginTop: '4.5em'
        }}>
        <Box
          sx={{
            mx: 'auto',
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: isMobile ? 'center' : 'none',
            maxWidth: theme.breakpoints.values.lg,
            px: 3,
          }}>
          <Grid
            container
            direction={isMobile ? 'column' : 'row'}
            justifyContent='space-between'
            alignItems={isMobile ? 'center' : 'flex-end'}
            sx={{ mb: isMobile ? 0 : 2 }}
            spacing={isMobile ? 5 : 0}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  textAlign: 'center',
                  //display: 'flex',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  //justifyContent: 'center',
                  //flexDirection: 'column',
                  px: isMobile ? 4 : 0,
                  //mb: 10,
                }}>
                <LandingTitle />
              </Box>
            </Grid>
            {/*<Grid item md={4}>
              <Box
                sx={{
                  ml: isMedium ? '-2em' : '-5em',
                  maxWidth: isMobile ? '15em' : 'unset',
                  mb: isMobile ? '-8em' : '-5em',
                }}>
                <img
                  loading='eager'
                  draggable={false}
                  alt=''
                  src={SeatImage}
                  style={{
                    width: '100%',
                  }}
                />
              </Box>
            </Grid>*/}
            <Box sx={{ ml: 'auto' }}>{!isMobile && <SocialMedia />}</Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default HeroBanner;
