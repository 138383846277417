import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

// UI
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams } from 'react-router-dom';
import { logSearch } from '../../firebase';

/**
 * @function SearchBar
 * @author RCH010
 * @version 1.0
 * @description renders a search bar and a selector.
 * @param {Boolean} displayCategoriesSelector sif true, tha categories selector will appear
 * @returns {JSX}
 */
const SearchBar = ({ displayCategoriesSelector }) => {
  const theme = useTheme();
  const formRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const searchEvetQuery = searchParams.get('search_event');
  const { register, handleSubmit } = useForm({
    defaultValues: {
      searchQuery: searchEvetQuery ? searchEvetQuery : '',
    },
  });

  const onSearchSubmit = (values) => {
    if (values.searchQuery) {
      // Log search on google analytics
      logSearch(values.searchQuery)
    }
    setSearchParams({ search_event: values.searchQuery });
  };

  return (
    <Box
      ref={formRef}
      onSubmit={handleSubmit(onSearchSubmit)}
      component='form'
      sx={{
        bgcolor: 'common.white',
        borderRadius: 2,
        position: 'relative',
        zIndex: 2,
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <InputBase
          {...register('searchQuery')}
          sx={{ ml: 2, flex: 2, color: 'grey.800', bgcolor: 'common.white' }}
          placeholder='Busca aquí un evento'
          inputProps={{ 'aria-label': 'search event' }}
        />
        <IconButton type='submit' aria-label='search' sx={{ mr: 1, my: 0.5 }}>
          <SearchIcon sx={{ color: 'secondary.main' }} />
        </IconButton>
        {displayCategoriesSelector && !isMobile && (
          <>
            <Divider
              flexItem
              sx={{ my: 1.5, mx: 0.5, color: 'grey.500' }}
              orientation='vertical'
            />
            <FormControl
              color='secondary'
              sx={{
                flex: 1,
                '& .MuiInputLabel-root': { color: 'grey.600' },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '& .MuiOutlinedInput-root': {
                  borderColor: 'grey.600',
                },
                '& svg': {
                  color: 'common.white',
                  borderRadius: '50%',
                  backgroundColor: 'secondary.main',
                },
              }}>
              <Select
                {...register('category')}
                sx={{ color: 'grey.800' }}
                color='secondary'
                labelId='category'
                defaultValue={'allCategories'}
                id='category-select'>
                <MenuItem value={'allCategories'}>
                  Todas las categorias
                </MenuItem>
                <MenuItem value={'1'}>Futball</MenuItem>
                <MenuItem value={'2'}>Baseball</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </Box>
    </Box>
  );
};

SearchBar.defaultProps = {
  displayCategoriesSelector: false,
};

SearchBar.propTypes = {
  displayCategoriesSelector: PropTypes.bool,
};

export default SearchBar;
