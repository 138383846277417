import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Paper, Skeleton } from '@mui/material';
import useProgressiveImg from '../../hooks/useProgressiveImage';

/**
 * @function ImageLazyLoad
 * @author RCH010
 * @version 1.0
 * @description Lazy load an image (srcHighQuality)
 * First an skeleton is shown, as the Low an High quality images load
 * Then the low is placed with a blur effect and finally the high quality image
 * In case the high one loads first, it is set and the low one is skipped
 * @param {String} srcLowQuality path of low quality image
 * @param {String} srcHighQuality path of high quality image
 * @param {String} altText alt text of image
 * @param {Object} imageStyles
 * @param {Object} containerStyles
 * @param {String} objectFitImage
 * @returns {JSX}
 */
const ImageLazyLoad = ({
  srcLowQuality,
  srcHighQuality,
  altText,
  imageStyles,
  containerStyles,
  objectFitImage,
}) => {
  const [src, { blur }] = useProgressiveImg(srcLowQuality, srcHighQuality);

  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: 'transparent',
        ...containerStyles,
      }}>
      {!src && (
        <Skeleton
          width='100%'
          height='100%'
          variant='rectangular'
          sx={{ position: 'absolute' }}
        />
      )}
      <Fade timeout={500} in={Boolean(src)}>
        <img
          draggable={false}
          src={src}
          alt={altText}
          style={{
            position: 'relative',
            filter: blur ? 'blur(5px)' : 'none',
            transition: blur ? 'none' : 'filter 0.8s ease-in-out',
            width: '100%',
            height: '100%',
            display: 'block',
            objectFit: objectFitImage,
            ...imageStyles,
          }}
        />
      </Fade>
    </Paper>
  );
};

ImageLazyLoad.defaultProps = {
  imageStyles: {},
  containerStyles: {},
  objectFitImage: 'contain',
};

ImageLazyLoad.propTypes = {
  srcLowQuality: PropTypes.string,
  srcHighQuality: PropTypes.string,
  altText: PropTypes.string,
  imageStyles: PropTypes.object,
  containerStyles: PropTypes.object,
};

export default ImageLazyLoad;
