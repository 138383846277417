import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// UI
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import {
  IconButton,
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
  Button,
} from '@mui/material';
import Logo from '../../assets/logo/Logo.svg';
import LogoMobile from '../../assets/logo/LogoMobile.svg';
import PersonIcon from '@mui/icons-material/Person';
// Components & Utils
import DrawerMenu from './DrawerMenu';
import { navbarOptions } from '../../utils/menuOptions';
import { UIContext } from '../../providers/UIProvider';
import { menuOptions } from '../../utils/menuOptions';

export const CTAVariants = {
  loginCTA: {
    variant: 'outlined',
    children: 'Iniciar Sesión',
    startIcon: <PersonIcon />,
    path: menuOptions.LOGIN.path,
  },
  bookCTA: {
    variant: 'contained',
    children: 'Rentar un palco',
    path: menuOptions.EVENTS.path,
  },
  profileCTA: {
    variant: 'contained',
    children: 'mi cuenta',
    startIcon: <PersonIcon />,
    path: menuOptions.PROFILE.path,
  },
};

/**
 * @function Navbar
 * @author RCH010
 * @version 1.0
 * @description Navbar component, which is rendered on Layout
 * For views up 'md' breakpoint the menu is shown on the bar, in other cases
 * the menu is shown with the DrawerMenu component.
 * @returns {JSX}
 */
const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerMenuOpen, setIsDrawerMenuOpen] = useState(false);
  const navigate = useNavigate();
  const {
    navbarBackgroundColor,
    currentTab,
    CTAVariant = 'bookCTA',
  } = useContext(UIContext);

  const toggleDrawer = (toggle = false) => {
    setIsDrawerMenuOpen(toggle);
  };

  return (
    <Box
      bgcolor={navbarBackgroundColor}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2050,
        transition: 'background-color 300ms ease-in-out',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          px: 3,
          py: 1,
          maxWidth: theme.breakpoints.values.lg,
        }}>
        <div>
          <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
            <img alt='Oasis' src={isMobile ? LogoMobile : Logo} />
          </Box>
        </div>
        <div>
          <nav>
            {isMobile ? ( // Render button for drawer menu
              <IconButton
                color='secondary'
                aria-label='menu'
                size='small'
                variant='outlined'
                onClick={() => setIsDrawerMenuOpen(true)}>
                <MenuIcon fontSize='large' />
              </IconButton>
            ) : (
              // Render options for desktop
              <>
                <List
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  {navbarOptions.map((option) => (
                    <li>
                      <Link
                        key={option.id}
                        to={`${option.path}`}
                        style={{ color: 'inherit', textDecoration: 'none' }}>
                        <ListItemButton
                          key={option.id}
                          sx={{
                            borderRadius: 8,
                            transition: 'background-color 200ms ease-in-out',
                            ':hover': {
                              backgroundColor: theme.palette.darkerBackground,
                            },
                          }}>
                          <ListItemText>
                            <Typography
                              align='center'
                              variant='body2'
                              sx={{
                                color:
                                currentTab === option.id ? '#FF0' : 'inherit',
                              }}>
                              {option.label}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Button
                      as={Link}
                      to={CTAVariants[CTAVariant].path}
                      aria-label='CTA Variants'
                      color='secondary'
                      sx={{
                        ml: 1,
                        textDecoration: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      {...CTAVariants[CTAVariant]}
                    />
                  </li>
                </List>
              </>
            )}
          </nav>
        </div>
        <DrawerMenu openMenu={isDrawerMenuOpen} toggleDrawer={toggleDrawer} />
      </Box>
    </Box>
  );
};

export default Navbar;
