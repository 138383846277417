import React from 'react';
import { Link } from 'react-router-dom';

// Component and utils
import ProvidersButtons from '../shared/ProvidersButtons';
import { menuOptions } from '../../utils/menuOptions';
import { ProviderLoginType } from '../../utils/enums';

// UI
import { Divider, Link as MUILink, Stack, Typography } from '@mui/material';

const MUILinkStyles = {
  sx: { color: (t) => t.palette.common.white, width: '100%' },
  variant: 'body2',
  underline: 'hover',
  component: Link,
};

/**
 * @function AuthAlternativeButtons
 * @param {String} type
 * @returns {JSX}
 */
const AuthAlternativeButtons = ({ type = ProviderLoginType.LOGIN }) => {
  return (
    <>
      {type === ProviderLoginType.SIGNUP ? (
        <Stack>
          <Typography variant='body2' align='center'>
            ¿Ya tienes cuenta?
          </Typography>
          <MUILink
            {...MUILinkStyles}
            align='center'
            to={menuOptions.LOGIN.path}>
            Inicia sesión
          </MUILink>
        </Stack>
      ) : (
        <Stack>
          <Typography variant='body2' align='center'>
            ¿Aún no tienes cuenta?
          </Typography>
          <MUILink
            {...MUILinkStyles}
            align='center'
            to={menuOptions.SIGNUP.path}>
            Crea una cuenta
          </MUILink>
        </Stack>
      )}
      <Stack direction='column' spacing={1.5} sx={{ width: '100%' }}>
        <Divider
          flexItem
          sx={{
            '&::before, &::after': {
              borderColor: (t) => t.palette.grey[500],
            },
          }}>
          o inicia con
        </Divider>
        <ProvidersButtons />
      </Stack>
    </>
  );
};

export default AuthAlternativeButtons;
