import patrocinadorOasis from '../assets/sponsors/patrocinadoresOasis.png';
import patrocinioDos from '../assets/sponsors/2.png';
import patrocinioTres from '../assets/sponsors/3.png';
import patrocinioCuatro from '../assets/sponsors/4.png';

const width = '100%';

export const sponsorsData = [
  {
    image: patrocinioCuatro,
    alt: 'Anunciate Aquí',
    link: 'mailto:contacto@oasisapp.mx',
    widthImg: width,
  },
  {
    image: patrocinadorOasis,
    alt: 'Oaseas',
    link: 'https://oaseas.mx/',
    widthImg: width,
  },
  {
    image: patrocinioDos,
    alt: 'Oaseas',
    link: 'https://oaseas.mx/destino/1',
    widthImg: width,
  },
  {
    image: patrocinioTres,
    alt: 'Oaseas',
    link: 'https://oaseas.mx/destino/2',
    widthImg: width,
  }
];