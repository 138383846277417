import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

/**
 * @function CarouselSponsors
 * @author RCH010
 * @version 1.0
 * @description Generic Carousel component.
 * @param {String} something short description of prop
 * @param {Array} carouselItems
 * @param {node} Item
 * @param {Object} responsive
 * @param {Boolean} swipeable
 * @param {Boolean} infinite
 * @param {Boolean} autoPlay
 * @param {Number} autoPlaySpeed
 * @param {Number} transitionDuration
 * @returns {JSX}
 */
const CarouselSponsors = ({
  carouselItems,
  Item,
  responsive,
  arrow,
  swipeable,
  infinite,
  autoPlay,
  autoPlaySpeed,
  transitionDuration,
}) => {

  // Estado para almacenar las imágenes aleatorias
  const [shuffledImages, setShuffledImages] = useState([]);

  // Efecto que mezcla las imágenes al montar el componente
  useEffect(() => {
    const shuffleImages = () => {
      const shuffled = [...carouselItems].sort(() => Math.random() - 0.5); // Baraja el arreglo
      setShuffledImages(shuffled); // Actualiza el estado con las imágenes aleatorias
    };

    shuffleImages(); // Llama a la función al montar el componente
  }, [carouselItems]); // Solo se ejecuta una vez al cargar el componente
  
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
        '& .react-multiple-carousel__arrow': {
          transition: 'background-color 200ms, filter 200ms, color 200ms',
          backgroundColor: (currTheme) => currTheme.palette.secondary.main,
          '&:hover': {
            filter: (currTheme) =>
              `drop-shadow(0.2em 0.2em .3em ${currTheme.palette.shadowGreen})`,
            backgroundColor: (currTheme) => currTheme.palette.secondary.dark,
            '&::before': {
              color: (currTheme) => currTheme.palette.grey[900],
            },
          },
          '&::before': {
            color: (currTheme) => currTheme.palette.secondary.contrastText,
            fontSize: '1.5em',
            fontWeight: 'bold',
          },
        },
        '& .react-multiple-carousel__arrow--left': {
          left: 'calc(1% + 1px)',
        },
        '& .react-multiple-carousel__arrow--right': {
          right: 'calc(1% + 1px)',
        },
        '& .react-multi-carousel-dot button': {
          backgroundColor: 'white',
          borderColor: 'white',
          width: '8px',
          height: '8px',
          pointerEvents: 'none'
        },
        '& .react-multi-carousel-dot--active button': {
          backgroundColor: '#26d07c',
          borderColor: '#26d07c',
          width: '8px',
          height: '8px',
          pointerEvents: 'none'
        }
      }}>
      <MultiCarousel
        partialVisible={true}
        swipeable={swipeable}
        draggable={false}
        arrows={arrow}
        showDots={true}
        responsive={responsive}
        infinite={infinite}
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        keyBoardControl={true}
        transitionDuration={transitionDuration}>
        {shuffledImages.map((item) => (
          <Item key={item.id} {...item} />
        ))}
      </MultiCarousel>
    </Box>
  );
};

CarouselSponsors.defaultProps = {
  carouselItems: [],
  Item: <></>,
  responsive: {},
  arrow: true,
  swipeable: true,
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 3000,
  transitionDuration: 500,
};

CarouselSponsors.propTypes = {
  carouselItems: PropTypes.array,
  Item: PropTypes.func,
  responsive: PropTypes.object,
  arrow: PropTypes.bool,
  swipeable: PropTypes.bool,
  infinite: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPlaySpeed: PropTypes.number,
  transitionDuration: PropTypes.number,
};

export default CarouselSponsors;