import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Reaptcha from 'reaptcha';
// Components
import TextInput from '../inputs/TextInput';
import { SignUpContext } from '../../providers/SignUpProvider';
import { AuthContext } from '../../providers/AuthProvider';
import TwoButtons from '../shared/buttons/TwoButtons';

// UI
import { Alert, Box, Collapse, Stack, Typography } from '@mui/material';
import { logSignUp } from '../../firebase';
const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;


export const SignUpSecondStep = () => {
  const { updateStep, signUpData, setSignUpData } = useContext(SignUpContext);
  const { signUp } = useContext(AuthContext);
  const captcha = useRef(null);
  const [isError, setIsError] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues: signUpData });

  const onSubmitForm = async (values) => {
    const newSignUpData = { ...signUpData, ...values };
    // ESTO ES UN PARCHE DLV, ESTA SUPER DESORDENADO ESTO
    try {
      const signUpResponse = await signUp(newSignUpData);
      if (signUpResponse.success === false) {
        setIsError(true);
        setErrorMessage(
          signUpResponse?.message ||
            'Tuvimos un problema creando tu cuenta, por favor intenta más tarde'
        );
        return;
      }
      // IS SUCCESSFULL
      logSignUp('email')
      setSignUpData(newSignUpData);
      updateStep();
    } catch (error) {
      setIsError(true);
      setErrorMessage(
        error?.message ||
          'Tuvimos un problema creando tu cuenta, por favor intenta más tarde'
      );
    }
  };

  const onBackButtonPress = () => {
    updateStep(-1);
  };
  const onVerify = async (recaptchaResponse) => {
    setCaptchaResponse(recaptchaResponse);
  };


  return (
    <Stack spacing={2} component='form' onSubmit={handleSubmit(onSubmitForm)}>
      <Typography variant='h4' sx={{ mb: 3 }}>
        Información de la cuenta
      </Typography>
      <Collapse in={isError}>
        <Alert severity='error' variant='outlined'>
          {errorMessage}
        </Alert>
      </Collapse>
      <TextInput
        autoFocus
        inputVariant='email'
        register={register}
        required='Por favor ingrese su correo.'
        registerName='email'
        label='Correo electrónico'
        placeholder='ejemplo@gmail.com'
        error={Boolean(errors.email && touchedFields.email)}
        helperText={
          Boolean(errors.email && touchedFields.email) && errors.email.message
        }
      />
      <TextInput
        inputVariant='password'
        register={register}
        required={true}
        registerName='password'
        label='Contraseña'
        error={Boolean(errors.password && touchedFields.password)}
        helperText={
          Boolean(errors.password && touchedFields.password) &&
          errors.password.message
        }
      />
      <TextInput
        inputVariant='password'
        register={register}
        required={true}
        registerName='passwordConfirmation'
        label='Validar contraseña'
        error={Boolean(
          errors.passwordConfirmation && touchedFields.passwordConfirmation
        )}
      />
      <Box sx={{my:2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
        <Reaptcha
          ref={captcha}
          sitekey={reCaptchaKey}
          onVerify={onVerify}
          onExpire={() => setCaptchaResponse(null)}
          onError={() => setCaptchaResponse(null)}
          size='normal'
          theme='dark'
        />
      </Box>
      <Stack alignItems='flex-end' sx={{ py: 2 }}>
        <TwoButtons
          primary={{
            type: 'submit',
            label: 'Siguiente',
            isFullWidth: true,
            isDisabled: !isValid ||  !captchaResponse,
          }}
          secondary={{
            label: 'Atrás',
            onClick: onBackButtonPress,
          }}
          containerStyles={{
            width: '100%',
          }}
        />
      </Stack>
    </Stack>
  );
};
