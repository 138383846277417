import React from 'react';
// Components & Utils
import { CheckoutProvider } from '../providers/CheckoutProvider';
import ViewContainer from '../components/shared/ViewContainer';
import CheckoutProcess from '../components/checkout/CheckoutProcess';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { Box, useMediaQuery } from '@mui/material';

/**
 * @function Booking
 * @author RCH010
 * @version 1.0
 * @description Booking view
 * @returns {JSX}
 */
const Booking = () => {
  useDocumentTitle('Oasis App - Checkout');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <CheckoutProvider>
      <ViewContainer isDarkBackground overflowHidden={false}>
        <Box
          sx={{
            minHeight: '100vh',
            // Half circle
            // This is set trough css so no prop 'overflow: clip' is required
            // With this we can have a sticky element on checkoutProcess
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='934' height='1870' viewBox='0 0 934 1870' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.00126648 934.501C0.00126648 418.242 418.182 0 933.896 0V315.79C933.896 315.79 902.411 310.726 859.791 315.79C779.993 325.267 704.836 349.849 637.227 386.631C601.945 405.811 568.71 428.286 537.985 453.692C522.612 466.376 507.85 479.797 493.762 493.913C423.37 564.372 369.742 651.629 339.438 749.204C321.239 807.74 311.455 869.971 311.455 934.501C311.455 998.989 321.239 1061.2 339.438 1119.67C411.139 1350.36 613.269 1523.6 859.497 1552.96C902.201 1558.04 933.896 1560.5 933.896 1560.5V1869C418.182 1869 0.00126648 1450.55 0.00126648 934.501Z' fill='url(%23paint0_linear_921_10217)' fill-opacity='0.3'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_921_10217' x1='590.011' y1='42.7116' x2='586.33' y2='1615.68' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23231547'/%3E%3Cstop offset='1' stop-color='%235432A8'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right -2rem top',
            backgroundSize: isMobile ? 'cover' : '70%',
          }}>
          <CheckoutProcess />
        </Box>
      </ViewContainer>
    </CheckoutProvider>
  );
};

export default Booking;
