import React from 'react';
import PropTypes from 'prop-types';

// Components & Utils
import LoginForm from '../../components/auth/LoginForm';
import SignUpForm from '../../components/auth/SignUpForm';
import ForgotPassword from '../../components/auth/ForgotPassword';
import { ProviderLoginType } from '../../utils/enums';
import SignUpProvider from '../../providers/SignUpProvider';

/**
 * @function AuthType
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const AuthType = ({ type }) => {
  switch (type) {
    case ProviderLoginType.LOGIN:
      return <LoginForm />;
    case ProviderLoginType.SIGNUP:
      return (
        <SignUpProvider>
          <SignUpForm />
        </SignUpProvider>
      );
    case ProviderLoginType.FORGOT_PASSWORD:
      return <ForgotPassword />;
    default:
      return <LoginForm />;
  }
};

AuthType.defaultProps = {
  type: ProviderLoginType.LOGIN,
};

AuthType.propTypes = {
  type: PropTypes.string,
};

export default AuthType;
