import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { menuOptions } from '../utils/menuOptions';
import { oasisTheme } from '../utils/theme';
import { AuthContext } from './AuthProvider';
import { useLocation } from 'react-router-dom';

const UIContextDefaultValues = {
  currentTab: null,
  setCurrentTab: () => {},
};

export const UIContext = createContext(UIContextDefaultValues);

export const UIProvider = ({ children }) => {
  const [currentTab, setCurrentTab] = useState(null);
  const [CTAVariant, setCTAVariant] = useState('loginCTA');
  const [navbarBackgroundColor, setNavbarBackgroundColor] = useState(
    oasisTheme.palette.lightBackground
  );
  const [landingReferences, setLandingReferences] = useState({
    [menuOptions.HOWITWORKS.id]: null,
    [menuOptions.BOOKING.id]: null,
    [menuOptions.ENTAILMENT.id]: null,
  });
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});

  const [isSnakBarOpened, setIsSnakBarOpened] = useState(false);
  const [snackBarProps, setSnackBarProps] = useState({});

  const { auth } = useContext(AuthContext);
  const location = useLocation();

  const toggleAlertDialog = (value = null) => {
    if (value !== null) {
      setIsAlertDialogOpen(value);
      return;
    }
    setIsAlertDialogOpen((currentState) => !currentState);
  };

  const toggleSnackbar = (value = null) => {
    if (value !== null) {
      setIsSnakBarOpened(value);
      return;
    }
    setIsSnakBarOpened((currentState) => !currentState);
  };


  const updateAlertDialogProps = (props) => {
    setAlertDialogProps((currentProps) => ({
      ...currentProps,
      ...props,
    }));
  };

  const updateSnackbarProps = (props) => {
    setSnackBarProps((currentProps) => ({
      ...currentProps,
      ...props,
    }));
  };

  useEffect(() => {
    if (auth.isLoggedIn && !auth.isLoading) {
      setCTAVariant('bookCTA');
    }
  }, [auth]);

  useEffect(() => {
    // TODO: Debe haber una mejor manera de reconocer en donde mostrar que CTA
    if (
      ![
        menuOptions.LANDING.path,
        menuOptions.FORMULA1.path,
        menuOptions.FAQ.path,
        menuOptions.HOWITWORKS.path,
        menuOptions.PROFILE.path,
      ].includes(location.pathname)
    ) {
      setCTAVariant('profileCTA');
    }
  }, [location]);

  return (
    <UIContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        navbarBackgroundColor,
        setNavbarBackgroundColor,
        landingReferences,
        setLandingReferences,
        CTAVariant,
        setCTAVariant,

        isAlertDialogOpen,
        toggleAlertDialog,
        alertDialogProps,
        updateAlertDialogProps,

        isSnakBarOpened,
        toggleSnackbar,
        snackBarProps,
        updateSnackbarProps,
      }}>
      {children}
    </UIContext.Provider>
  );
};

UIProvider.propTypes = {
  children: PropTypes.any,
};
