import React, { useContext } from 'react';

// Compontes & utils
import SignUpFirstStep from './SignUpStep1';
import { SignUpSecondStep } from './SignUpStep2';
import SignUpThirdStep from './SignUpStep3';

// UI
import { Stack, Typography } from '@mui/material';
import { SignUpContext } from '../../providers/SignUpProvider';
import useDocumentTitle from '../../hooks/useDocumentTitle';

/**
 * @function SignUpForm
 *
 * @returns {React.ReactNode}
 */
const SignUpForm = () => {
  useDocumentTitle('Oasis App - Crear cuenta');
  const { step } = useContext(SignUpContext);

  const StepForm = ({ stepNumber }) => {
    switch (stepNumber) {
      case 1:
        return <SignUpFirstStep />;
      case 2:
        return <SignUpSecondStep />;
      case 3:
        return <SignUpThirdStep />;
      default:
        return <SignUpFirstStep />;
    }
  };

  return (
    <Stack direction='column' sx={{ width: '100%' }}>
      <Typography variant='h1' sx={{ mb: 4 }}>
        Regístrate
      </Typography>
      <StepForm stepNumber={step} />
    </Stack>
  );
};

export default SignUpForm;
