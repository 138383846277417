import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  alpha,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/**
 * @function QuestionsAccordion
 * @author RCH010
 * @version 1.0
 * @description Renders n accordions, one for each question.
 * Accordion is used as a controlled component.
 * @param {Array<Object>} questions array of questions from faq.json
 * @returns {JSX}
 */
const QuestionsAccordion = ({ questions }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState({});

  const handleAccordionChange = (id) => (event, isExpanded) => {
    setIsAccordionOpen((currentObject) => {
      const newValue = !currentObject[id];
      return {
        ...currentObject,
        [id]: newValue,
      };
    });
  };

  return (
    <>
      {questions.map((question) => (
        <Accordion
          expanded={isAccordionOpen.id}
          onChange={handleAccordionChange(question.id)}
          elevation={0}
          disableGutters
          key={question.id}
          sx={{
            border: 'none',
            my: 1,
            '&:before': {
              // Divider
              bgcolor: (currTheme) => currTheme.palette.grey[800],
              height: '1.2px',
            },
          }}>
          <AccordionSummary
            expandIcon={
              <ExpandLessIcon
                sx={{ fontSize: '2rem', color: 'secondary.main' }}
              />
            }
            aria-controls='panel1d-content'
            id='panel1d-header'
            sx={{
              bgcolor: (theme) =>
                isAccordionOpen[question.id]
                  ? alpha(theme.palette.darkerBackground, 0.1)
                  : 'transparent',
              flexDirection: 'row-reverse',
              transition: 'background-color 200ms ease-in-out',
              ':hover': {
                bgcolor: (currTheme) => currTheme.palette.lightBackground,
              },
            }}>
            <Typography variant='body1' sx={{ ml: 1, fontWeight: '500' }}>
              {question.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: (theme) => alpha(theme.palette.darkerBackground, 0.1),
            }}>
            <Typography sx={{ pl: '2em' }} variant='body1'>
              {question.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

QuestionsAccordion.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default QuestionsAccordion;
