import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

/**
 * @function ShadowBackgroundTextBanner
 * @author RCH010
 * @version 1.0
 * @description Banner that displays given text twice, one as a regular h1, and the
 * other as big as possible, with a big transparency * op
 * @returns {JSX}
 */
const ShadowBackgroundTextBanner = ({ text }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        zIndex: 1,
        position: 'relative',
        maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
        mx: 'auto',
        // mt: { xs: 10, md: 1 },
        px: 3,
      }}>
      <Box
        sx={{
          position: 'relative',
          bgcolor: (currTheme) => currTheme.palette.lightBackground,
          width: '100%',
          height: { xs: '10em', md: '14em' },
        }}>
        <Typography
          sx={{
            fontSize: {
              xs: '6rem !important',
              lg: '7rem !important',
            },
            position: 'absolute',
            bottom: -15,
            whiteSpace: isMobile ? 'pre-wrap' : 'nowrap',
            fontWeight: '900',
            fontFamily: 'MuseoModerno',
            color: 'rgba(255,255,255,0.04)',
            userSelect: 'none',
            lineHeight: 1,
          }}>
          {text}
        </Typography>
        <Typography
          variant='h1'
          sx={{
            position: 'absolute',
            bottom: 0,
            userSelect: 'none',
          }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

ShadowBackgroundTextBanner.defaultProps = {
  text: '',
};

ShadowBackgroundTextBanner.propTypes = {
  text: PropTypes.string,
};

export default ShadowBackgroundTextBanner;
