import { useQuery } from 'react-query';
import {
  getAllEvents,
  getBoxEvent,
  getEvent,
  getEventBoxes,
  getEvents,
} from '../api/events';
import {
  getAddress,
  getAddresses,
  getCopomexData,
  getProfile,
} from '../api/user';
import {
  getBoxQuote,
  getCities,
  getEventCategories,
  getFAQ,
  getPartnerships,
  getPaymentIntent,
  getPaymentMethods,
  getVenues,
} from '../api/extras';
import { getBooking, getBookings } from '../api/bookings';
import { getBoxEventMenu, getCart } from '../api/foodAndBeverages';

/*********************************** EVENTS ***********************************/
export const useEvents = (queryOptions) =>
  useQuery('events', getAllEvents, queryOptions);

export const useSearchEvents = (
  {
    page,
    size = 80,
    latitude,
    longitude,
    venueId,
    all,
    startsAt,
    state,
    category,
    cityId,
    boxId,
    search,
  },
  queryOptions
) => {
  useQuery(
    `events-search-${search}-page-${page}-size-${size}-latitude-${latitude}-longitude-${longitude}-venueId-${venueId}-all-${all}-startsAt-${startsAt}-state-${state}-category-${category}-cityId-${cityId}-boxId-${boxId}`,
    () =>
      getEvents({
        page,
        size,
        latitude,
        longitude,
        venueId,
        all,
        startsAt,
        state,
        category,
        cityId,
        boxId,
        search,
      }),
    queryOptions
  );
};

export const useEvent = (eventID, queryOptions) =>
  useQuery(`event-${eventID}`, () => getEvent(eventID), queryOptions);

/********************************* BOX/Events *********************************/
export const useEventBoxes = (eventID, queryOptions) =>
  useQuery(
    `event-boxes-${eventID}`,
    () => getEventBoxes(eventID),
    queryOptions
  );

export const useBoxEvent = (boxID, eventID, queryOptions) =>
  useQuery(
    `event-${eventID}-box-${boxID}`,
    () => getBoxEvent(boxID, eventID),
    queryOptions
  );

/********************************** BOOKING ***********************************/
export const useBookings = (queryOptions) =>
  useQuery('bookings', getBookings, queryOptions);

export const useBooking = (bookingId, queryOptions) =>
  useQuery(
    `booking-${bookingId}`,
    () => getBooking({ bookingId }),
    queryOptions
  );

/********************************** FOOD AND BEVERAGES ************************/

export const useBoxEventMenu = (boxId, eventId, queryOptions) =>
  useQuery(
    `menu-box-${boxId}-event-${eventId}`,
    () => getBoxEventMenu({ boxId, eventId }),
    queryOptions
  );

export const useCart = (
  { boxId = null, eventId = null, cartId = null },
  queryOptions
) =>
  useQuery(
    `cart-${cartId ? cartId : '-'}-box${boxId ? boxId : '-'}-event${
      eventId ? eventId : ''
    }`,
    () => getCart({ boxId, eventId, cartId }),
    queryOptions
  );

/********************************** PROFILE ***********************************/
export const useProfileInfo = (queryOptions) =>
  useQuery('profile-info', getProfile, queryOptions);

export const useAddresses = (queryOptions) =>
  useQuery('addresses', getAddresses, queryOptions);

export const useAddress = (id, queryOptions) =>
  useQuery(`address-${id}`, () => getAddress(id), queryOptions);

export const useZIPCode = (cp, queryOptions) =>
  useQuery(`copomex-${cp}`, () => getCopomexData(cp), queryOptions);

/*********************************** PAYMENT **********************************/
export const useGetPaymentMethods = (queryOptions) =>
  useQuery('payment-method', getPaymentMethods, queryOptions);

export const useGetPaymentIntent = (
  { eventId, boxId, totalSeats, bookingType, address },
  queryOptions
) =>
  useQuery(
    `payment-event-${eventId}-box-${boxId}-totalSeats-${totalSeats}-bookingType-${bookingType}-address-${address}`,
    () =>
      getPaymentIntent({ eventId, boxId, totalSeats, bookingType, address }),
    queryOptions
  );

export const useGetBoxQuote = (
  { boxId, eventId, totalSeats, type },
  queryOptions
) =>
  useQuery(
    `quote-box-${boxId}-event-${eventId}-totalSeats-${totalSeats}-type-${type}`,
    () => getBoxQuote({ boxId, eventId, totalSeats, type }),
    queryOptions
  );

/*********************************** EXTRAS ***********************************/

export const useCategories = (queryOptions) =>
  useQuery('categories', getEventCategories, queryOptions);

export const useVenues = (queryOptions) =>
  useQuery('venues', getVenues, queryOptions);

export const useCities = (queryOptions) =>
  useQuery('cities', getCities, queryOptions);

export const useFAQ = (queryOptions) => useQuery('faq', getFAQ, queryOptions);

export const usePartnerships = (queryOptions) =>
  useQuery('partnerships', getPartnerships, queryOptions);
