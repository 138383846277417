import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Reaptcha from 'reaptcha';

// Components & Utils
import TextInput from '../components/inputs/TextInput';
import ShadowBackgroundTextBanner from '../components/faq/ShadowBackgroundTextBanner';
import { UIContext } from '../providers/UIProvider';

// UI
import { Button, Stack, Typography, useTheme, Box } from '@mui/material';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { unsubscribeEmail } from '../api/extras';

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
const defaultFormValues = {
  email: null,
};

/**
 * @function Unsubscribe
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const Unsubscribe = () => {
  useDocumentTitle('Oasis App - Desuscribirse');
  const theme = useTheme();
  const { setNavbarBackgroundColor, toggleSnackbar, updateSnackbarProps } =
    useContext(UIContext);
  const captcha = useRef(null);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const values = {
      'g-recaptcha-response': captchaResponse,
      ...data,
    };
    const res = await unsubscribeEmail(values);
    if (res.success) {
      updateSnackbarProps({
        severity: 'success',
        text: 'Tu correo se ha dado de baja correctamente, no te llegaran mas correos con la oferta de eventos.',
      });
      captcha.current && captcha.current.reset();
      reset();
    } else {
      updateSnackbarProps({
        severity: 'error',
        text: 'Ha ocurrido un error, por favor inténtelo de nuevo.',
      });
    }
    toggleSnackbar(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setNavbarBackgroundColor(theme.palette.lightBackground);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVerify = async (recaptchaResponse) => {
    setCaptchaResponse(recaptchaResponse);
  };

  return (
    <Box
      sx={{
        bgcolor: (currTheme) => currTheme.palette.lightBackground,
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}>
      <ShadowBackgroundTextBanner text={`Suscripciones`} />
      <Box sx={{ bgcolor: (currTheme) => currTheme.palette.mainBackground }}>
        <Box
          sx={{
            pt: { xs: 15, md: 20 },
            pb: { xs: 20, md: 20 },
            maxWidth: 'md',
            px: 3,
            mx: 'auto',
            minHeight: '50vh',
          }}>
          <Typography variant='h1' sx={{ mb: 5 }}>
            ¿Estas segur@ de ya no querer recibir nuestras noticias?
          </Typography>
          <Typography variant='body1' sx={{ mb: 3 }}>
            Si optas por dejar de recibir nuestros correos, dejarás de recibir
            nuestra oferta de eventos.
          </Typography>
          <Stack
            onSubmit={handleSubmit(onFormSubmit)}
            component='form'
            sx={{ maxWidth: { xs: '100%', md: '20em' }, mx: 'auto' }}
            spacing={5}>
            <TextInput
              inputVariant='email'
              registerName='email'
              register={register}
              required='Por favor ingrese su correo.'
              error={Boolean(errors.email && touchedFields.email)}
              helperText={
                Boolean(errors.email && touchedFields.email) &&
                errors.email.message
              }
            />
            <Box
              sx={{
                my: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'flex-end', md: 'center' },
              }}>
              <Reaptcha
                ref={captcha}
                sitekey={reCaptchaKey}
                onVerify={onVerify}
                onExpire={() => setCaptchaResponse(null)}
                onError={() => setCaptchaResponse(null)}
                size='normal'
                theme='dark'
              />
            </Box>
            <Button
              type='submit'
              aria-label='Enviar'
              variant='contained'
              color='secondary'
              size='large'
              disabled={!isValid || !captchaResponse || isLoading}>
              Enviar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Unsubscribe;
