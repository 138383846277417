import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

/**
 * @function ImageCard
 * @author RCH010
 * @version 1.0
 * @description component that renders a card with an image and only supporting text
 * @param {String} image
 * @param {String} supportingText
 * @param {String} backgroundColor
 * @param {String} textColor
 * @param {String} shadow
 * @param {String} justifyContent
 * @param {String} imageMaxWidth
 * @param {String} imageMaxHeigh
 * @param {String} imageContainerHeight
 * @param {String} textVariant
 * @returns {JSX}
 */
const ImageCard = ({
  image,
  supportingText,
  backgroundColor,
  textColor,
  shadow,
  justifyContent,
  imageMaxWidth,
  imageMaxHeigh,
  imageContainerHeight,
  textVariant,
}) => {
  return (
    <Box
      sx={{
        boxShadow: shadow,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: justifyContent,
        alignItems: 'center',
        p: 3,
        width: '100%',
        height: '100%',
        borderRadius: 8,
        backgroundColor: backgroundColor,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: imageContainerHeight,
          '& > img': {
            width: '100%',
            height: 'auto',
            maxHeight: imageMaxHeigh,
            maxWidth: imageMaxWidth,
          },
        }}>
        <img src={image} alt={supportingText} loading='lazy' />
      </Box>
      <Typography
        sx={{
          color: (theme) => textColor || theme.palette.primary.dark,
          pt: 2,
          width: '100%',
        }}
        variant={textVariant}
        align='center'
        variantMapping={{ h6: 'p' }}>
        {supportingText}
      </Typography>
    </Box>
  );
};

ImageCard.defaultProps = {
  backgroundColor: grey.A100,
  textColor: null,
  shadow: 'unset',
  justifyContent: 'space-between',
  imageMaxWidth: 'unset',
  imageMaxHeigh: 'unset',
  imageContainerHeight: '100%',
  textVariant: 'h6',
};

ImageCard.propTypes = {
  image: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  shadow: PropTypes.string,
  imageMaxWidth: PropTypes.string,
  imageMaxHeigh: PropTypes.string,
  justifyContent: PropTypes.string,
  imageContainerHeight: PropTypes.string,
  textVariant: PropTypes.string,
};

export default ImageCard;
