import React, { useRef } from 'react';
// UI
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import Azteca from '../assets/mundial/azteca.webp';
import BannerSin from '../assets/mundial/banner-noletras.webp';
import Mexico from '../assets/mundial/back-mexico.webp';


// Components & Utils
import ViewContainer from '../components/shared/ViewContainer';
import DownloadAppSection from '../components/shared/DownloadAppSection';
import FAQSection from '../components/shared/FAQSection';
import useDocumentTitle from '../hooks/useDocumentTitle';
import ContactFormMundial from '../components/shared/ContactForm-Mundial';

const styles = {
  backAzteca: {
    backgroundImage: `url(${Azteca})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: 'center'
  },
  backBalon: {
    backgroundImage: `url(${BannerSin})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: 'center'
  },
  btnRegistro: {
    backgroundColor: '#b5ec03',
    color: 'black',
    textDecoration: 'none',
    padding: '1em 4em',
    borderRadius: '30px'
  },
  backMexico: {
    backgroundImage: `url(${Mexico})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: 'center'
  }
}

const Mundial2026 = () => {
  useDocumentTitle('Oasis App - Mundial México 2026');
  const contactFormRef = useRef();

  return (
    <ViewContainer>
      <Grid container spacing={0} marginTop='4.5em'>
        <Grid item xs={12} md={7} style={styles.backBalon} sx={{
          height: { xs: 800, md: 1000 },
        }}>
          <Typography
            variant='h1' paddingTop='3em' paddingBottom='1em'>
          ¡NO TE PIERDAS LA
          OPORTUNIDAD DE SER
          PARTE DE LA EMOCIÓN DEL
          MUNDIAL!
          </Typography>
          <Typography
            variant='p' sx={{
              fontSize: { xs: 20, md: 30 },
            }}>
          Regístrate ahora para <b>unirte a la fila</b> de espera
          y recibir información exclusiva sobre la renta de
          palcos para la <b>Copa Mundial de Fútbol 2026</b> en México.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} style={styles.backAzteca} sx={{
          height: { xs: 700, md: 1000 },
        }}>
          <Box backgroundColor='#b5ec03' textAlign='center' padding='2em 4em' color='black' marginTop='15em' marginBottom='4em' sx={{
            marginTop: { xs: '9em', md: '15em' },
          }}>
            <Typography
              variant='p'>
          Con la <b>alta demanda</b> que estamos experimentando, asegura tu lugar desde <b>ahora</b> y no te quedes fuera de este evento histórico.<br /> <b>¡Regístrate ya para no perderte ni un solo momento de la acción!</b>
            </Typography>
          </Box>
          <a href="/mundial-mexico/#registro" style={styles.btnRegistro}><b>REGÍSTRATE AQUÍ</b></a>
        </Grid>
        <Grid item xs={12} md={12} style={styles.backMexico}>
          <Box sx={{ mt: 10, zIndex: 1, position: 'relative' }}>
            <span ref={contactFormRef} />
            <a name="registro"></a>
            <ContactFormMundial noBackground />
          </Box>
        </Grid>
      </Grid>
      <DownloadAppSection />
      <FAQSection />
    </ViewContainer>
  );
};

export default Mundial2026;
