import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
// Components & Utils
// UI
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';

/**
 * @function DiscountBadge
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const DiscountBadge = ({ price, discount, isLarge }) => {
  const value = Number((discount * 100) / (price + discount)).toFixed(2);

  // Fail safe
  if (value <= 0 || value > 100) return <></>;

  return (
    <Stack
      alignItems='center'
      direction='row'
      sx={{
        backgroundColor: (t) => t.palette.secondary.main,
        width: 'fit-content',
        px: isLarge ? '12px' : '4px',
        py: isLarge ? '6px' : '2px',
        borderRadius: '500px',
        gap: isLarge ? '8px' : '6px',
      }}>
      <LocalOfferRoundedIcon
        sx={{
          color: (t) => t.palette.secondary.contrastText,
          fontSize: isLarge ? '16px !important' : '11px !important',
        }}
      />
      <Typography
        sx={{
          fontSize: isLarge ? '14px !important' : '10px !important',
          fontWeight: '400 !important',
          color: (t) => t.palette.secondary.contrastText,
        }}>{`-${value}%`}</Typography>
    </Stack>
  );
};

DiscountBadge.propTypes = {
  price: PropTypes.number.isRequired,
};

export default DiscountBadge;
