import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// Components & Utils
// UI
import {
  Box,
  ClickAwayListener,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { CheckoutContext } from '../../providers/CheckoutProvider';

const elementStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const buttonStyles = {
  color: (t) => t.palette.secondary.contrastText,
  transition: 'color 150ms',
  ':hover': {
    color: (t) => t.palette.grey[600],
  },
};

/**
 * @function ProductButton
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const ProductButton = ({ cardWidth, id, price }) => {
  const [colap, setColap] = useState(false);
  const addButton = useRef(null);
  const { cart, updateCart } = useContext(CheckoutContext);

  const widthProp = {
    xs: `calc(${cardWidth.xs} / 3)`,
    sm: `calc(${cardWidth.sm} / 3)`,
    md: `calc(${cardWidth.md} / 3)`,
  };

  const onClickAwayProduct = async () => {
    setColap(false);
  };

  const addItem = (increment) => {
    updateCart(id, { quantity: increment, unitPrice: price });
    if (!colap) setColap(true);
  };

  useEffect(() => {
    if (cart[id]?.quantity < 1) {
      setColap(false);
    }
  }, [cart, id]);

  return (
    <ClickAwayListener onClickAway={() => onClickAwayProduct()}>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '500px',
        }}>
        <Slide
          timeout={200}
          in={colap}
          direction='left'
          container={addButton.current}
          sx={{
            '.MuiCollapse-wrapperInner': { width: '100%' },
            display: 'flex',
            backgroundColor: (t) => t.palette.secondary.light,
          }}>
          <Box>
            <Box sx={{ width: { ...widthProp }, ...elementStyles }}>
              <IconButton>
                <>
                  {cart[id]?.quantity === 1 ? (
                    <DeleteRoundedIcon
                      onClick={() => addItem(-1)}
                      sx={{
                        ...buttonStyles,
                      }}
                    />
                  ) : (
                    <RemoveRoundedIcon
                      onClick={() => addItem(-1)}
                      sx={{
                        ...buttonStyles,
                      }}
                    />
                  )}
                </>
              </IconButton>
            </Box>
            <Box sx={{ width: { ...widthProp }, ...elementStyles }}>
              <Typography
                sx={{ color: (t) => t.palette.secondary.contrastText }}>
                {cart[id]?.quantity}
              </Typography>
            </Box>
          </Box>
        </Slide>
        <Box
          sx={{
            ...elementStyles,
            backgroundColor: (t) => t.palette.secondary.light,
            borderRadius: '500px',
            position: 'absolute',
            top: 0,
            right: 0,
          }}>
          <IconButton
            ref={addButton}
            onClick={() => {
              // When there's an item and the productButton is collapsed
              // it shows in the circle, so we only add the Item when there's a
              // add icon.
              if (colap || !cart[id] || cart[id]?.quantity < 1) {
                addItem(1);
              } else {
                setColap(true);
              }
            }}>
            {colap || !cart[id] || cart[id]?.quantity < 1 ? (
              <AddRoundedIcon
                sx={{
                  ...buttonStyles,
                }}
              />
            ) : (
              <Typography
                sx={{
                  color: (t) => t.palette.secondary.contrastText,
                  fontSize: '0.9rem !important',
                  width: '24px',
                  height: '24px',
                  display: 'block',
                  lineHeight: '24px',
                  wordWrap: 'unset !important',
                }}>
                {cart[id]?.quantity}
              </Typography>
            )}
          </IconButton>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

ProductButton.defaultProps = {
  something: '',
};

ProductButton.propTypes = {
  something: PropTypes.string,
};

export default ProductButton;
