import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// UI
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';

/**
 * @function DateInput
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const DateInput = ({
  control,
  registerName,
  rules,
  label,
  datePickerProps,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <Controller
        control={control}
        name={registerName}
        rules={rules}
        {...rest}
        render={({ field }) => (
          <DatePicker
            label={label}
            {...field}
            {...datePickerProps}
            renderInput={(params) => (
              <TextField
                color='secondary'
                placeholder='dd/mm/yyyy'
                sx={{
                  m: 0,
                  '& .Mui-focused': {
                    '& * svg': {
                      color: 'secondary.main',
                    },
                  },
                  '& .Mui-error': {
                    '& * svg': {
                      color: (currTheme) => currTheme.palette.error.main,
                    },
                  },
                }}
                {...params}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

DateInput.defaultProps = {
  rules: {},
  datePickerProps: {},
};

DateInput.propTypes = {
  control: PropTypes.object.isRequired,
  registerName: PropTypes.string,
  rules: PropTypes.object,
  label: PropTypes.string,
  datePickerProps: PropTypes.object,
};

export default DateInput;
