import React, { useContext, useEffect } from 'react';

// Components & Utils
import { UIContext } from '../../providers/UIProvider';
import AuthAlternativeButtons from '../../components/auth/AuthAlternativeButtons';
import { AuthView } from '../../components/auth/AuthView';
import { ProviderLoginType } from '../../utils/enums';
import AuthType from './AuthType';

/**
 * @function Login
 * @author RCH010
 * @version 1.0
 * @description Login View
 * @returns {JSX}
 */
const Login = () => {
  const { setNavbarBackgroundColor } = useContext(UIContext);

  useEffect(() => {
    setNavbarBackgroundColor('#5A3CB4');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthView>
      <AuthType type={ProviderLoginType.LOGIN} />
      <AuthAlternativeButtons type={ProviderLoginType.LOGIN} />
    </AuthView>
  );
};

export default Login;
