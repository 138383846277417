import { APITypes, httpRequest } from '../utils/util';

export const getBoxQuote = async ({ boxId, eventId, totalSeats, type }) => {
  const res = await httpRequest(
    `app/boxes/quote?boxId=${boxId}&eventId=${eventId}&type=${type}&${
      totalSeats ? `totalSeats=${totalSeats}` : ''
    }`
  );
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getPaymentMethods = async () => {
  const res = await httpRequest(`app/paymentMethods`);
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getPaymentIntent = async ({
  eventId,
  boxId,
  totalSeats,
  bookingType,
  address,
}) => {
  const res = await httpRequest(`app/payment`, {
    body: {
      eventId,
      boxId,
      totalSeats,
      bookingType,
      address,
    },
    method: 'POST',
  });

  /// TODO: define error codes
  if (res.paymentIntent) return res;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const updatePaymentIntent = async ({ paymentIntent, paymentMethod }) => {
  // console.log('recieve', paymentIntent, paymentMethod);
  const res = await httpRequest(`app/updatePayment`, {
    body: {
      paymentIntentId: paymentIntent,
      paymentMethodId: paymentMethod,
    },
    method: 'POST',
  });
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;

  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const sendContactForm = async (data) => {
  const res = await httpRequest(
    `contact`,
    {
      body: { ...data },
      method: 'POST',
    },
    APITypes.PIXAN
  );

  if (res.status === 'ok') return { success: true };
  return {
    success: false,
    message:
      'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde',
  };
};

export const unsubscribeEmail = async (data) => {
  const res = await httpRequest(
    `notifications/app/weeklySummary/unsubscribe`,
    {
      method: 'POST',
      body: { ...data },
    },
    APITypes.PIXAN
  );

  if (res.success) return { success: true };

  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getEventCategories = async () => {
  const res = await httpRequest('app/categories');
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getVenues = async () => {
  const res = await httpRequest('app/venues');
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getCities = async () => {
  const res = await httpRequest('app/cities');
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getFAQ = async () => {
  const res = await httpRequest('app/faq');
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getPartnerships = async () => {
  const res = await httpRequest('app/partnerships');
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};
