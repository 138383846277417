import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

// Components & Utils
import NewAddressForm from '../../components/checkout/address/NewAddressForm';
import { useAddress } from '../../hooks/requests';
import { addAddress, editAddress } from '../../api/user';

// UI
import { Stack, Skeleton, Typography } from '@mui/material';
import useDocumentTitle from '../../hooks/useDocumentTitle';

/**
 * @function Address
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const Address = () => {
  useDocumentTitle('Oasis App - Mi dirección');
  const { addressID } = useParams();
  const navigate = useNavigate();
  const [address, setAddress] = useState(null);
  const addNewAddressMutation = useMutation(addAddress);
  const editAddressMutation = useMutation(editAddress);

  useAddress(addressID, {
    enabled: addressID !== 'new',
    onSuccess: (data) => {
      setAddress(data);
    },
    onError: (error) => {
      console.log('Error fetching address', error);
    },
  });

  const onEditAddress = async (editedAddress) => {
    try {
      await editAddressMutation.mutateAsync({ ...editedAddress });
      navigate('/profile/addresses');
      // Mensaje de success y back to addresses
    } catch (error) {
      throw new Error(
        'No se pudo actualizar la dirección, intenta de nuevo más tarde'
      );
    }
  };

  const onNewAddressSubmit = async (newAddress) => {
    try {
      await addNewAddressMutation.mutateAsync({ ...newAddress });
      navigate('/profile/addresses');
      // Mensaje de success y back to addresses
    } catch (error) {
      throw new Error(
        'No se pudo crear la dirección, intenta de nuevo más tarde'
      );
    }
  };

  if (addressID === 'new') {
    return (
      <>
        <Typography variant='h4'>Nueva dirección</Typography>
        <Typography variant='body2' gutterBottom>
          Tu dirección se usará para saber a dónde mandar los pases del palco
          que rentes
        </Typography>
        <NewAddressForm
          onSubmit={onNewAddressSubmit}
          buttonLabel='Guardar'
          buttonsContainerStyles={{ width: '100%' }}
        />
      </>
    );
  }

  return (
    <>
      {address ? (
        <>
          <Typography variant='h4'>Editar dirección</Typography>
          <Typography variant='body2' gutterBottom>
            Tu dirección se usará para saber a dónde mandar los pases del palco
            que rentes
          </Typography>
          <NewAddressForm
            buttonsContainerStyles={{ width: '100%' }}
            defaultValues={address}
            onSubmit={onEditAddress}
            buttonLabel='Guardar'
          />
        </>
      ) : (
        <Stack
          spacing={4}
          sx={{
            width: '100%',
            mt: 3,
            mx: 'auto',
            maxWidth: 'md',
            my: 5,
            px: 3,
          }}>
          <Skeleton variant='rectangular' width='100%' height='15em' />
          <Skeleton variant='rectangular' width='100%' height='4em' />
        </Stack>
      )}
    </>
  );
};

export default Address;
