import amex from '../assets/cards/american_express.svg';
import dinersClub from '../assets/cards/diners_club.svg';
import discover from '../assets/cards/discover.svg';
import jcb from '../assets/cards/jcb.svg';
import mastercard from '../assets/cards/mastercard.svg';
import unionPay from '../assets/cards/union_pay.svg';
import visa from '../assets/cards/visa.svg';
import cartesBancaires from '../assets/cards/cartes_bancaires.svg';

export const cardsSVG = {
  amex: amex,
  cartes_bancaires: cartesBancaires,
  diners_club: dinersClub,
  discover: discover,
  jcb: jcb,
  mastercard: mastercard,
  visa: visa,
  unionpay: unionPay,
};
