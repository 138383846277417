import React from 'react';
// UI
import { Box } from '@mui/material';

/**
 * @function ViewContainer
 * @author RCH010
 * @version 1.0
 * @description Base view container
 * @param {node} children
 * @returns {JSX}
 */
const ViewContainer = ({ children, isDarkBackground = false, overflowHidden = true }) => {
  return (
    <Box
      sx={{
        backgroundImage: (currTheme) =>
          isDarkBackground
            ? 'linear-gradient(180deg, #2D283A 8.56%, #3B2376 141.24%);'
            : `linear-gradient(${currTheme.palette.lightBackground}, ${currTheme.palette.mainBackground} 70%)`,
        position: 'relative',
        zIndex: 1,
        minHeight: '100vh',
        ...(overflowHidden && {overflow: 'hidden'})
      }}>
      {children}
    </Box>
  );
};

export default ViewContainer;
