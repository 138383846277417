import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

// Components & Utils
import { CheckoutContext } from '../../providers/CheckoutProvider';
import AddressForm from './forms/AddressForm';
import TicketsForm from './forms/TicketsForm';
import FoodAndBeveragesForm from './forms/FoodAndBeveragesForm';
import PaymentMethodForm from './forms/PaymentMethodForm';

import { bookingSteps } from '../../utils/enums';

/**
 * @function BookingSteps
 * @author RCH010
 * @version 1.0
 * @description This is the checkout handler that is within the checkout provider
 * @returns {JSX}
 */
const BookingSteps = ({
  eventID,
  boxID,
  nextStep,
  triggerPayment,
  setTriggerPayment,
}) => {
  const { currentStep } = useContext(CheckoutContext);

  return (() => {
    switch (currentStep) {
      case bookingSteps.ADDRESS:
        return <AddressForm nextStep={nextStep} />;
      case bookingSteps.TICKETS:
        return <TicketsForm />;
      case bookingSteps.FOOD_AND_BEVERAGES:
        return <FoodAndBeveragesForm />;
      case bookingSteps.PAYMENT:
        return (
          <PaymentMethodForm
            triggerPayment={triggerPayment}
            setTriggerPayment={setTriggerPayment}
          />
        );
      default:
        return <Navigate replace to={`/events/${eventID}/box/${boxID}`} />;
    }
  })();
};

export default BookingSteps;
