import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { bookingStatusType } from '../../utils/enums';
// Components & Utils
// UI

const variants = {
  [bookingStatusType.ACCEPTED]: {
    backgroundColor: '#4CAF50',
    color: '#E0FFE1',
  },
  [bookingStatusType.CANCELED]: {
    backgroundColor: '#EF5350',
    color: '#FFE7E7',
  },
  [bookingStatusType.PENDING]: {
    backgroundColor: '#03A9F4',
    color: '#E9F8FF',
  },
};

const statusText = {
  [bookingStatusType.ACCEPTED]: 'Pagado',
  [bookingStatusType.CANCELED]: 'Cancelado',
  [bookingStatusType.PENDING]: 'Pendiente',
};

/**
 * @function StatusChip
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const StatusChip = ({ variant }) => {
  return (
    <Box
      sx={{
        fontSize: '14px',
        px: '8px',
        py: '2px',
        width: 'fit-content',
        borderRadius: '100px',
        ...variants[variant],
      }}>
      {statusText[variant]}
    </Box>
  );
};

StatusChip.defaultProps = {
  something: '',
};

StatusChip.propTypes = {
  something: PropTypes.string,
};

export default StatusChip;
