import React from 'react';
import PropTypes from 'prop-types';
// UI
import { Box } from '@mui/material';

/**
 * @function VideoContainer
 * @author RCH010
 * @version 1.0
 * @description container for a responsive youtube video
 * @param {String} title
 * @param {String} embedID from Youtube
 * @returns {JSX}
 */
const VideoContainer = ({ title, embedID }) => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
        height: 0,
        '& > iframe': {
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          position: 'absolute',
        },
      }}>
      <iframe
        width='853'
        height='480'
        src={`https://www.youtube-nocookie.com/embed/${embedID}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title={title}
      />
    </Box>
  );
};

VideoContainer.defaultProps = {
  title: '',
  embedID: '',
};

VideoContainer.propTypes = {
  title: PropTypes.string.isRequired,
  embedID: PropTypes.string.isRequired,
};

export default VideoContainer;
