import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Reaptcha from 'reaptcha';

// Components & Utils
import CardElement from './CardElement';
import { CheckoutContext } from '../../providers/CheckoutProvider';

// UI
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { UIContext } from '../../providers/UIProvider';
import { appStorage } from '../../utils/util';

const baseURL = process.env.REACT_APP_BASE_PATH;
const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

/**
 * @function Checkout
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const Checkout = ({
  paymentIntent: createdPaymentIntent,
  paymentMethods,
  triggerPayment,
  setTriggerPayment,
}) => {
  const stripe = useStripe();
  const captcha = useRef(null);
  const elements = useElements();
  const navigate = useNavigate();
  const { boxID, eventID } = useParams();
  const { setCheckoutButtons } = useContext(CheckoutContext);
  const {
    toggleAlertDialog,
    updateAlertDialogProps,
    updateSnackbarProps,
    toggleSnackbar,
  } = useContext(UIContext);

  const [captchaResponse, setCaptchaResponse] = useState(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethods && paymentMethods.length > 0 ? paymentMethods[0] : null
  );
  const [isSettingNewCard, setIsSettingNewCard] = useState(
    !(paymentMethods && paymentMethods.length > 0)
  );

  const onVerify = async (recaptchaResponse) => {
    setCaptchaResponse(recaptchaResponse);
  };

  const handleSubmit = async () => {
    // Validate captcha is completed and stripe is loaded.
    if (!canProceedToPayment()) return;
    setCheckoutButtons((p) => ({ ...p, isLoading: true }));

    let paymentError = null;
    appStorage.save('boxID', boxID);
    appStorage.save('eventID', eventID);

    if (isSettingNewCard) {
      if (!elements) return;
      const { error: errorNewCard } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${baseURL}/processing-payment`,
        },
      });
      paymentError = errorNewCard || null;
    } else {
      const { error: errorOldCard } = await stripe.confirmCardPayment(
        createdPaymentIntent.paymentIntent,
        {
          payment_method: selectedPaymentMethod.id,
          return_url: `${baseURL}/processing-payment`,
        }
      );
      paymentError = errorOldCard || null;
    }

    if (
      paymentError &&
      (paymentError.type === 'card_error' ||
        paymentError.type === 'validation_error')
    ) {
      updateAlertDialogProps({
        title: '¡Oh no!',
        content: paymentError.message,
        variant: 'error',
      });
      toggleAlertDialog(true);
      setCheckoutButtons((p) => ({ ...p, isLoading: false }));
    } else {
      navigate(
        `/processing-payment?payment_intent_client_secret=${createdPaymentIntent.paymentIntent}`
      );
    }
  };

  const canProceedToPayment = () => {
    if (!stripe || (isSettingNewCard && !elements)) {
      updateSnackbarProps({
        severity: 'info',
        text: 'Se esta generando tu orden de pago, por favor espera un momento.',
      });
      toggleSnackbar(true);

      return false;
    }

    if (!captchaResponse) {
      updateSnackbarProps({
        severity: 'warning',
        text: 'Por favor completa el captcha para poder realizar el pago.',
      });
      toggleSnackbar(true);
    }

    return stripe && captchaResponse;
  };

  useEffect(() => {
    if (triggerPayment) {
      handleSubmit();
      setTriggerPayment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPayment]);

  return (
    <Stack sx={{ height: '100%' }}>
      <Typography variant='h3' gutterBottom>
        ¿Cómo será tu pago?
      </Typography>
      {/**
        Only display button to switch bewtween cards/new card if the user has cards
       */}
      {paymentMethods && paymentMethods.length > 0 && (
        <Box sx={{ mt: 2, mb: 3 }}>
          <Button
            sx={{ fontSize: '1rem' }}
            size='medium'
            variant='outlined'
            aria-label='Checkout'
            color='secondary'
            onClick={() => setIsSettingNewCard((isNew) => !isNew)}>
            {isSettingNewCard
              ? 'Usar una tarjeta guardada'
              : 'Agrega otra tarjeta'}
          </Button>
        </Box>
      )}

      <Stack
        spacing={2}
        justifyContent='space-between'
        component='form'
        id='payment-form'
        sx={{ height: '100%' }}
        // onSubmit={handleSubmit}
      >
        {/**
          Render cards of the user
         */}
        {paymentMethods && paymentMethods.length > 0 && !isSettingNewCard && (
          <Grid
            container
            justifyContent='space-evenly'
            alignItems='center'
            sx={{ gap: 2 }}>
            {paymentMethods.map((pm) => (
              <CardElement
                key={pm.id}
                paymentMethod={pm}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
            ))}
          </Grid>
        )}

        {/**
          Renders the stripe paymentElement to add a new payment method
         */}

        {isSettingNewCard && <PaymentElement id='payment-element' />}
        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <Reaptcha
            ref={captcha}
            sitekey={reCaptchaKey}
            onVerify={onVerify}
            onExpire={() => setCaptchaResponse(null)}
            onError={() => setCaptchaResponse(null)}
            size='normal'
            theme='dark'
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default Checkout;
