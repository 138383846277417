import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const contactData = {
  mail: {
    label: 'contacto@oasisapp.mx',
    href: 'mailto:contacto@oasisapp.mx',
    Icon: <EmailIcon color='secondary' />,
  },
  whatsapp: {
    label: '+52 (55) 8410 7195',
    href: 'https://wa.me/5215584107195',
    Icon: <WhatsAppIcon color='secondary' />,
  },
  phone: {
    label: '+52 (55) 4440 8015',
    href: 'tel:5215544408015',
    Icon: <PhoneIcon color='secondary' />,
  },
  address: {
    label: 'Blvd. Miguel de Cervantes Saavedra 157Amp Granada, Miguel Hidalgo, 11520 Ciudad de México, CDMX',
    href: 'https://maps.app.goo.gl/mKaBkPFaeFu7Eyz69',
    Icon: <LocationOnSharpIcon color='secondary' />,
  },
};
