import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';

// Components
import { AuthContext } from '../../providers/AuthProvider';

// Utils
import { menuOptions } from '../../utils/menuOptions';

// UI
import { Avatar, Stack, Tab, Tabs, Typography, Button } from '@mui/material';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const LinkTab = ({ to, label, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Tab
      label={label}
      component='a'
      onClick={(event) => {
        event.preventDefault();
        navigate(to);
      }}
      {...rest}
    />
  );
};

const sections = {
  bookings: 1,
  addresses: 2,
};

const ProfileNav = () => {
  useDocumentTitle('Oasis App - Mi cuenta');
  const { auth, logOut } = useContext(AuthContext);
  const { pathname } = useLocation();
  const section = pathname.split('/')[2] || null;
  const [value, setValue] = useState(sections[section] || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // TODO: el losding no va aqui, debe de ir en el authprovider
  if (auth.isLoading) return <></>;

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: (t) => t.palette.lightLinearGradient,
        width: '100%',
        pt: { xs: 10, md: 15 },
        pb: 20,
      }}>
      <Stack alignItems={'center'} spacing={1}>
        <Avatar
          sx={{ width: 124, height: 124 }}
          src={auth.userData.pictureUrl ?? undefined}
        />
        <Typography variant='h1'>{auth.userData?.fullName || ''}</Typography>
        <Typography variant='h4'>{auth.userData?.email || ''}</Typography>

        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
          textColor='secondary'
          indicatorColor='secondary'
          sx={{ maxWidth: '100%' }}>
          <LinkTab to={menuOptions.PROFILE.path} label='Información personal' />
          <LinkTab to={menuOptions.BOOKINGS.path} label='Mis reservas' />
          <LinkTab to={menuOptions.ADDRESSES.path} label='Mis direcciones' />
          {/* TODO: <LinkTab to={menuOptions.FAVORITES.path}>Favoritos</LinkTab> */}
        </Tabs>
      </Stack>
      <Stack
        spacing={2}
        sx={{
          flexGrow: 1,
          width: '100%',
          mx: 'auto',
          maxWidth: 'md',
          my: 5,
          px: 3,
        }}
        justifyContent='space-between'>
        <Outlet />
        <Stack alignItems={'center'} spacing={2} sx={{ pt: 8 }}>
          <Button
            color='error'
            variant='outlined'
            aria-label='Cerrar Sesion'
            sx={{ maxWidth: 'md', width: '100%' }}
            onClick={() => logOut()}>
            Cerrar sesión
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileNav;
