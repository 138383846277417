import React, { useContext, useEffect } from 'react';
import { AuthView } from '../../components/auth/AuthView';
import { UIContext } from '../../providers/UIProvider';
import { ProviderLoginType } from '../../utils/enums';
import AuthType from './AuthType';

// Components & Utils

/**
 * @function ResetPassword
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const ResetPassword = () => {
  const { setNavbarBackgroundColor } = useContext(UIContext);

  useEffect(() => {
    setNavbarBackgroundColor('#5A3CB4');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthView>
      <AuthType type={ProviderLoginType.FORGOT_PASSWORD} />
    </AuthView>
  );
};

export default ResetPassword;
