import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components & Utils
import { CheckoutContext } from '../../../providers/CheckoutProvider';

// UI
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';

const parseAddress = (addresses) => {
  if (!addresses) return null;
  const userAddresses = addresses.map((address) => ({
    id: address.id,
    value: address.id,
    label: `${address.street}, ${address.extNumber}, ${
      address.intNumber ? address.intNumber + ',' : ''
    } ${address.neighborhood}, ${address.zip}, ${address.city}, ${
      address.state
    }`,
  }));

  return userAddresses;
};

const findAddress = (id, addresses = []) => {
  return addresses.find((address) => address.id === id);
};

/**
 * @function SelectAddress
 * @author RCH010
 * @version 1.0
 * @description Address select
 * @returns {JSX}
 */
const SelectAddress = ({ addresses, addressDefaultValue }) => {
  const userAddresses = parseAddress(addresses);
  const { updateBookingOptions } = useContext(CheckoutContext);

  const onSelectChange = ({ target }) => {
    const addressID = target.value;
    const selectedAddress = findAddress(addressID, addresses);

    updateBookingOptions({ selectedAddress: { ...selectedAddress } });
  };

  // Set the default address on Booking options (for when the select is rendered and there's no change)
  useEffect(() => {
    const defaultAddress = findAddress(addressDefaultValue, addresses);
    updateBookingOptions({ selectedAddress: { ...defaultAddress } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={{ flex: 1 }} justifyContent='space-between'>
      <FormControl
        color='secondary'
        sx={{
          m: 0,
          minWidth: 'calc(100%)',
          maxWidth: '10em',
        }}>
        <InputLabel id={`select-address`}>Dirección</InputLabel>
        <Select
          onChange={onSelectChange}
          color='secondary'
          label={'Dirección'}
          labelId='select-address'
          variant='outlined'
          defaultValue={addressDefaultValue}
          MenuProps={{
            sx: { '& .MuiMenu-paper': { backgroundColor: '#2D1E5A' } },
          }}>
          {userAddresses.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

SelectAddress.defaultProps = {
  addresses: [],
};

SelectAddress.propTypes = {
  addresses: PropTypes.array,
};

export default SelectAddress;
