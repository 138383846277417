import HowItWorks01 from '../assets/howItWorks/howItWorksApp.svg';
import HowItWorks02 from '../assets/howItWorks/howItWorksBox.svg';
import HowItWorks03 from '../assets/howItWorks/howItWorksEnjoy.svg';

import venues01 from '../assets/venues/ArenaCDMX.png';
import venues02 from '../assets/venues/ArenaMonterrey.png';
import venues03 from '../assets/venues/Azteca.png';
import venues04 from '../assets/venues/Azul.png';
import venues05 from '../assets/venues/BBVA.png';
import venues06 from '../assets/venues/Coliseo.png';
import venues07 from '../assets/venues/Corregidora.png';
import venues08 from '../assets/venues/Fronton.png';
import venues09 from '../assets/venues/Morelos.png';
import venues10 from '../assets/venues/TorosMexico.png';
import venues11 from '../assets/venues/TSM.png';
import venues12 from '../assets/venues/Uni.png';

import bookingService01 from '../assets/bookingServices/Security.svg';
import bookingService02 from '../assets/bookingServices/Service.svg';
import bookingService03 from '../assets/bookingServices/Transport.svg';

import linkingBoxes01 from '../assets/linkingBoxes/Analysts.svg';
import linkingBoxes02 from '../assets/linkingBoxes/Concierge.svg';
import linkingBoxes03 from '../assets/linkingBoxes/Income.svg';

export const howItWorks = [
  { id: 1, image: HowItWorks01, supportingText: 'Descarga la App' },
  { id: 2, image: HowItWorks02, supportingText: 'Encuentra un palco' },
  { id: 3, image: HowItWorks03, supportingText: 'Disfruta' },
];

export const bookingServices = [
  { id: 1, image: bookingService01, supportingText: 'Seguridad\nantifraudes' },
  {
    id: 2,
    image: bookingService02,
    supportingText: 'Servicio de\ncomidas y bebidas',
  },
  {
    id: 3,
    image: bookingService03,
    supportingText: 'Transporte de\nboletos a domicilio',
  },
];

export const linkingBoxes = [
  {
    id: 1,
    image: linkingBoxes01,
    supportingText:
      'Analistas de eventos que\nte propondrán precios dinámicos\npara recibir los ingresos justos del mercado',
  },
  {
    id: 2,
    image: linkingBoxes02,
    supportingText:
      'Concierge VIP para antes,\ndurante y después de la estadía',
  },
  {
    id: 3,
    image: linkingBoxes03,
    supportingText: 'Ingresos constantes\nal rentar tu palco',
  },
];

// TODO Hacer esto un endpoint
export const venuesInfo = [
  { id: 1, srcImage: venues01, altText: 'Arena Ciudad de México' },
  { id: 2, srcImage: venues02, altText: 'Arena Monterrey' },
  { id: 3, srcImage: venues03, altText: 'Estadio Azteca' },
  { id: 4, srcImage: venues04, altText: 'Estadio Azul' },
  { id: 5, srcImage: venues05, altText: 'Estadio BBVA Bancomer' },
  { id: 6, srcImage: venues06, altText: 'Estadio Coliseo' },
  { id: 7, srcImage: venues07, altText: 'Esyadop Corregidora' },
  { id: 8, srcImage: venues08, altText: 'Estadio Fronton' },
  { id: 9, srcImage: venues09, altText: 'Estadio Morelos' },
  { id: 10, srcImage: venues10, altText: 'Monumental Plaza de Toros México' },
  { id: 11, srcImage: venues11, altText: 'Estadio TSM' },
  { id: 12, srcImage: venues12, altText: 'Estadio Universitario' },
];
