import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  APITypes,
  appStorage,
  errorResponse,
  httpRequest,
} from '../utils/util';
import { createProfile, getProfile } from '../api/user';

const AuthDefaultValues = {
  isLoading: true,
  isLoggedIn: false,
  userData: {},
  redirectTo: null,
};

export const AuthContext = createContext(); // TODO: definir valores default

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(AuthDefaultValues);

  useEffect(() => {
    const jwt = appStorage.retrieve('jwt');
    if (jwt) {
      getProfile().then((userData) => {
        if (userData) {
          appStorage.save('user', userData);
          setAuth((currentAuth) => ({
            ...currentAuth,
            isLoggedIn: userData !== null,
            isLoading: false,
            userData,
          }));
        } else {
          setAuth((currentAuth) => ({
            ...currentAuth,
            isLoggedIn: false,
            isLoading: false,
          }));
        }
      });
    } else {
      setAuth((currentAuth) => ({
        ...currentAuth,
        isLoggedIn: false,
        isLoading: false,
      }));
    }
  }, []);

  const signUp = async ({
    firstName,
    lastName,
    motherLastName,
    email,
    phone,
    phoneCountry,
    birthAt,
    password,
    passwordConfirmation,
  }) => {
    const res = await createProfile({
      firstName,
      lastName,
      motherLastName,
      email,
      phone,
      phoneCountry,
      birthAt,
      password,
      passwordConfirmation,
    });
    if (res.status === 'error') {
      const messages = res?.messages;
      const [message] = Object.values(messages || {});

      return errorResponse({ message: message });
    }
    return res;
  };

  const login = async ({ provider, email: user, password: secret }) => {
    const res = await httpRequest(
      'auth/login',
      {
        body: { provider, user, secret },
        method: 'POST',
      },
      APITypes.PIXAN
    );

    if (res && res.status === 'ok') {
      appStorage.save('user', res.data.user);
      const { expiresAt, isPaymentDisabled, refreshToken, token } = res.data;
      appStorage.save('jwt', {
        expiresAt,
        isPaymentDisabled,
        refreshToken,
        token,
      });
      setAuth((currentAuth) => ({
        ...currentAuth,
        isLoggedIn: true,
        isLoading: false,
        userData: { ...res.data.user },
      }));
      return res.data;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (res.status === 'error' && res.messages.hasOwnProperty('401')) {
      return errorResponse({ code: 'auth-credentials' });
    }

    return errorResponse({
      code: res?.code,
      message: 'No se ha podido verificar al usuario',
    });
  };

  const logOut = async () => {
    const res = await httpRequest(
      'auth/logout',
      {
        method: 'POST',
      },
      APITypes.PIXAN
    );
    if (res && res.status === 'ok') {
      appStorage.clear();
      setAuth((currentAuth) => ({
        ...currentAuth,
        isLoggedIn: false,
        isLoading: false,
        userData: null,
      }));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        signUp,
        login,
        logOut,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
