import React, { useContext, useEffect } from 'react';
// Components & Utils
import ShadowBackgroundTextBanner from '../components/faq/ShadowBackgroundTextBanner';
// UI
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { UIContext } from '../providers/UIProvider';
import useDocumentTitle from '../hooks/useDocumentTitle';

/**
 * @function TermsAndContiions
 * @author RCH010
 * @version 1.0
 * @description TermsAndContiions view
 * This component doesn't uses ViewContainer because of the desired effect of the FAQBanner
 * View contains a tabs container with all the questions in accordions
 * And a DownloadAppSection and Contact form
 * @returns {JSX}
 */
const TermsAndConditions = () => {
  const theme = useTheme();
  useDocumentTitle('Oasis App - Términos y condiciones');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setNavbarBackgroundColor } = useContext(UIContext);

  useEffect(() => {
    setNavbarBackgroundColor(theme.palette.lightBackground);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      sx={{
        bgcolor: (currTheme) => currTheme.palette.lightBackground,
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}>
      <ShadowBackgroundTextBanner
        text={`Términos ${isMobile ? '\n' : ''}y Condiciones`}
      />
      <Box
        sx={{
          bgcolor: (currTheme) => currTheme.palette.mainBackground,
        }}>
        <Box
          sx={{
            px: 3,
            maxWidth: 'lg',
            mx: 'auto',
            py: 8,
          }}>
          <Typography>
            <p>
              A los Usuarios, les informamos que los siguientes Términos y
              Condiciones les son aplicables por el simple uso o acceso a
              cualquiera de las páginas, aplicaciones web y móviles, softwares
              y, aplicaciones en general, que integran el Portal de OASISAPP.MX
              (en adelante y, conjunta e indistintamente, el "Portal"), por lo
              que entenderemos que los acepta y acuerda en obligarse a su
              cumplimiento{' '}
              <b>
                En el caso de que no esté de acuerdo con los Términos y
                Condiciones debera abstenerse de acceder o utilizar el Portal.
              </b>
            </p>
            <p>
              OASIS BY JOA S.A. DE C.V. y/o sus subsidiarias, controladoras,
              partes relacionadas y afiliadas (en adelante y, conjunta e
              indistintamente, la "Empresa") se reservan el derecho de modificar
              discrecionalmente el contenido del Portal en cualquier momento,
              sin necesidad de previo aviso.
            </p>
            <p>
              El Usuario, entendido como aquella persona que realiza el uso o
              accede al Portal, mediante equipo de cómputo y/o cualquier equipo
              de comunicación o dispositivo (en adelante el "Usuario"), conviene
              en no utilizar dispositivos, software, o cualquier otro medio
              tendiente a interferir tanto en las actividades y/u operaciones
              del Portal, en las bases de datos y/o información que se contenga
              en el mismo.
            </p>
            <p>
              <b>1. USO Y RESTRICCIONES.</b> El acceso o utilización del Portal
              expresan la adhesión plena y sin reservas del Usuario a los
              presentes Términos y Condiciones. A través del Portal, el Usuario
              se servirá, contratará y/o utilizará diversos servicios y
              contenidos (los "Servicios y Contenidos"), puestos a disposición
              por la Empresa. La Empresa tendrá el derecho a negar, restringir o
              condicionar al Usuario el acceso al Portal, total o parcialmente,
              a su entera discreción, así como a modificar los Servicios y
              Contenidos del Portal en cualquier momento y sin necesidad de
              previo aviso.
            </p>
            <p>
              El Usuario reconoce que no todos los Servicios y Contenidos están
              disponibles en todas las áreas geográficas y que algunos Servicios
              y Contenidos pueden ser utilizados solamente con posterioridad a
              su contratación, activación o registro previo por el Usuario y/o
              mediante el pago de estos. La Empresa no garantiza la
              disponibilidad y continuidad de la operación del Portal y de los
              Servicios y Contenidos, ni la utilidad del Portal o los Servicios
              y Contenidos en relación con cualquier actividad específica,
              independientemente del medio de acceso que utilice el Usuario. La
              Empresa no será responsable por daño o pérdida alguna de cualquier
              naturaleza que pueda ser causado debido a la falta de
              disponibilidad o continuidad de operación del Portal y/o de los
              Servicios y Contenidos.
            </p>
            <p>
              El aprovechamiento de los Servicios y Contenidos en el Portal, es
              exclusiva responsabilidad del Usuario, quien en todo caso deberá
              servirse de ellos acorde a las funcionalidades permitidas en el
              propio Portal y a los usos autorizados en los presentes Términos y
              Condiciones, por lo que el Usuario se obliga a utilizarlos de tal
              modo que no contravengan las buenas costumbres, los derechos de
              terceros, las normas de uso y convivencia en Internet, las leyes
              de los Estados Unidos Mexicanos y, la legislación vigente en el
              país en que el Usuario se encuentre al acceder al Portal y usar
              los Servicios y Contenidos. El Portal es para el uso individual
              del Usuario por lo que no podrá comercializar de manera alguna los
              Servicios y Contenidos.
            </p>
            <p>
              <b>2. RESTRICCIONES.</b> . El Usuario no tiene el derecho de
              colocar híper ligas dentro del Portal, a utilizar las ligas del
              Portal, ni el derecho de colocar o utilizar los Servicios y
              Contenidos en sitios o páginas propias o de terceros sin
              autorización previa y por escrito de la Empresa. El Usuario no
              podrá impedir a cualquier otro Usuario el uso del Portal ni de los
              Servicios y Contenidos.
            </p>
            <p>
              <b>3. PROPIEDAD INTELECTUAL.</b> Los derechos de propiedad
              intelectual, propiedad industrial respecto de los Servicios y
              Contenidos, los signos distintivos y dominios de las Páginas o del
              Portal, así como los derechos de uso y explotación de estos,
              incluyendo de manera enunciativa más no limitativa, su
              divulgación, publicación, reproducción, distribución y
              transformación, son propiedad exclusiva de la Empresa. El Usuario
              no adquiere ningún derecho de propiedad intelectual y/o industrial
              por el simple uso o acceso de los Servicios y Contenidos del
              Portal y, en ningún momento, dicho uso será considerado como una
              autorización o licencia para utilizar los Servicios y Contenidos
              con fines distintos a los que se contemplan en los presentes
              Términos y Condiciones.
            </p>
            <p>
              <b>4. PROPIEDAD INTELECTUAL DE TERCEROS.</b> El Usuario acuerda
              que las disposiciones que se establecen en el artículo 3 anterior
              respecto de la titularidad de los derechos de la Empresa, también
              son aplicables a los derechos de terceros respecto de los
              Servicios y Contenidos de las Páginas, dominios o información
              presentada o vinculada al Portal.
            </p>
            <p>
              <b>5. CALIDAD DE LOS SERVICIOS Y CONTENIDOS Y SUS GARANTÍAS.</b>{' '}
              Ni la Empresa, ni sus proveedores o socios comerciales, serán
              responsables de cualquier daño o perjuicio que sufra el Usuario a
              consecuencia de inexactitudes, consultas realizadas, asesorías,
              errores tipográficos y cambios o mejoras que se realicen
              periódicamente a los Servicios y Contenidos. Las recomendaciones y
              consejos obtenidos a través del Portal son de naturaleza general,
              por lo que no deben tomarse en cuenta en la adopción de decisiones
              personales ni profesionales. Para ello, se debe consultar a un
              profesional apropiado que pueda asesorar al Usuario de acuerdo con
              sus necesidades específicas.
            </p>
            <p>
              La Empresa ofrece los Servicios y Contenidos con un nivel de
              competencia y diligencia razonable desde un punto de vista
              comercial, sin embargo, no ofrece ningún tipo de garantía en
              relación con estos. El Portal es proporcionado por la Empresa "tal
              como está" y "según disponibilidad". La Empresa no manifiesta
              ninguna representación o garantía de ningún tipo, expresa o
              implícita, en relación con la operación del Portal, información,
              contenido, materiales o productos incluidos. El Usuario acepta
              expresamente que el uso del Portal es bajo su propio riesgo. La
              Empresa se reserva el derecho a remover o eliminar cualquier
              información del Portal, en cualquier momento, a su entera
              discreción. Ni la Empresa, ni sus proveedores o distribuidores
              ofrecen garantías específicas sobre los Servicios y Contenidos; la
              Empresa excluye todas las garantías en la medida que las leyes
              vigentes lo permitan.
            </p>
            <p>
              De conformidad con los Términos y Condiciones, la Empresa no asume
              ni asumirá ningún tipo de responsabilidad frente a ninguna
              persona, derivada o que pudiera derivarse por los Servicios y
              Contenidos, navegación en el Portal, consultas, aclaraciones y/o
              cualquier otra clase de respuesta otorgada por parte de la Empresa
              por cualquier medio de comunicación.
            </p>
            <p>
              El Usuario se obliga a dejar en paz y a salvo a la Empresa, a sus
              accionistas, subsidiarias, afiliadas, funcionarios, directores,
              empleados, asesores, apoderados, representantes y/o cualquier
              persona relacionada con esta, de cualquier responsabilidad que
              pudiera imputarse en virtud y/o en relación con el Portal, la
              prestación de los Servicios y Contenidos o cualquier otro derivado
              de los presentes Términos y Condiciones.
            </p>
            <p>
              El Usuario entiende y acepta que la Empresa se encontrará limitada
              por responsabilidad de cualquier tipo, en todos los casos, al
              monto pagado como contraprestación por los Servicios y Contenidos.
            </p>

            <p
              style={{
                paddingLeft: '2em',
              }}>
              <b>5.1 DEVOLUCIONES Y CANCELACIONES.</b> Para seguridad de los
              Usuarios se establecen los siguientes supuestos:
            </p>

            <p
              style={{
                paddingLeft: '3em',
              }}>
              5.1.1. En caso de cancelación total previa al evento por causas de
              fuerza mayor externas a Oasis App: Se procede a la devolución de
              la renta de algún palco descontando el costo de comisión cobrado
              por nuestra pasarela de pagos.
            </p>
            <p
              style={{
                paddingLeft: '3em',
              }}>
              5.1.2. En caso de reducción de aforo por causas de fuerza mayor
              externas a Oasis App: No existen devoluciones.
            </p>
            <p
              style={{
                paddingLeft: '3em',
              }}>
              5.1.3. Si existiera alguna situación externa a Oasis App donde los
              huéspedes deben ser retirados del evento: No existen devoluciones.
            </p>
            <p
              style={{
                paddingLeft: '3em',
              }}>
              5.1.4. Los demás supuestos establecidos en nuestras Políticas de
              Cancelación.
            </p>
            <p
              style={{
                paddingLeft: '2em',
              }}>
              <b>5.2 SUSCRIPCIONES.</b> Es posible que para el uso y/o
              contratación de algunos o todos los Servicios y Contenidos, sea
              necesario el registro del Usuario y que este cuente con un método
              de pago automático y válido, asociado a dicho registro, método que
              podrá ser modificado o cancelado, a discreción del Usuario. En su
              caso, el Usuario se sujetará a los Términos y Condiciones de la
              plataforma de pagos.
            </p>
            <p
              style={{
                paddingLeft: '2em',
              }}>
              <b>5.3 ENVÍOS.</b> La Empresa no será responsable de ninguna
              demora y/o incumplimiento respecto de los envíos realizados ni
              mediante terceros ajenos a la Empresa, el Usuario acepta los
              riesgos adjuntos y se sujeta a los Términos y Condiciones del
              proveedor de servicios encargado del envío.
            </p>
            <p>
              <b>6. BIENES Y SERVICIOS DE TERCEROS ENLAZADOS.</b> El hecho de
              que se ofrezca información en el Portal o en otros sitios ligados
              o vinculados, no implica la recomendación, garantía, patrocinio o
              aprobación por parte de la Empresa respecto de dicha información,
              bienes y/o servicios. La disponibilidad de bienes y/o servicios
              ofertados por terceros o por sitios ligados o vinculados, no es
              responsabilidad de la Empresa. En virtud de lo anterior, la
              Empresa no será responsable ante cualquier autoridad de cualquier
              naturaleza, por cualquier asunto relacionado con la venta,
              consumo, distribución, entrega, disponibilidad o prestación con
              respecto de cualquiera de los bienes y/o servicios ofertados por
              terceros o por sitios ligados o vinculados a través del Portal.
            </p>
            <p>
              Respecto de los Servicios y Contenidos que prestan terceros dentro
              o mediante enlaces a el Portal (tales como ligas, banners y
              botones), la Empresa se limita exclusivamente, para conveniencia
              del Usuario, a: (i) Informar al Usuario sobre los mismos y, (ii) a
              proporcionar un medio para poner en contacto al Usuario con
              proveedores o vendedores. Los productos y/o servicios que se
              comercializan dentro del Portal y/o en los sitios de terceros
              enlazados son suministrados por comerciantes independientes y no
              se entenderá en ningún caso que son responsabilidad de la Empresa.
              No existe ningún tipo de relación laboral, asociación o sociedad,
              entre la Empresa y dichos terceros. Toda asesoría, consejo,
              declaración, información y contenido de las páginas de terceros
              enlazadas o dentro del Portal representan las opiniones y juicios
              de dicho tercero, consecuentemente, la Empresa no será responsable
              de ningún daño o perjuicio que sufra el Usuario a consecuencia de
              estos.
            </p>
            <p>
              <b>7. CONFIDENCIALIDAD.</b> La Empresa se obliga a mantener
              confidencial la información que reciba del Usuario que tenga dicho
              carácter conforme a las disposiciones legales aplicables en los
              Estados Unidos Mexicanos; la Empresa no asume ninguna obligación
              de mantener confidencial cualquier otra información que el Usuario
              le proporcione.
            </p>
            <p>
              <b>8. USO DE LA INFORMACIÓN NO CONFIDENCIAL.</b> Mediante el uso
              del Portal, el Usuario autoriza a la Empresa, de manera
              enunciativa más no limitativa, a utilizar, publicar, reproducir,
              divulgar, comunicar públicamente y transmitir la información no
              confidencial, en términos de lo establecido en la Ley Federal de
              Protección de Datos Personales en Posesión de Particulares, en la
              Ley Federal de los Derechos de Autor, en la Ley Federal de
              Protección al Consumidor y en cualquiera otra aplicable en la
              legislación mexicana.
            </p>
            <p>
              <b>9. COOKIES.</b> El Usuario que tenga acceso al Portal, conviene
              en recibir archivos que les transmitan los servidores de la
              Empresa. Una "Cookie" es un archivo de datos que se almacena en el
              disco duro de la computadora del Usuario cuando este acceda al
              Portal. Dichos archivos pueden contener información tal como la
              identificación proporcionada por el Usuario o información para
              rastrear las páginas que el Usuario ha visitado. Una Cookie no
              puede leer los datos o información del disco duro del Usuario ni
              leer las Cookies creadas por otros sitios o páginas.
            </p>
            <p>
              Generalmente, las Cookies son aceptadas automáticamente, el
              Usuario puede cambiar la configuración de su navegador en
              cualquier momento. En caso de que el Usuario decida rechazar las
              Cookies, es posible que ciertas secciones del Portal no tengan su
              funcionamiento óptimo o incluso no funcionen en absoluto.
            </p>
            <p>
              <b>10. AVISO DE PRIVACIDAD DE DATOS PERSONALES.</b> Toda la
              información que la Empresa recabe del Usuario es tratada con
              absoluta confidencialidad conforme las disposiciones legales
              aplicables en la legislación mexicana.
            </p>
            <p>
              Para conocer más sobre la protección de sus datos personales, por
              favor consulte nuestro Aviso de Privacidad.
            </p>
            <p>
              <b>11. CLAVES DE ACCESO.</b> En todo momento, el Usuario es el
              responsable único y final de mantener en secreto las claves de
              acceso que pudiera tener y con las cuales tuviera acceso a ciertos
              Servicios y Contenidos del Portal.
            </p>
            <p style={{ paddingLeft: '2em' }}>
              <b>11.1 CUENTAS.</b> El Usuario, al crear una cuenta en el Portal,
              declara, bajo protesta de decir verdad, que tiene como mínimo 18
              años de edad o la mayoría de edad legal en su jurisdicción; el
              Usuario reconoce que es su responsabilidad cualquier actividad que
              se desarrolle con dicha cuenta o a través de ella y reconoce que
              tiene conocimiento y acepta las condiciones establecidas en los
              presentes Términos y Condiciones y en el Aviso de Privacidad.
            </p>
            <p>
              <b>12. MODIFICACIONES.</b> La Empresa tendrá el derecho de
              modificar, en cualquier momento, los Términos y Condiciones, sin
              previo aviso y/o consentimiento del Usuario. En consecuencia, el
              Usuario debe leer atentamente los Términos y Condiciones cada vez
              que pretenda utilizar el Portal. Ciertos Servicios y Contenidos
              ofrecidos a los Usuarios en y/o a través del Portal están sujetos
              a condiciones particulares propias que sustituyen, completan y/o
              modifican los presentes Términos y Condiciones. Consiguientemente,
              el Usuario también debe leer atentamente las correspondientes
              condiciones particulares antes de acceder a cualquiera de los
              Servicios y Contenidos.
            </p>
            <p>
              <b>13. LEYES APLICABLES Y JURISDICCIÓN.</b> Para la
              interpretación, cumplimiento y ejecución de los presentes Términos
              y Condiciones, el Usuario está de acuerdo en que serán aplicables
              las leyes Federales de los Estados Unidos Mexicanos y competentes
              los tribunales de Álvaro Obregón Ciudad de México, renunciando
              expresamente a cualquier otro fuero o jurisdicción que pudiera
              corresponderles debido a sus domicilios presentes o futuros o por
              cualquier otra causa.
            </p>
            <p>
              <b> 14. CONTACTO.</b> El Usuario puede contactar, en todo momento,
              al personal de la Empresa para cualquier aclaración, comentario,
              duda y/o sugerencia relacionada con los Servicios y Contenidos,
              con el Portal y/o con los presentes Términos y Condiciones en
              soporte@oasisapp.mx.
            </p>
            <p>
              <b> 15. PAGOS CON TARJETA.</b> Todas las transacciones con tarjeta
              serán procesadas a través de Stripe.
            </p>

            <p>
              <b> 16. COMISIONES.</b> En Oasis App se aplican dos tipos de
              comisiones, una comisión aplicable a huéspedes (usuarios que
              rentan un palco) y otra a anfitriones (usuarios que suben a la
              Oasis un palco, platea o boleto VIP). Las comisiones de Oasis se
              manejan de la siguiente manera:
            </p>
            <p style={{ paddingLeft: '2em' }}>
              <b>16.1 HUÉSPEDES.</b> Se le aumenta un 16% al valor de renta
              establecido por los anfitriones en Oasis App. Dicho 16% se le
              aumenta un 16% correspondiente al IVA generado en la transacción.
              En eventos con alta demanda, Oasis App se reserva el derecho de
              aumentar hasta en un 20% + IVA de comisión para huéspedes al valor
              de renta establecido por los anfitriones en Oasis App.
            </p>
            <p style={{ paddingLeft: '4em' }}>
              <b>16.1.1 EJEMPLO.</b> Un palco se lista en 100 pesos por un
              anfitrión en Oasis, por lo tanto, su precio final sería de 100
              pesos + 16 pesos de comisión Oasis + 2.56 pesos de IVA generado
              sobre la comisión Oasis. Por ello, el precio final de dicho
              ejemplo sería de 118.56 pesos.
            </p>
            <p style={{ paddingLeft: '2em' }}>
              <b>16.2 ANFITRIONES.</b> Se toma como comisión un 15% del valor
              total del precio que este usuario determine en la aplicación.
            </p>
            <p style={{ paddingLeft: '4em' }}>
              <b>16.2.1 EJEMPLO.</b> El anfitrión lista su palco en 100 pesos en
              Oasis App, por lo tanto, 15 pesos corresponderán a la comisión de
              Oasis y 85 pesos serían depositados a la cuenta del anfitrión o a
              la que este determine.
            </p>
            <p style={{ paddingLeft: '4em' }}>
              <b>16.2.2 PAGO A ANFITRIONES:</b> Todos los pagos serán procesados
              con transacciones y/o depósitos bancarios, el uso de efectivo
              dependerá de disponibilidad.
            </p>

            <p>
              <b> 17. FACTURACIÓN.</b> En caso de requerir factura favor de
              solicitarla en facturacion@oasisapp.mx donde sin problema
              podríamos emitir una factura sobre los montos de comisión que se
              ven involucrados por Oasis. De igual forma, solicitaremos a él
              anfitrión del palco rentado la posibilidad de emitir una factura
              por el monto de la renta, sin embargo, Oasis App no se hace
              responsable de los hechos posteriores.
            </p>
            <p>
              Por otro lado, Oasis App cumpliendo con sus obligaciones fiscales,
              al momento de hacer un depósito de renta correspondiente a un
              anfitrión de algún palco se le emitirá una factura donde se
              contempla el porcentaje de comisión de Oasis App.
            </p>

            <p>
              <b> 18. RESPONSABILIDAD DE ANFITRIONES.</b> Por el momento, Oasis
              App no regula la exclusividad de plataforma para renta de palcos
              de estadio y asientos VIP, por ello, los palcohabientes tienen la
              responsabilidad de administrar su palco de manera eficiente y
              evitar en todo momento la generación de múltiples reservas en
              diferentes plataformas. En sentido de lo anterior, los dueños de
              palcos cuentan con la obligación de habilitar o deshabitar en caso
              de que el palco haya sido reservado por otra plataforma externa a
              Oasis. De igual forma, los palcohabientes cuentan con la
              responsabilidad de establecer precios que aceptarían por la renta
              de su palco, ya que, una vez agendado una reserva, no hay
              negociación posterior del precio listado.
            </p>
            <p>
              Por último, los estadios con los que cuenta alianza comercial
              Oasis App con los promotores de eventos, equipos de fútbol o la
              institución que regula al estadio, estos si deberán mantener
              exclusividad de renta por Oasis y, de lo contrario, podrían ser
              dados de baja de la aplicación de Oasis e incluso incurrir con
              faltas administrativas con los promotores de los eventos.
            </p>

            <p>
              <b> 19. RESPONSABILIDAD DE HUÉSPEDES</b> Los huéspedes de palcos
              reservados por Oasis App deberán conocer el reglamento del estadio
              que regula a palcos o zonas VIP rentadas. Además, estos deberán
              mantener respetar en el inmueble rentado y su comportamiento
              dentro del palco, con el inmueble, terceros y accesorios, en caso
              de incurrir con alguna falta se aplicaría el Depósito de Garantía,
              prohibición de la aplicación, faltas administrativas internas de
              los estadios, procedimientos legales, etc.
            </p>

            <p>
              <b> 20. ENTREGA DE ACCESOS A EVENTOS: </b>
              Oasis App se obliga a entregar accesos en tiempo y forma de los
              eventos reservados por los HUÉSPEDES, en caso de no poder cumplir
              con esta obligación, Oasis App devuelve el 100% del monto de la
              transacción.
            </p>
            <p>
              La entrega de los accesos dependerá de facilidad y posibilidad de
              entrega, en caso de que los accesos sean digitales se entregaran
              de manera digital, en caso de ser físicos y que los accesos no
              sean accesorios de una propiedad se mandaran por correo express o
              entrega en el estadio donde se celebre el evento, en caso de ser
              físicos y que los accesos sean accesorios de una propiedad estos
              se entregaran en la misma ciudad donde se celebre el evento en el
              domicilio y cualidades que EL HUÉSPED indique.
            </p>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
