import React from 'react';
import PropTypes from 'prop-types';
// Components & Utils
import IconText from '../shared/IconText';
import ImageLazyLoad from '../shared/ImageLazyLoad';
// UI
import {
  Stack,
  Typography,
  Box,
  Skeleton,
  Button,
  useMediaQuery,
} from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

// This returns an array with six skeleton cards
const textSkeletons = () => {
  const textRows = [];
  for (let index = 0; index < 4; index++) {
    textRows.push(
      <Skeleton key={index} width='100%' height='1.3em' variant='text' />
    );
  }
  return textRows;
};

/**
 * @function EventBanner
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const EventBanner = ({
  isLoading,
  pictureUrl,
  title,
  subtitle,
  iconTextItems,
  CTAComponent,
  goBack,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        zIndex: 1,
        position: 'relative',
        pt: { xs: 7, md: 15 },
        mx: 'auto',
        maxWidth: 'lg',
        mb: 10,
        px: { xs: 0, md: 3 },
      }}>
      {!isMobile && (
        <Button
          onClick={goBack}
          color='secondary'
          aria-label='Event Banner'
          sx={{
            textTransform: 'none',
            width: 'fit-content',
            pr: 2,
            mb: 1,
          }}>
          <ArrowBackIosNewRoundedIcon color='secondary' />
          Atrás
        </Button>
      )}
      {!isLoading ? (
        <Stack
          alignItems={{ xs: 'stretch', md: 'center' }}
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 4, md: 2 }}>
          <Stack>
            <ImageLazyLoad
              altText={'Renta de Palcos para ' + title}
              objectFitImage='cover'
              srcHighQuality={pictureUrl}
              containerStyles={{
                width: 'unset',
                height: {
                  xs: '15em !important',
                  sm: '18em !important',
                  md: '17em !important',
                },
                '& img': {
                  width: {
                    xs: '100% !important',
                    md: '23em !important',
                    lg: '30em !important',
                  },
                  height: {
                    xs: '15em !important',
                    sm: '18em !important',
                    md: '17em !important',
                  },
                  borderRadius: {
                    sm: '0 !important',
                    md: '16px !important',
                  },
                },
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ px: 3, width: '100%' }}>
            {title && (
              <Typography gutterBottom variant='h1'>
                Renta de Palcos para {title}
              </Typography>
            )}
            {subtitle && (
              <Typography gutterBottom variant='h6'>
                {subtitle}
              </Typography>
            )}
            {iconTextItems.map((item) => (
              <IconText key={item.id} {...item} />
            ))}
            {CTAComponent}
          </Stack>
        </Stack>
      ) : (
        <Stack
          alignItems={{ xs: 'stretch', md: 'center' }}
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 4, md: 2 }}>
          <Skeleton
            variant='rectangular'
            sx={{
              width: { xs: '100%', md: '23em', lg: '30em' },
              height: { xs: '15em', sm: '18em', md: '17em' },
              borderRadius: {
                sm: '0 !important',
                md: '16px !important',
              },
            }}
          />
          <Stack sx={{ px: 3, flex: 1 }}>
            <Skeleton width='100%' height='4em' variant='text' sx={{ mb: 3 }} />
            {textSkeletons()}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

EventBanner.defaultProps = {
  isLoading: true,
  pictureUrl: null,
  title: null,
  subtitle: null,
  iconTextItems: [],
  CTAComponent: <></>,
  goBack: () => {},
};

EventBanner.propTypes = {
  goBack: PropTypes.func,
};

export default EventBanner;
