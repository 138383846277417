import { httpRequest } from '../utils/util';

export const getBoxEventMenu = async ({ boxId, eventId }) => {
  const res = await httpRequest(`app/menus?boxId=${boxId}&eventId=${eventId}`);
  const data = res.data;

  /// TODO: define error codes
  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const addCartProducts = async ({ boxId, eventId, items }) => {
  const res = await httpRequest(`app/menus/cart/add`, {
    body: {
      boxId: Number(boxId),
      eventId: Number(eventId),
      items: [...items],
    },
    method: 'POST',
  });
  const resData = res.data;

  /// TODO: define error codes
  if (res.success) return resData;

  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};

export const getCart = async ({ cartId = null, boxId, eventId }) => {
  let res;
  if (cartId) {
    res = await httpRequest(`app/menus/cart/${cartId}`);
  } else {
    res = await httpRequest(`app/menus/cart?boxId=${boxId}&eventId=${eventId}`);
  }
  const data = res.data;

  if (res.success) return data;
  console.log(res);
  throw new Error(
    'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  );
};
