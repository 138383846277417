import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Components & Utils
import { AuthContext } from '../../providers/AuthProvider';
import TextInput from '../inputs/TextInput';
import { menuOptions } from '../../utils/menuOptions';

// UI
import {
  Typography,
  Link as MUILink,
  Button,
  Stack,
  CircularProgress,
  Collapse,
  Alert,
} from '@mui/material';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { logLogin } from '../../firebase';

const defaultValues = {
  email: '',
  password: '',
};

const MUILinkStyles = {
  sx: { color: (t) => t.palette.common.white, width: '100%' },
  variant: 'body2',
  underline: 'hover',
  component: Link,
};

/**
 * @function LoginForm
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const LoginForm = () => {
  useDocumentTitle('Oasis App - Iniciar sesión');
  const {
    register,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues: defaultValues });

  const { login, auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state = {} } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onFormSubmit = async (data) => {
    setIsLoading(true);

    const loginResult = await login({ provider: 'email', ...data });
    if (loginResult.success) {
      logLogin('email');
      navigate(state?.from ?? '/events');
      return;
    }
    setErrorMessage(loginResult.message);
    setIsError(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (auth.isLoggedIn) navigate(state?.from ?? '/events');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Stack
      direction='column'
      spacing={2}
      component='form'
      onSubmit={handleSubmit(onFormSubmit)}
      sx={{ width: '100%' }}>
      <Typography variant='h1' gutterBottom sx={{ textAlign: 'left', mb: 3 }}>
        Iniciar sesión
      </Typography>
      <Collapse in={isError}>
        <Alert severity='error' variant='outlined'>
          {errorMessage}
        </Alert>
      </Collapse>
      <TextInput
        variant='outlined'
        inputVariant='email'
        register={register}
        required='Por favor ingrese su correo.'
        registerName='email'
        label='Correo electrónico'
        placeholder='ejemplo@gmail.com'
        error={Boolean(errors.email && touchedFields.email)}
        helperText={
          Boolean(errors.email && touchedFields.email) && errors.email.message
        }
      />
      <TextInput
        variant='outlined'
        inputVariant='password'
        register={register}
        required={true}
        registerName='password'
        label='Contraseña'
        error={Boolean(errors.password && touchedFields.password)}
      />
      <MUILink
        {...MUILinkStyles}
        align='right'
        to={menuOptions.FORGOTPASSWORD.path}>
        Olvidé mi contraseña
      </MUILink>
      <Stack sx={{ py: 3 }}>
        <Button
          variant='contained'
          align='right'
          aria-label='Login'
          color='secondary'
          type='submit'
          disabled={!isValid || isLoading}>
          {isLoading ? <CircularProgress size={30} /> : 'Entrar'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default LoginForm;
