import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
// Components & Utils
// UI

/**
 * @function IconText
 * @author RCH010
 * @version 1.0
 * @description Wrapper for icon and text
 * @param {Object} Icon
 * @param {String} text
 * @param {Object} typographyProps
 * @param {Object} containerProps
 * @returns {JSX}
 */
const IconText = ({ Icon, text, typographyProps, containerProps }) => {
  return (
    <Stack direction='row' alignItems='center' {...containerProps}>
      <Stack
        justifyContent='center'
        alignItems='center'
        sx={{ width: '1.2em' }}>
        {Icon}
      </Stack>
      <Typography ml={2} {...typographyProps}>
        {text}
      </Typography>
    </Stack>
  );
};

IconText.defaultProps = {
  Icon: <></>,
  text: '',
  typographyProps: {},
  containerProps: {},
};

IconText.propTypes = {
  Icon: PropTypes.object,
  text: PropTypes.string,
  typographyProps: PropTypes.object,
  containerProps: PropTypes.object,
};

export default IconText;
