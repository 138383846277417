import { useContext } from 'react';
import { AuthContext } from '../providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router';
import { menuOptions } from '../utils/menuOptions';
import { LinearProgress } from '@mui/material';

export const RequireAuth = ({
  redirectTo = menuOptions.LOGIN.path,
  children,
}) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  if (!auth.isLoggedIn && !auth.isLoading) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }
  if (auth.isLoading) return <LinearProgress />;
  return <>{children ?? <></>}</>;
};
