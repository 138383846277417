import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

// Componets & Utils
import { SignUpContext } from '../../providers/SignUpProvider';
import CountrySelect from '../inputs/CountrySelect';
import TextInput from '../inputs/TextInput';
import DateInput from '../inputs/DateInput';
import TwoButtons from '../shared/buttons/TwoButtons';
import countries from '../../utils/json/countries.json';

// UI
import { Stack, Typography } from '@mui/material';

// Rage for date of birth   (today-110 years) - (today-18years)
const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 110);
const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 18);

const getCountry = (code) => {
  return countries.findIndex((country) => country.code === code);
};

/**
 * @function SignUpFirstStep
 * @returns
 */
const SignUpFirstStep = () => {
  const { updateStep, signUpData, setSignUpData } = useContext(SignUpContext);
  const countryIndex = getCountry(signUpData.phoneCountry);
  const defaultSignUpData = {
    ...signUpData,
    phoneCountry: countries[countryIndex],
  };
  const {
    register,
    control,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues: defaultSignUpData });

  const onSubmitForm = (values) => {
    const newValues = { ...values, phoneCountry: values.phoneCountry?.code };
    setSignUpData((currentData) => ({ ...currentData, ...newValues }));
    updateStep();
  };

  return (
    <Stack spacing={2} component='form' onSubmit={handleSubmit(onSubmitForm)}>
      <Typography variant='h4' sx={{ mb: 3 }}>
        Información básica
      </Typography>
      <TextInput
        autoFocus
        inputVariant='name'
        register={register}
        required='Ingrese su nombre completo'
        registerName='firstName'
        error={Boolean(errors.firstName && touchedFields.firstName)}
      />
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <TextInput
          inputVariant='firstLastName'
          register={register}
          required={true}
          registerName='lastName'
          error={Boolean(errors.lastName && touchedFields.lastName)}
        />
        <TextInput
          inputVariant='secondLastName'
          register={register}
          required={true}
          registerName='motherLastName'
          error={Boolean(errors.motherLastName && touchedFields.motherLastName)}
        />
      </Stack>
      <DateInput
        control={control}
        registerName='birthAt'
        label='Fecha de nacimiento'
        datePickerProps={{
          maxDate: maxDate,
          minDate: minDate,
          openTo: 'year',
        }}
        rules={{
          required: true,
          validate: (date) => {
            const receivedDate = new Date(date);
            return (
              receivedDate.getTime() > minDate.getTime() &&
              receivedDate.getTime() < maxDate.getTime()
            );
          },
        }}
      />
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        {/* TODO: error  */}
        <CountrySelect control={control} />
        {/* TODO: Mask for phoneinput */}
        <TextInput
          fullWidth
          inputVariant='phoneNumber'
          register={register}
          required={true}
          registerName='phone'
          error={Boolean(errors.phone && touchedFields.phone)}
        />
      </Stack>
      <Stack alignItems='flex-end' sx={{ py: 2 }}>
        <TwoButtons
          primary={{
            type: 'submit',
            label: 'Siguiente',
            isFullWidth: true,
            isDisabled: !isValid,
          }}
          containerStyles={{
            width: '100%',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SignUpFirstStep;
