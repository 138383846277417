import React from 'react';
import PropTypes from 'prop-types';
// UI
import { IconButton, useTheme } from '@mui/material';

/**
 * @function IconButtonOasis
 * @author RCH010
 * @version 1.0
 * @description button with an icon
 * @param {String} something short description of prop
 * @param {String} IconPath path to icon
 * @param {String} ariaLabel
 * @param {Object} iconStyles object with additional styles the icon may require
 * @param {String} shadowColor
 * @returns {JSX}
 */
const IconButtonOasis = ({
  IconPath,
  ariaLabel,
  iconStyles,
  shadowColor,
  ...props
}) => {
  const theme = useTheme();

  return (
    <IconButton
      sx={{
        width: '100%',
        transition: 'width 200ms ,filter 200ms',
        '&:hover': {
          filter: `drop-shadow(4px 4px 4px ${
            shadowColor ? shadowColor : theme.palette.shadowGreen
          })`,
          width: 'calc(100% + 0.2em)',
          '& > img': {
            width: 'calc(100% + 0.2em)',
          },
        },
      }}
      aria-label={ariaLabel}
      {...props}>
      {typeof IconPath === 'string' ? (
        <img
          alt=''
          src={IconPath}
          style={{
            width: '100%',
            transition: 'width 200ms',
            ...iconStyles,
          }}
        />
      ) : (
        IconPath
      )}
    </IconButton>
  );
};

IconButtonOasis.defaultProps = {
  iconPath: '',
  'aria-label': '',
  shadowColor: null,
};

IconButtonOasis.propTypes = {
  iconPath: PropTypes.string.isRequired,
  'aria-label': PropTypes.string,
  shadowColor: PropTypes.string,
  iconStyles: PropTypes.object,
};

export default IconButtonOasis;
